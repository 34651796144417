import Close from "@mui/icons-material/Close";
import DoneRounded from "@mui/icons-material/DoneRounded";
import RotateLeft from "@mui/icons-material/RotateLeft";
import RotateRight from "@mui/icons-material/RotateRight";
import IconButton from "@mui/material/IconButton";
import { useRef } from "react";
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
import AutoSizer from "react-virtualized-auto-sizer";
import { Deferred } from "codemod_components/utils/Deferred";
import { APDialogCard } from "codemod_components/elements/APDialogCard";
import { APColumn, APRow } from "codemod_components/layout/APFlex";
import { APExpanded, APSizedBox } from "codemod_components/layout/Common";

export default function ImageCropDialog({
  base64,
  deferred,
  aspectRatio,
  variant,
}: {
  base64: string;
  deferred: Deferred<HTMLCanvasElement>;
  aspectRatio?: number;
  variant?: string;
}) {
  var cropperRef = useRef<ReactCropperElement>(null);
  function rotateAndScale(rotation: number) {
    var cropper = cropperRef?.current?.cropper;
    if (cropper) {
      var { height: canvasHeight, width: canvasWidth } =
        cropper.getCanvasData();
      var { height: containerHeight, width: containerWidth } =
        cropper.getContainerData();
      var scaleFactor = 1;
      if (canvasWidth < canvasHeight) {
        scaleFactor = containerWidth / canvasHeight;
      } else {
        scaleFactor = containerHeight / canvasWidth;
      }
      cropper.rotate(rotation);
      // cropper.scale(scaleFactor);
      var { height: canvasHeight, width: canvasWidth } =
        cropper.getCanvasData();
      cropper.setCanvasData({
        height: scaleFactor * canvasWidth,
        width: scaleFactor * canvasHeight,
      });
      cropper.setCropBoxData({
        left: 0,
        top: 0,
        height: canvasHeight * 0.9,
        width: canvasWidth * 0.9,
      });
    }
  }

  const buttonColor = "white";

  return (
    <APDialogCard
      title=""
      style={{
        minWidth: "300px",
        height: "100%",
        backgroundColor: "rgba(0,0,0,0.7)",
        borderRadius: 0,
        padding: 0,
      }}
      leading={
        <IconButton
          onClick={() => deferred.reject(new Error("USER_CANCELLED"))}
          size="large"
        >
          <Close htmlColor={buttonColor} />
        </IconButton>
      }
      trailing={
        <APRow gap="12px" mainAxisAlignment="center">
          <IconButton onClick={() => rotateAndScale(-90)} size="large">
            <RotateLeft htmlColor={buttonColor} />
          </IconButton>
          <IconButton onClick={() => rotateAndScale(90)} size="large">
            <RotateRight htmlColor={buttonColor} />
          </IconButton>
          <IconButton
            onClick={async () => {
              const imageElement = cropperRef?.current;
              const cropper = imageElement?.cropper;
              if (cropper != null) {
                deferred.resolve(
                  cropper.getCroppedCanvas({ maxHeight: 1080, maxWidth: 1080 }),
                );
              }
            }}
            size="large"
          >
            <DoneRounded htmlColor={buttonColor} />
          </IconButton>
        </APRow>
      }
    >
      <APColumn mainAxisSize="max" crossAxisAlignment="stretch">
        <APSizedBox height="12px" />
        <APExpanded noClone>
          <AutoSizer>
            {({ width, height }: { width: number; height: number }) => {
              if (height == 0) {
                return null;
              }
              return (
                <Cropper
                  className={
                    variant === "avatar" ? "custom-cropper" : undefined
                  }
                  autoCropArea={1}
                  aspectRatio={aspectRatio}
                  ref={cropperRef}
                  src={base64}
                  minContainerHeight={height}
                  minContainerWidth={width}
                  style={{ maxWidth: "100%", maxHeight: "80vh" }}
                  viewMode={2}
                  dragMode="none"
                />
              );
            }}
          </AutoSizer>
        </APExpanded>
        <APSizedBox height="12px" />
      </APColumn>
    </APDialogCard>
  );
}
