import { APForm } from "codemod_components/forms/APForm";
import { APColumn, APRow } from "codemod_components/layout/APFlex";
import { APPalette } from "codemod_components/utils/APPalette";
import { APCard } from "codemod_components/elements/APCard";
import React, { useEffect, useState } from "react";
import { Checkbox } from "@mui/material";
import { APText } from "codemod_components/elements/APText";
import { APTag } from "codemod_components/elements/APTag";
import { useNavigate } from "react-router-dom";
import { useAPForm } from "codemod_components/forms/useAPForm";
import { APFormFieldText } from "codemod_components/forms/APFormFieldText";
import { REGEX } from "codemod_components/utils/REGEX";
import { APButton } from "codemod_components/elements/APButton";
import { showLoadingDialog } from "codemod_components/dialogs/showLoadingDialog";
import { getIt, launchDialog } from "codemod_components/dialogs/DialogHelper";
import FinacuityProfileApiServices from "../../../requests/UserRequests";
import {
  dispatchUpdateUserLoanApplications,
  dispatchUserProfile,
} from "../../../store/slices/authenticationSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@reduxjs/toolkit/query";
import FinacuityLoanApplicationApiServices from "../../../requests/LoanapplyRequests";
import { APOtp } from "codemod_components/forms/APOtp";
import ExistingLoanApplications from "../ExistingLoanApplications";
import LamfApplicationApprovedDialog from "./ApplicationApproved";
import { showErrorDialog } from "codemod_components/dialogs/showErrorDialog";
// @ts-ignore
import hero from "../../../images/banners/hero.png";
import { APGrid } from "../../../codemod_components/layout/APGrid";
import { APFormFieldImage } from "../../../codemod_components/forms/APFormFieldImage";
import OnboardingApiServices from "../../../requests/OnboardingRequests";
import { useForceRender } from "../../../codemod_components/hooks/useForceUpdate";
import { convertDateFormat } from "../../../utils/string-utils";
import { APFormFieldDate } from "codemod_components/forms/APFormFieldDate";
import LoanApplicationWrapperContainer from "../LoanApplicationWrapperContainer";
import { useCountDown } from "codemod_components/hooks/useCountDown";
import CountdownButton from "Screens/AuthModule/AnimatedButton/AnimatedButton";

const ApplyLamfLoanWindow = ({
  control,
  initialForm,
  errorMessage,
  handlerGenerateMfiExtractOtpRequest,
  setInitialForm,
  handleImageUpload,
  generateBureauPullOtpReq,
  timeLeft,
}) => {
  return (
    <APForm control={control}>
      <APColumn
        mainAxisSize="min"
        crossAxisAlignment="stretch"
        style={{
          backgroundColor: APPalette["grey-50"],
          padding: 0,
        }}
      >
        <APColumn>
          <APCard style={{ borderRadius: "8px", width: "95%" }}>
            <LamfLoanApplicationForm
              kycForm={initialForm}
              setKycForm={setInitialForm}
              handleImageUpload={handleImageUpload}
              handlerResendOtpRequest={generateBureauPullOtpReq}
              timeLeft={timeLeft}
            />
          </APCard>
          {errorMessage && <APTag variant="warning">{errorMessage}</APTag>}
          <APButton
            size="large"
            disabled={!initialForm?.consent}
            style={{ width: "85%", marginTop: "1vh" }}
            onClick={async () => {
              if (await control.validate())
                handlerGenerateMfiExtractOtpRequest();
            }}
          >
            {!Boolean(initialForm.otpRequested) ? "Get OTP" : "Submit OTP"}
          </APButton>
        </APColumn>
      </APColumn>
    </APForm>
  );
};

const LamfLoanApplicationForm = ({
  kycForm,
  setKycForm,
  handleImageUpload,
  handlerResendOtpRequest,
  timeLeft,
}) => {
  return (
    <>
      <APFormFieldText
        disabled={kycForm.otpRequested}
        style={{ width: "100%" }}
        label="Email"
        textAppearance={"lowercase"}
        initialValue={kycForm.email}
        onChanged={(value) => {
          setKycForm((prev) => ({ ...prev, ...{ email: value } }));
        }}
        validator={(value) => {
          if (!value || REGEX.EMAIL.test(value) == false) {
            return "Please enter a valid Email";
          }
          return null;
        }}
      />
      <APFormFieldText
        disabled={kycForm.otpRequested}
        style={{ width: "100%" }}
        keyboardType={"tel"}
        label="Mobile [registered with CAMS]"
        initialValue={kycForm.mobile}
        onChanged={(value) => {
          setKycForm((prev) => ({ ...prev, ...{ mobile: value } }));
        }}
        validator={(value) => {
          if (!value || REGEX.PHONE.test(value) == false) {
            return "Please enter a valid Phone";
          }
          return null;
        }}
      />
      <APFormFieldImage
        label="PAN Card"
        src={kycForm?.base64Image}
        onChange={handleImageUpload}
        validator={(file) => (!file ? "Please upload a valid image" : null)}
        disabled={kycForm.otpRequested}
      />

      <APGrid count={2} columnGap={"4px"}>
        <APFormFieldText
          disabled={kycForm.otpRequested}
          style={{ width: "100%" }}
          textAppearance={"capitalize"}
          label="Name"
          initialValue={kycForm.name}
          onChanged={(value) => {
            setKycForm((prev) => ({ ...prev, ...{ name: value } }));
          }}
          validator={(value) => {
            if (!value) {
              return "Please enter a valid Name";
            }
            return null;
          }}
        />
        <APFormFieldText
          disabled={kycForm.otpRequested}
          style={{ width: "100%" }}
          label="PAN"
          textAppearance={"uppercase"}
          initialValue={kycForm.pan}
          onChanged={(value) => {
            setKycForm((prev) => ({ ...prev, ...{ pan: value } }));
          }}
          validator={(value) => {
            if (!value || REGEX.PAN.test(value) == false) {
              return "Please enter a valid PAN number";
            }
            return null;
          }}
        />
      </APGrid>
      <APFormFieldDate
        disabled={kycForm.otpRequested}
        style={{ width: "100%" }}
        label="Date of Birth"
        initialValue={kycForm.dob}
        onChanged={(value) => {
          setKycForm((prev) => ({ ...prev, dob: value }));
        }}
        validator={(value) => {
          if (!value || value.length < 10) {
            return "Please enter a valid date";
          }
          return null;
        }}
      />
      <APRow>
        <Checkbox
          disabled={kycForm.otpRequested}
          checked={kycForm.consent}
          onChange={() => {
            setKycForm((prevState: { consent: any }) => ({
              ...prevState,
              ...{ consent: !Boolean(prevState.consent) },
            }));
          }}
        />
        <APText variant="paragraph-xsmall">
          By Clicking Get OTP, you authorize MoneyEase to pull holdings from
          Mutual Fund Custodians CAMS, Kfintech.
        </APText>
      </APRow>

      {kycForm.otpRequested && (
        <APColumn>
          <APOtp
            digits={6}
            onChanged={(value) =>
              setKycForm((prev) => ({ ...prev, otp: value }))
            }
          />
          <APRow style={{ justifyContent: "start" }}>
            <CountdownButton
              timeLeft={timeLeft}
              onClick={handlerResendOtpRequest}
              duration={60000 / 1000}
            />
          </APRow>
        </APColumn>
      )}
    </>
  );
};

const ApplyLamfLoan = () => {
  const lamfLoanApplicationFormControl = useAPForm();
  const { key, reload } = useForceRender();
  const dispatch = useDispatch();
  // @ts-ignore
  const { user, applications, profile } = useSelector(
    (state: RootState<any, any, any>) => state.authenticationSlice,
  );
  const lamfApplications =
    Array.isArray(applications) &&
    applications.filter((x) => x.product_code === "loan:lamf");
  const lamfApplicationForm = useAPForm();
  let navigate = useNavigate();
  let [errorMessage, setErrorMessage] = useState<string | undefined>();
  let [initialForm, setInitialForm] = useState({
    base64Image: "",
    consent: false,
    name: "",
    email: "",
    pan: "",
    dob: "",
    otpRequested: false,
    applicationId: null,
    otp: null,
    mobile: user?.mobile || "",
    mfi_id: null,
  });
  const [timeLeft, { start }] = useCountDown(45000, 1000);

   useEffect(() => {
     if (initialForm.otpRequested) {
       start();
     }
   }, [initialForm.otpRequested, start]);

  const fetchProfileData = async () => {
    const { data, status_code } = await showLoadingDialog(
      async () => await getIt(FinacuityProfileApiServices).getMeProfileData(),
    );
    if (status_code === 0) {
      dispatch(dispatchUserProfile(data?.profile));
    }
  };

  const fetchCustomerLoanApplications = async () => {
    const { data, status_code } = await showLoadingDialog(
      async () =>
        await getIt(FinacuityLoanApplicationApiServices).getLoanApplications(),
    );
    if (status_code === 0) {
      dispatch(dispatchUpdateUserLoanApplications(data.applications));
    }
  };

  const generateMfiOtpReq = async () => {
    const { data, status_code, error } = await showLoadingDialog(
      async () =>
        await getIt(
          FinacuityLoanApplicationApiServices,
        ).getLamfLoanApplicationOtp({
          name: initialForm.name,
          email: initialForm.email.toLowerCase(),
          pan: initialForm.pan.toUpperCase(),
          dob: new Date(initialForm.dob).getTime(),
          mobile: initialForm.mobile,
        }),
    );
    if (status_code === 0 && data?.mfi?.mfi_id) {
      setInitialForm((prevState) => ({
        ...prevState,
        ...{ otpRequested: true, mfi_id: data?.mfi?.mfi_id },
      }));
      start()
    } else if (status_code === 422) {
      try {
        await showErrorDialog(error?.msg || "Mutual Funds does not Exist");
      } catch (e) {
        await showErrorDialog(
          "Invalid PAN/PEKRN, Mobile/Email combination, Or Mutual Funds does not Exist",
        );
      }
    } else {
      await showErrorDialog(error?.message || "Something went wrong");
    }
  };

  const handlerGenerateMfiExtractOtpRequest = async () => {
    try {
      if (!initialForm.otpRequested) {
        await generateMfiOtpReq();
      } else if (initialForm.otpRequested && initialForm.otp?.length === 6) {
        await submitOtp();
      }
    } catch (error) {
      await showErrorDialog(error?.message);
    }
  };

  const submitOtp = async () => {
    const { data, status_code, error } = await showLoadingDialog(
      async () =>
        await getIt(
          FinacuityLoanApplicationApiServices,
        ).submitLamfLoanApplicationOtp({
          name: initialForm.name,
          email: initialForm.email.toLowerCase(),
          pan: initialForm.pan.toUpperCase(),
          dob: new Date(initialForm.dob).getTime(),
          mobile: initialForm.mobile,
          otp: initialForm.otp,
          mfi_id: initialForm?.mfi_id,
        }),
    );
    if (status_code === 0 && data?.application) {
      setInitialForm((prevState) => ({
        ...prevState,
        ...{ applicationId: data?.application?.application_id },
      }));
      await checkEligibility(data?.application?.application_id);
    } else if (status_code === 406) {
      await showErrorDialog("Your OTP Expired, Try Resending the OTP");
    } else {
      await showErrorDialog(
        error?.message || data?.message || "Something went wrong",
      );
    }
  };

  const renderEligibilityDialog = async (applicationId: string) => {
    const { data, status_code } = await showLoadingDialog(
      async () =>
        await getIt(FinacuityLoanApplicationApiServices).getLoanApplications(),
    );
    let res: { clicked: boolean; applicationId: string } = await launchDialog(
      (deferred) => (
        <LamfApplicationApprovedDialog
          deferredResolve={deferred}
          application={
            data?.applications?.find(
              (x) => x.application_id === applicationId,
            ) || {}
          }
        />
      ),
    );
    await completeMyApplicationJourney(applicationId);
  };

  const checkEligibility = async (applicationId: string) => {
    const { data, status_code } = await showLoadingDialog(
      async () =>
        await getIt(
          FinacuityLoanApplicationApiServices,
        ).getLamfLoanApplicationEligibility(applicationId),
      "running Eligibility",
    );
    if (status_code === 102) {
      await checkEligibility(applicationId);
    }
    try {
      await renderEligibilityDialog(applicationId);
    } catch (e) {}
  };

  const completeMyApplicationJourney = async (applicationId: string) => {
    const { data, status_code } = await showLoadingDialog(
      async () =>
        await getIt(
          FinacuityLoanApplicationApiServices,
        ).getLamfLoanApplicationRedirectUri(applicationId),
    );
    if (status_code === 0 || status_code === 400) {
      window.location.href = data.url;
    }
  };

  useEffect(() => {
    if (lamfApplications && lamfApplications.length > 0) {
      checkEligibility(lamfApplications[0].application_id);
    }
  }, [lamfApplications]);

  useEffect(() => {
    if (!user) fetchProfileData();
    if (!applications) fetchCustomerLoanApplications();
  }, []);

  const handleImageUpload = async (value: string) => {
    if (value && value !== initialForm?.base64Image) {
      try {
        await showLoadingDialog(async () => {
          const resp = await getIt(
            FinacuityLoanApplicationApiServices,
          ).generatePreSignedUrl(user?.fu_id, "individual_pan");
          await getIt(OnboardingApiServices).uploadDocumentToPreSignedUrl(
            resp.data.url,
            value,
          );
          const { data, status_code } = await getIt(
            FinacuityLoanApplicationApiServices,
          ).performExecuteActionOnDocument(user?.fu_id, "individual_pan");
          if (status_code === 0) {
            setInitialForm((prevState) => ({
              ...prevState,
              ...{
                name: data.name,
                pan: data.number,
                dob: convertDateFormat(data.dob),
                base64Image: value,
              },
            }));
            reload();
          }
        });
      } catch (err) {
        await showErrorDialog(err.message);
      }
    }
  };

  return (
    <>
      <LoanApplicationWrapperContainer
        headerTitle={"Loan Against Mutual Funds"}
        productCode={"loan:lamf"}
      >
        {lamfApplications &&
        Array.isArray(lamfApplications) &&
        lamfApplications.length > 0 ? (
          <ExistingLoanApplications
            applications={lamfApplications}
            isHovered={true}
            onClick={console.log}
            setIsHovered={console.log}
            completeJourneyButton={completeMyApplicationJourney}
          />
        ) : (
          <ApplyLamfLoanWindow
            key={key}
            control={lamfLoanApplicationFormControl}
            errorMessage={errorMessage}
            handlerGenerateMfiExtractOtpRequest={
              handlerGenerateMfiExtractOtpRequest
            }
            initialForm={initialForm}
            setInitialForm={setInitialForm}
            handleImageUpload={handleImageUpload}
            generateBureauPullOtpReq={generateMfiOtpReq}
            timeLeft={timeLeft}
          />
        )}
      </LoanApplicationWrapperContainer>
    </>
  );
};

export default ApplyLamfLoan;
