import { useMemo } from "react";
export type APFormControl = any;
export type APFormFieldItem = any;

export function useAPForm(): APFormControl {
  const control: APFormControl = useMemo<APFormControl>(() => {
    return {
      fields: new Set(),
      validate: async () => {
        var validation = true;
        for (const item of Array.from(control.fields)) {
          // @ts-ignore
          var resp = await item.validate();
          validation = validation && resp;
        }
        return validation;
      },
      reset: () => {
        for (const item of Array.from(control.fields)) {
          // @ts-ignore
          item.reset();
        }
      },
      submit: () => {},
    };
  }, []);

  return control;
}
