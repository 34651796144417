import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getIt } from "codemod_components/dialogs/DialogHelper";
import { APButton } from "codemod_components/elements/APButton";
import { APGrid } from "codemod_components/layout/APGrid";
import { APColumn, APRow } from "codemod_components/layout/APFlex";
import { APFormSubmit } from "codemod_components/forms/APFormSubmit";
import { APTag } from "codemod_components/elements/APTag";
import { APText } from "codemod_components/elements/APText";
import OnboardingApiServices from "../../../requests/OnboardingRequests";
import { updateVideoForm } from "../../../store/slices/onboardingSlice";
import { useWindowDimensions } from "codemod_components/hooks/useWindowDimension";
import { useCountDown } from "codemod_components/hooks/useCountDown";
import { showLoadingDialog } from "codemod_components/dialogs/showLoadingDialog";
import { showErrorDialog } from "codemod_components/dialogs/showErrorDialog";
import {
  ReactMediaRecorder,
  useReactMediaRecorder,
} from "react-media-recorder";
import * as faceapi from "face-api.js";

const VideoForm = ({ goToNextStep, goToPrevStep, isLastStep }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { video, session_id, token } = useSelector(
    (state) => state.onboardingSlice,
  );

  const {
    status,
    startRecording,
    stopRecording,
    mediaBlobUrl,
    clearBlobUrl,
    previewStream,
    error,
  } = useReactMediaRecorder({
    video: true,
    audio: true,
    askPermissionOnMount: true,
    blobPropertyBag: { type: "video/mp4" },
  });

  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [modelsLoaded, setModelsLoaded] = useState(false);
  const [timeLeft, actions] = useCountDown(30 * 1000, 1000);
  const [faceDetected, setFaceDetected] = useState(false);
  const [showCanvas, setShowCanvas] = useState(true);

  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const { height, width } = useWindowDimensions();

  const generateRandomIdentifier = useMemo(() => {
    const docIdentifier = video?.doc_identifier || "";
    const randomStart = Math.max(
      0,
      Math.floor(Math.random() * docIdentifier.length - 7),
    );
    return docIdentifier.slice(randomStart, randomStart + 6);
  }, [video?.doc_identifier]);

  useEffect(() => {
    const loadModels = async () => {
      try {
        await faceapi.nets.tinyFaceDetector.loadFromUri("/models");
        await faceapi.nets.faceLandmark68Net.loadFromUri("/models");
        await faceapi.nets.faceRecognitionNet.loadFromUri("/models");
        setModelsLoaded(true);
      } catch (error) {
        console.error("Error loading models:", error);
      }
    };
    loadModels();
    generateUniqueVideoReferenceCode();
  }, []);

  useEffect(() => {
    if (videoRef.current && previewStream) {
      videoRef.current.srcObject = previewStream;
      if (modelsLoaded) {
        startFaceDetection();
      }
    }
  }, [previewStream, modelsLoaded]);

  const startFaceDetection = async () => {
    if (videoRef.current && canvasRef.current) {
      const canvas = canvasRef.current;
      faceapi.matchDimensions(canvas, videoRef.current);

      const intervalId = setInterval(async () => {
        const detections = await faceapi
          .detectAllFaces(
            videoRef.current,
            new faceapi.TinyFaceDetectorOptions(),
          )
          .withFaceLandmarks();
        canvas.innerHTML = "";
        faceapi.draw.drawDetections(canvas, detections);
        faceapi.draw.drawFaceLandmarks(canvas, detections);

        if (detections.length > 0) {
          const highestScore = Math.max(
            ...detections.map((d) => d.detection.score),
          );
          if (highestScore >= 0.81) {
            setFaceDetected(true);
            if (status !== "recording") {
              handlerStartRecording();
            }
          } else {
            setFaceDetected(false);
          }
        } else {
          setFaceDetected(false);
        }
      }, 1000); // Check every second

      return () => clearInterval(intervalId); // Clean up interval on component unmount
    }
  };

  const generateUniqueVideoReferenceCode = async () => {
    try {
      const resp = await showLoadingDialog(() =>
        getIt(OnboardingApiServices).generatePreSignedUrl(
          session_id,
          "individual_video",
          token,
        ),
      );
      dispatch(
        updateVideoForm({
          ...video,
          doc_identifier: resp.data.doc_identifier,
          url: resp.data.url,
        }),
      );
    } catch (error) {
      console.error("Error generating unique video reference code:", error);
      await showErrorDialog(
        "Failed to generate video reference code. Please try again.",
      );
    }
  };

  const uploadVideo = async () => {
    try {
      const blob = await fetch(mediaBlobUrl).then((r) => r.blob());
      const file = new File([blob], video?.doc_identifier || "video", {
        type: "video/mp4",
      });

      await showLoadingDialog(() =>
        getIt(OnboardingApiServices).uploadVideoDocumentToPreSignedUrl(
          video.url,
          file,
          {},
        ),
      );
      const ocrResponseStatus = await showLoadingDialog(() =>
        getIt(OnboardingApiServices).performExecuteActionOnDocument(
          session_id,
          "individual_video",
          token,
        ),
      );

      dispatch(
        updateVideoForm({
          ...video,
          ocr_extract: ocrResponseStatus?.data,
          base64Image: mediaBlobUrl,
          videoUploadVerified: true,
        }),
      );
    } catch (error) {
      console.error("Error uploading video chunks:", error);
      await showErrorDialog("Failed to upload video. Please try again.");
    }
  };

  const handlerStartRecording = () => {
    if (mediaBlobUrl) clearBlobUrl();
    startRecording();
    actions.reset();
    actions.start(30 * 1000);
  };

  const handlerStopRecording = () => {
    actions.pause();
    stopRecording();
    setShowCanvas(false);
    uploadVideo();
  };

  const handlerSubmitInfo = async () => {
    try {
      await getIt(OnboardingApiServices).updateSessionInfo(
        session_id,
        {
          video: {
            ocr_extract: { ...video.ocr_extract },
            doc_identifier: video.doc_identifier,
          },
        },
        token,
      );
      if (isLastStep) {
        navigate(`/onboarding/${session_id}/success`);
      } else {
        goToNextStep();
      }
    } catch (err) {
      setErrorMessage(err.message);
    }
  };

  return (
    <>
      <APGrid count={1} columnGap={"16px"}>
        <APColumn>
          {video.doc_identifier && (
            <>
              <APText variant="paragraph-medium">
                Please Speak, while recording the video
              </APText>
              <APText variant="h1">{generateRandomIdentifier}</APText>
            </>
          )}
          {error === "permission_denied" && (
            <APText variant="paragraph-medium">
              Please grant permission to use your camera and microphone.
            </APText>
          )}
        </APColumn>
        <APColumn>
          {status === "recording" && showCanvas && (
            <>
              <video
                ref={videoRef}
                width={width * 0.84}
                height={height * 0.5}
                autoPlay
              />
              <canvas
                ref={canvasRef}
                width={width * 0.84}
                height={height * 0.5}
                style={{ position: "absolute", top: 0, left: 0 }}
              />
            </>
          )}
          {status !== "recording" && mediaBlobUrl && (
            <video
              src={mediaBlobUrl}
              width={width * 0.84}
              height={height * 0.5}
              controls
              style={{ marginTop: "1rem" }}
            />
          )}
        </APColumn>
        <APColumn>{Math.ceil(timeLeft / 1000)}</APColumn>
        <APGrid count={1} columnGap={"16px"}>
          <APColumn>
            {status !== "recording" && (
              <APButton
                size="large"
                onClick={handlerStartRecording}
                type="primary"
              >
                {mediaBlobUrl ? "Record again" : "Record your Video"}
              </APButton>
            )}
            {status === "recording" && (
              <APButton size="small" onClick={handlerStopRecording}>
                Stop Recording
              </APButton>
            )}
          </APColumn>
        </APGrid>
      </APGrid>
      {video?.videoUploadVerified && video.url && (
        <APColumn>
          <video
            src={video.url}
            style={{ width: "100%", height: "auto", marginTop: "1rem" }}
          />
          <APButton size="small" onClick={() => videoRef.current?.play()}>
            Replay Video
          </APButton>
        </APColumn>
      )}
      {errorMessage && <APTag variant="warning">{errorMessage}</APTag>}
      {video?.videoUploadVerified && (
        <APRow style={{ padding: "0px 16px 16px 16px" }}>
          <APColumn style={{ flex: 1 }}>
            <APFormSubmit size="large" onClick={handlerSubmitInfo}>
              Next
            </APFormSubmit>
          </APColumn>
        </APRow>
      )}
    </>
  );
};

export default VideoForm;
