import React, { useEffect, useState } from "react";
import { APFormFieldImage } from "codemod_components/forms/APFormFieldImage";
import { showLoadingDialog } from "codemod_components/dialogs/showLoadingDialog";
import { getIt } from "codemod_components/dialogs/DialogHelper";
import OnboardingApiServices from "../../../requests/OnboardingRequests";
import { showErrorDialog } from "codemod_components/dialogs/showErrorDialog";
import { APGrid } from "codemod_components/layout/APGrid";
import axios from "axios";
import { convertBytesToBase64Image } from "../../../utils/string-utils";
import { APFormFieldText } from "codemod_components/forms/APFormFieldText";
import { REGEX } from "codemod_components/utils/REGEX";
import { APFormFieldDropdown } from "codemod_components/forms/APFormFieldDropdown";
import { useForceRender } from "codemod_components/hooks/useForceUpdate";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  updatePoaForm,
  updatePoiForm,
} from "../../../store/slices/onboardingSlice";
import { APExpanded } from "../../../codemod_components/layout/Common";
import { APFormSubmit } from "../../../codemod_components/forms/APFormSubmit";
import { APRow } from "../../../codemod_components/layout/APFlex";
import { useAPForm } from "../../../codemod_components/forms/useAPForm";
import { APForm } from "codemod_components/forms/APForm";
import { APTag } from "../../../codemod_components/elements/APTag";
import { resizeImage } from "../../../codemod_components/hooks/FilePicker/utils";
import { useLocation, useNavigate } from "react-router-dom";

const RenderOcrInfoStatus = ({ kycForm, onChange, disabled }) => {
  return (
    <>
      <APFormFieldText
        label="Aaddhaar Number"
        initialValue={kycForm?.uid}
        onChanged={(value) => onChange("uid", value)}
        validator={(value) => {
          return null;
        }}
        disabled={disabled}
      />

      <APFormFieldText
        label="Pincode"
        initialValue={kycForm?.pincode}
        onChanged={(value) => onChange("pincode", value)}
        validator={(value) => {
          if (!value || REGEX.PIN.test(value) == false) {
            return "Please enter a valid Pincode";
          }
          return null;
        }}
        disabled={disabled}
      />

      <APFormFieldDropdown<"M" | "F" | "T">
        label="Gender"
        items={[
          {
            value: "M",
            label: "Male",
          },
          {
            value: "F",
            label: "Female",
          },
          {
            value: "T",
            label: "Transgender",
          },
        ]}
        initialValue={kycForm?.gender?.substr(0, 1)}
        onChanged={(value) => onChange("gender", value)}
        validator={(value) => {
          if (!value) {
            return "Please select gender";
          }
          return null;
        }}
        disabled={disabled}
      />

      <APFormFieldText
        label="Address"
        initialValue={kycForm?.address}
        onChanged={(value) => onChange("address", value)}
        disabled={disabled}
      />
    </>
  );
};

const poaForm = ({ goToNextStep, goToPrevStep, isLastStep }) => {
  const navigate = useNavigate();
  const poaFormControl = useAPForm();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [aadImages, setAadImages] = useState([null, null]);

  const { key, reload } = useForceRender();
  const { poa, session_id, token, session_meta } = useSelector(
    (state: any) => state.onboardingSlice,
  );
  const dispatch = useDispatch();

  const preFillInfo = async () => {
    await showLoadingDialog(async () => {
      let { data, status_code } = await getIt(
        OnboardingApiServices,
      ).getSessionDetail(session_id, token);
      const poaPrefilledObject = data?.session?.poa;
      if (poaPrefilledObject?.aadhaar_back_masked_doc_identifier) {
        let aadhaar_urls = await showLoadingDialog(
          async () =>
            await getIt(OnboardingApiServices).downloadDocPreSignedUrl(
              session_id,
              "aadhaar_front", // this gets you both the images
              token,
            ),
        );
        let image0 = "";
        let image1 = "";
        await Promise.all(
          aadhaar_urls.data.map(async (i) => {
            const imageBody = await axios.get(i.url, {
              responseType: "arraybuffer",
            });
            if (i.doc_type.includes("front")) {
              image0 = convertBytesToBase64Image(imageBody.data);
            }
            if (i.doc_type.includes("back")) {
              image1 = convertBytesToBase64Image(imageBody.data);
            }
          }),
        );
        setAadImages([image0, image1]);
        dispatch(updatePoaForm(poaPrefilledObject));
        reload();
      }
    });
  };

  // console.log(aadImages);
  useEffect(() => {
    preFillInfo();
  }, []);

  const replaceWithMaskedImages = async (images) => {
    if (Array.isArray(images) && images.length == 2) {
      const [response1, response2] = await Promise.all([
        axios.get(images[0], { responseType: "arraybuffer" }),
        axios.get(images[1], { responseType: "arraybuffer" }),
      ]);
      return [
        convertBytesToBase64Image(response1.data),
        convertBytesToBase64Image(response2.data),
      ];
    }
  };

  const uploadDocumentAction = async (value: string, type_of_doc: string) => {
    return await showLoadingDialog(async () => {
      const docUpload = await getIt(OnboardingApiServices).generatePreSignedUrl(
        session_id,
        type_of_doc,
        token,
      );
      await getIt(OnboardingApiServices).uploadDocumentToPreSignedUrl(
        docUpload.data.url,
        value,
      );
      return { doc_identifier: docUpload?.data.doc_identifier };
    });
  };

  const ocrActionExecute = async () => {
    if (
      poa?.status !== "completed" &&
      aadImages.some((element) => element !== null)
    ) {
      await showLoadingDialog(async () => {
        let { status_code, data } = await getIt(
          OnboardingApiServices,
        ).performExecuteActionOnDocument(session_id, "aadhaar_back", token);
        if (status_code === 0) {
          let maskedDataImages = [];
          const cloned = _.cloneDeep({
            ocr_extract: { ...poa.ocr_extract, ...data },
          });
          if (data?.images) {
            maskedDataImages = await replaceWithMaskedImages(data?.images);
            setAadImages(maskedDataImages);
          }
          dispatch(updatePoaForm(cloned));
          reload();
        }
      });
    }
  };

  const handlerSubmitInfo = async () => {
    if (poa?.status !== "completed") {
      await getIt(OnboardingApiServices).updateSessionInfo(
        session_id,
        {
          poa: {
            ocr_extract: { ...poa.ocr_extract },
            aadhaar_back_masked_doc_identifier:
              poa.aadhaar_back_masked_doc_identifier,
            aadhaar_front_masked_doc_identifier:
              poa.aadhaar_front_masked_doc_identifier,
            maskedImagesExist: poa.maskedImagesExist,
          },
        },
        token,
      );
    }
    if (isLastStep) {
      navigate(`/onboarding/${session_id}/success`);
    } else {
      goToNextStep();
    }
  };

  return (
    <>
      <APForm control={poaFormControl} style={{ width: "100%" }}>
        <APGrid key={key} count={1} columnGap={"16px"}>
          <APFormFieldImage
            label="Aaddhar Photo Front"
            src={aadImages[0]}
            onChange={async (value) => {
              if (value && value !== aadImages[0]) {
                try {
                  setAadImages((prevState) => [value, prevState[1]]);
                  const { doc_identifier } = await uploadDocumentAction(
                    value,
                    "aadhaar_front",
                  );
                  await ocrActionExecute();
                  dispatch(
                    updatePoaForm(
                      _.cloneDeep({
                        aadhaar_front_masked_doc_identifier: doc_identifier,
                      }),
                    ),
                  );
                  reload();
                } catch (err: any) {
                  await showErrorDialog(err.message);
                }
              }
            }}
            validator={(file) => {
              if (!file) {
                return "Please upload a valid image";
              }
              return null;
            }}
            disabled={poa?.status === "completed"}
          />
          <APFormFieldImage
            label="Aaddhar Photo Back"
            src={aadImages[1]}
            onChange={async (value) => {
              if (value && value !== aadImages[1]) {
                try {
                  setAadImages((prevState) => [prevState[0], value]);
                  const { doc_identifier } = await uploadDocumentAction(
                    value,
                    "aadhaar_back",
                  );
                  await ocrActionExecute();
                  dispatch(
                    updatePoaForm(
                      _.cloneDeep({
                        aadhaar_back_masked_doc_identifier: doc_identifier,
                      }),
                    ),
                  );
                  reload();
                } catch (err: any) {
                  await showErrorDialog(err.message);
                }
              }
            }}
            validator={(file) => {
              if (!file) {
                return "Please upload a valid image";
              }
              return null;
            }}
            disabled={poa?.status === "completed"}
          />

          <RenderOcrInfoStatus
            kycForm={poa.ocr_extract || {}}
            onChange={(label: any, value: any) =>
              dispatch(updatePoaForm({ [label]: value }))
            }
            disabled={poa?.status === "completed"}
          />
        </APGrid>
      </APForm>
      {errorMessage && <APTag variant="warning">{errorMessage}</APTag>}
      <APRow style={{ padding: "0px 16px 16px 16px" }}>
        <APExpanded>
          <APFormSubmit
            size="large"
            onClick={async () => {
              if (await poaFormControl.validate()) {
                try {
                  await handlerSubmitInfo();
                } catch (err: any) {
                  setErrorMessage(err.message);
                }
              }
            }}
          >
            Next
          </APFormSubmit>
        </APExpanded>
      </APRow>
    </>
  );
};
export default poaForm;
