import { launchDialog } from "codemod_components/dialogs/DialogHelper";
import { ErrorDialog } from "codemod_components/dialogs/ErrorDialog";

interface IErrorDialogOptions {
  title?: string;
  yesAction?: string;
}

export async function showErrorDialog(
  message: string,
  options?: IErrorDialogOptions,
) {
  try {
    if (message === "USER_CANCELLED") {
      return;
    }
    var result = await launchDialog<void>((deferred) => (
      <ErrorDialog
        title={options?.title}
        deferred={deferred}
        message={message}
        yesAction={options?.yesAction}
      />
    ));
    return result;
  } catch (error) {
    return false;
  }
}
