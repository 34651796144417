import React, { useContext } from "react";
import { DialogContext } from "codemod_components/dialogs/DialogHelper";
import { APColumn, APRow } from "codemod_components/layout/APFlex";
import { APScrollView } from "codemod_components/layout/APScrollView";
import { APExpanded } from "codemod_components/layout/Common";
import { APCard } from "./APCard";
import { APText } from "./APText";
import APIconButton from "codemod_components/icons/APIconButton";
import { APPalette } from "codemod_components/utils/APPalette";
import CloseIcon from "@mui/icons-material/Close";

export interface APDialogCardProps {
  style?: React.CSSProperties;
  /**
   * title of the dialog
   */
  title: string;
  children: React.ReactNode;
  /**
   * adds any node before the dialog title
   * @private used in confirmation and error dialog
   */
  leading?: React.ReactNode;
  /**
   * call to actions which will appear at the bottom of the dialog
   */
  actions?: React.ReactNode;
  /**
   * adds any node after the dialog title
   */
  trailing?: JSX.Element;
  /**
   * @deprecated
   */
  contentDivider?: boolean;
  /**
   * @deprecated
   */
  contentDividerOffset?: string;
}

export function APDialogCard({
  style,
  title,
  children,
  leading,
  actions,
  trailing,
  contentDivider = false,
  contentDividerOffset,
}: APDialogCardProps) {
  const dialogContext = useContext(DialogContext);

  return (
    <APCard
      style={{
        padding: "16px",
        minWidth: "420px",
        maxWidth: "100%",
        ...style,
        boxSizing: "border-box",
        borderRadius: "8px",
      }}
    >
      <APColumn gap="8px" mainAxisSize="max" crossAxisAlignment="stretch">
        {/* Card Header */}
        <APRow gap="8px" mainAxisAlignment="spaceBetween">
          {leading}
          <APExpanded>
            <APText variant="h3" color={APPalette["grey-700"]} ellipsis>
              {" "}
              {title}
            </APText>
          </APExpanded>
          {trailing}
          {!dialogContext.disableClose && (
            <APIconButton
              onClick={() =>
                dialogContext.deferred?.reject(new Error("USER_CANCELLED"))
              }
            >
              <div>
                <CloseIcon />
              </div>
            </APIconButton>
          )}
        </APRow>
        {/* Card Body */}
        <APExpanded>
          <APScrollView>{children}</APScrollView>
        </APExpanded>
        {/* Card Actions */}
        {actions}
      </APColumn>
    </APCard>
  );
}
