import React, { Fragment, useEffect, useMemo, useState } from "react";

import { APJourneyTile } from "codemod_components/stepper_app/APJourneyTile";
import { APCenter, APExpanded } from "codemod_components/layout/Common";
import { APInkWell } from "codemod_components/elements/APInkWell";
import { APColumn, APRow } from "codemod_components/layout/APFlex";
import { APPalette } from "codemod_components/utils/APPalette";
import { APText } from "codemod_components/elements/APText";
import { APCard } from "codemod_components/elements/APCard";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useForceRender } from "../hooks/useForceUpdate";
import { APScrollView } from "../layout/APScrollView";

type OnboardingStep = {
  step_title: string;
  secondary_header: string;
  type: string;
};

type APJourneyAppProps = {
  title: string;
  description?: string;
  mode: "linear" | "non-linear";
  trailing?: React.ReactNode;
  style?: React.CSSProperties;
  onStepChange?: (stepId: string) => void;
  initialStepId?: string;
  getSteps: any;
  handleCompletion: (values: any) => void;
  formProps?: object;
  canChangeSteps?: boolean;
};

export function APJourneyApp(props: APJourneyAppProps) {
  const { key, reload } = useForceRender();
  const stepIdArray = useMemo(
    () =>
      props.getSteps.map((step, index) => ({ id: step.title, step })).flat(),
    [],
  );

  let [currentStepId, setCurrentStepId] = useState<string>(stepIdArray[0].id);
  const currentStepIndex = useMemo(
    () => stepIdArray.findIndex((a) => a.id === currentStepId),
    [currentStepId],
  );
  let currentStep = stepIdArray[currentStepIndex]?.step;

  function goToNextStep() {
    let currentIndex = stepIdArray.findIndex((a) => a.id === currentStepId);
    if (currentIndex < stepIdArray.length - 1) {
      setCurrentStepId(stepIdArray[currentIndex + 1].id);
      reload();
    } else {
      props.handleCompletion;
    }
  }

  function goToPrevStep() {
    let currentIndex = stepIdArray.findIndex((a) => a.id === currentStepId);
    if (!!currentIndex && currentIndex <= stepIdArray.length - 1) {
      setCurrentStepId(stepIdArray[currentIndex - 1].id);
      reload();
    }
  }

  useEffect(() => {
    props.onStepChange?.(currentStepId);
  }, [currentStepId]);

  return (
    <APColumn style={{ ...props.style, padding: 0 }} key={key}>
      <APColumn
        style={{ background: APPalette.white }}
        mainAxisSize="max"
        crossAxisAlignment="stretch"
      >
        <APColumn
          style={{ padding: "8px 16px" }}
          gap="8px"
          crossAxisAlignment="stretch"
        >
          <APRow
            mainAxisSize="max"
            crossAxisAlignment="start"
            mainAxisAlignment="center"
          >
            {!!currentStepIndex && props.canChangeSteps && (
              <APInkWell onClick={goToPrevStep}>
                <ChevronLeftIcon />
              </APInkWell>
            )}
            <APExpanded>
              <APColumn>
                <APText center variant="h3">
                  {currentStep.title}
                </APText>
                <APText
                  center
                  color={APPalette["grey-500"]}
                  variant="paragraph-xsmall"
                >
                  {currentStep.description}
                </APText>
              </APColumn>
            </APExpanded>
            {props.trailing}
          </APRow>
          <APJourneyTile
            totalSteps={stepIdArray.length}
            currentStep={currentStepIndex}
            thickness={2}
          />
        </APColumn>
        <APExpanded>
          <APColumn
            mainAxisSize="min"
            crossAxisAlignment="stretch"
            style={{
              backgroundColor: APPalette["grey-50"],
              marginBottom: "4px",
            }}
          >
            <APScrollView style={{ width: "100%" }}>
              <APCard style={{ borderRadius: "8px" }}>
                <currentStep.form
                  {...{ ...currentStep?.formProps, goToNextStep, goToPrevStep }}
                  isLastStep={currentStepIndex === stepIdArray.length - 1}
                />
              </APCard>
            </APScrollView>
          </APColumn>
        </APExpanded>
      </APColumn>
    </APColumn>
  );
}

export type JourneySectionApp = {
  title: string;
  description: string;
  steps: JourneyStepApp[];
};

export type JourneyStepApp = {
  title: string;
  description: string;
  form: React.ReactNode;
  onSubmit: () => Promise<any>;
  hideNextButton?: boolean;
};
