import { APForm } from "codemod_components/forms/APForm";
import { APColumn, APRow } from "codemod_components/layout/APFlex";
import { APText } from "codemod_components/elements/APText";
import { APCenter, APSizedBox } from "codemod_components/layout/Common";
import { Divider } from "@mui/material";
import { APFormSubmit } from "codemod_components/forms/APFormSubmit";
import { APDialogCard } from "codemod_components/elements/APDialogCard";
import React from "react";
import { useAPForm } from "codemod_components/forms/useAPForm";
import { APCard } from "codemod_components/elements/APCard";
import LamfLoanApprovedCommercials from "./LamfLoanApprovedCommericals";
import ExistingLoanApplications from "../ExistingLoanApplications";
import LamfLoanApprovedCommericals from "./LamfLoanApprovedCommericals";
import { APStack } from "../../../codemod_components/layout/APStack";

const LamfApplicationApprovedDialog = ({ deferredResolve, application }) => {
  return (
    <APDialogCard
      title={
        Number(application?.provisional?.loan_amount || 0) > 0
          ? "Application Approved"
          : "Application on Hold"
      }
      style={{ minWidth: "240px", maxWidth: "478px" }}
    >
      <APStack style={{ minHeight: "124px", width: "100%" }}>
        <APCenter>
          <LamfLoanApprovedCommericals application={application} />
        </APCenter>

        {Number(application?.provisional?.loan_amount) > 0 ? (
          <APFormSubmit
            style={{ width: "100%" }}
            onClick={() =>
              deferredResolve.resolve(true, application.application_id)
            }
          >
            Apply Now
          </APFormSubmit>
        ) : (
          <APFormSubmit
            style={{ width: "100%" }}
            onClick={() =>
              deferredResolve.resolve(true, application.application_id)
            }
          >
            Apply Now for Loan Against Shares
          </APFormSubmit>
        )}
      </APStack>
    </APDialogCard>
  );
};

export default LamfApplicationApprovedDialog;
