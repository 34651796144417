import React, { useContext, useEffect, useRef, useState } from "react";
import { APFormContext } from "codemod_components/forms/APForm";
import {
  APFormControl,
  APFormFieldItem,
} from "codemod_components/forms/useAPForm";
import { APFormFieldText } from "codemod_components/forms/APFormFieldText";

interface APFormFieldDateProps {
  id?: string;
  style?: React.CSSProperties;
  size?: "small" | "medium";
  label?: string;
  placeholder?: string;
  helperText?: React.ReactNode;
  initialValue?: string;
  readOnly?: boolean;
  value?: string;
  onChanged?: (v: string) => any;
  validator?: (
    v: string,
  ) => React.ReactNode | null | Promise<React.ReactNode | null>;
  control?: APFormControl;
  autoFocus?: boolean;
  required?: boolean;
  autoValidate?: boolean;
  error?: React.ReactNode;
  disabled?: boolean;
}

export function APFormFieldDate({
  id,
  style,
  disabled,
  size = "medium",
  label,
  placeholder,
  initialValue,
  value,
  readOnly = false,
  helperText,
  onChanged,
  validator,
  control,
  autoFocus,
  required,
  autoValidate,
  error,
}: APFormFieldDateProps) {
  const inputRef = useRef<any>(null);

  let control2: APFormControl | undefined = useContext(APFormContext);
  if (control) {
    control2 = control;
  }

  const [_value, setValue] = useState<string>(value ?? initialValue ?? "");
  const [_error, setError] = useState<React.ReactNode | null>(null);

  const checkValues = async (): Promise<boolean> => {
    let errorMessage = null;
    if (validator) {
      errorMessage = await validator(_value);
    }
    setError(errorMessage);
    if (errorMessage) {
      inputRef.current?.scrollIntoView?.({
        behavior: "smooth",
        block: "center",
      });
    }
    return !errorMessage;
  };

  const fieldItem: APFormFieldItem = {
    validate: checkValues,
    reset: () => {
      setValue(initialValue || "");
    },
  };

  useEffect(() => {
    if (control2) {
      control2.fields.add(fieldItem);
    }
    return () => {
      if (control2) {
        control2.fields.delete(fieldItem);
      }
    };
  }, [control2, fieldItem]);

  useEffect(() => {
    (async () => {
      if (validator && (autoValidate || _error !== null)) {
        setError(await validator(_value));
      }
      if (onChanged) onChanged(_value);
    })();
  }, [_value]);

  useEffect(() => {
    setError(error);
  }, [error]);

  const handleChange = (newValue: string) => {
    const timestampValue = Date.parse(newValue);
    const formattedValue = !isNaN(timestampValue)
      ? new Date(timestampValue).toISOString().slice(0, 10)
      : newValue;
    setValue(formattedValue);
    if (onChanged) onChanged(formattedValue);
  };

  return (
    <APFormFieldText
      id={id}
      style={style}
      size={size}
      label={label}
      placeholder={placeholder}
      helperText={_error || helperText}
      initialValue={_value}
      readOnly={readOnly}
      onChanged={handleChange}
      validator={validator}
      control={control}
      autoFocus={autoFocus}
      required={required}
      autoValidate={autoValidate}
      error={_error}
      disabled={disabled}
      keyboardType="date"
    />
  );
}
