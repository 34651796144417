import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { APExpanded, APSizedBox } from "codemod_components/layout/Common";
import { APText } from "codemod_components/elements/APText";
import { APColumn, APRow } from "codemod_components/layout/APFlex";
import { APButton } from "codemod_components/elements/APButton";
import { APTag } from "codemod_components/elements/APTag";
import { showLoadingDialog } from "codemod_components/dialogs/showLoadingDialog";
import FinacuityProfileApiServices from "requests/UserRequests";
import { getIt } from "codemod_components/dialogs/DialogHelper";
import { APOtp } from "codemod_components/forms/APOtp";
import { Player } from "@lottiefiles/react-lottie-player";
import { useCountDown } from "codemod_components/hooks/useCountDown";
import { showErrorDialog } from "codemod_components/dialogs/showErrorDialog";
import { APInkWell } from "codemod_components/elements/APInkWell";
import { APIcon } from "codemod_components/icons/APIcon";
import CountdownButton from "./AnimatedButton/AnimatedButton";
import { dispatchRegisterCurrentUser } from "store/slices/authenticationSlice";
import { useDispatch } from "react-redux";
import otpAnimation from "../../animations/lottie/otp_animation.json";
import { APScrollView } from "codemod_components/layout/APScrollView";

const Verify = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { maskedPhoneNumber } = location.state as {
    maskedPhoneNumber: string;
  };
  const countdownDuration = 30000;
  const [otpValue, setOtpValue] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [timeLeft, { start }] = useCountDown(countdownDuration, 1000);
  const queryParams = new URLSearchParams(location.search);
  const continueUrl = queryParams.get("continue");

  useEffect(() => {
    start();
  }, [start]);

  const pushUserBackToSource = async (data: any) => {
    window.localStorage.setItem("sta", data?.token);
    window.localStorage.setItem("str", data?.refresh);
    dispatch(
      dispatchRegisterCurrentUser({
        user: data?.user,
        token: data?.token,
        refresh: data?.refresh,
      }),
    );
    if (continueUrl) {
      navigate(decodeURIComponent(continueUrl));
    } else navigate(-1);
  };

  const handleVerify = async () => {
    if (otpValue && otpValue.length === 6) {
      try {
        const { data, status_code, error } = await showLoadingDialog(
          async () =>
            await getIt(FinacuityProfileApiServices).login(
              maskedPhoneNumber,
              otpValue,
            ),
        );

        if (status_code === 0) {
          await pushUserBackToSource(data);
        } else {
          setErrorMessage(error?.message);
        }
      } catch (err: any) {
        setErrorMessage(err.message);
      }
    } else {
      setErrorMessage("Please enter a valid OTP.");
    }
  };

  const handleResendOtp = async () => {
    try {
      await showLoadingDialog(async () => {
        const { status_code, error } = await getIt(
          FinacuityProfileApiServices,
        ).getLoginOtp(maskedPhoneNumber);

        if (status_code === 0) {
          start();
        } else {
          await showErrorDialog(error?.message);
        }
      });
    } catch (error: any) {
      await showErrorDialog(error.message);
    }
  };

  return (
    <APScrollView style={{ maxWidth: "600px" }}>
      <APColumn
        style={{ padding: "16px 16px" }}
        gap="8px"
        crossAxisAlignment="stretch"
      >
        <APRow
          mainAxisSize="max"
          crossAxisAlignment="start"
          mainAxisAlignment="center"
        >
          <APInkWell onClick={() => navigate(-1)}>
            <APIcon icon="backButton" size={20} />
          </APInkWell>

          <APExpanded>
            <APColumn>
              <APText center variant="h3">
                Verify OTP
              </APText>
            </APColumn>
          </APExpanded>
        </APRow>
      </APColumn>
      <APColumn
        crossAxisAlignment="center"
        mainAxisAlignment="center"
        style={{ padding: "1rem" }}
      >
        <Player src={otpAnimation} autoplay loop style={{ height: "15rem" }} />
        <APColumn style={{ width: "100%" }}>
          <APText variant="h1" center style={{ margin: "1rem" }}>
            <b>Enter Your OTP</b>
          </APText>
          <APText center>
            We have sent the OTP to Your Mobile Number
            <br style={{ marginBottom: "1rem" }} />
            <b>+91 - {maskedPhoneNumber}</b>
          </APText>
          <APRow>
            <APOtp digits={6} onChanged={(value) => setOtpValue(value)} />
          </APRow>
          <APRow style={{ justifyContent: "center" }}>
            <CountdownButton
              timeLeft={timeLeft}
              onClick={handleResendOtp}
              duration={countdownDuration / 1000}
            />
          </APRow>
          <APSizedBox height="2rem" />
        </APColumn>
        {errorMessage && <APTag variant="warning">{errorMessage}</APTag>}
        <APButton size="large" style={{ width: "100%" }} onClick={handleVerify}>
          Verify Phone Number
        </APButton>
      </APColumn>
    </APScrollView>
  );
};

export default Verify;
