import { ErrorBoundary } from "react-error-boundary";

import APIconButton from "codemod_components/icons/APIconButton";
import { APIcon } from "codemod_components/icons/APIcon";
import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { APCenter } from "codemod_components/layout/Common";
import { APCard } from "codemod_components/elements/APCard";
import { APColumn } from "codemod_components/layout/APFlex";
import { APText } from "codemod_components/elements/APText";
import { APPalette } from "codemod_components/utils/APPalette";

function ErrorBox({ error, resetErrorBoundary }: any) {
  const [path] = useState({ loc: window.location.pathname });

  useLocation();

  useEffect(() => {
    if (path.loc != window.location.pathname) {
      resetErrorBoundary();
      path.loc = window.location.pathname;
    }
  }, [window.location.pathname]);

  return (
    <APCenter>
      <APCard>
        <APColumn gap={"12px"} mainAxisSize="min" crossAxisAlignment="center">
          <APText variant="h3">Something went wrong!</APText>
          <APText variant="subtext" color={APPalette["grey-500"]}>
            {"Please try again or \nreach out to our support team"}
          </APText>
          <APIconButton
            onClick={() => {
              resetErrorBoundary();
            }}
          >
            <APIcon icon="refreshDouble"></APIcon>
          </APIconButton>
          <APText
            variant="subtext"
            color={APPalette["grey-400"]}
            maxLines={1}
            style={{ maxWidth: "320px" }}
          >
            Error: {error.message}{" "}
          </APText>
        </APColumn>
      </APCard>
    </APCenter>
  );
}

export function APErrorWrap({ children }: { children: JSX.Element }) {
  // @ts-ignore
  return (
    <ErrorBoundary
      fallbackRender={({ error, resetErrorBoundary }) => {
        return (
          <ErrorBox
            error={error}
            resetErrorBoundary={resetErrorBoundary}
          ></ErrorBox>
        );
      }}
    >
      {children}
    </ErrorBoundary>
  );
}
