import { APColumn, APRow } from "../../codemod_components/layout/APFlex";
import { APCard } from "../../codemod_components/elements/APCard";
import { IntroScreen } from "./IntroScreen";
import { Checkbox } from "@mui/material";
import { APFormFieldText } from "../../codemod_components/forms/APFormFieldText";
import { showLoadingDialog } from "../../codemod_components/dialogs/showLoadingDialog";
import { getIt } from "../../codemod_components/dialogs/DialogHelper";
import OnboardingApiServices from "../../requests/OnboardingRequests";
import { updateOnBoardingSession } from "../../store/slices/onboardingSlice";
import { showSnackbar } from "../../codemod_components/dialogs/showSnackbar";
import { APButton } from "../../codemod_components/elements/APButton";
import React, { useState } from "react";
import { showErrorDialog } from "../../codemod_components/dialogs/showErrorDialog";
import { useDispatch, useSelector } from "react-redux";
import { APScrollView } from "../../codemod_components/layout/APScrollView";

const OnboardingAuth = ({ session_meta }) => {
  const dispatch = useDispatch();
  const { token, session_id } = useSelector(
    (state: any) => state.onboardingSlice,
  );

  const [_, setAbsoluteGeo] = useState([]);
  const [otpValidated, setOtpValidated] = useState(false);

  const [otpSent, setOtpSent] = useState(false);
  const [consentChecked, setConsentChecked] = useState(false);

  const checkLocationAndAllow = async () => {
    const requiresLocation = session_meta?.forensics?.location === true;
    if (requiresLocation) {
      try {
        const { state } = await navigator.permissions.query({
          name: "geolocation",
        });
        if (state === "granted") {
          navigator.geolocation.getCurrentPosition(
            async (position) => {
              setAbsoluteGeo([
                position.coords.longitude,
                position.coords.latitude,
              ]);
              await sendSessionOtp();
            },
            (error) => {
              showSnackbar(error.message, "negative");
            },
          );
        } else if (state === "prompt") {
          navigator.geolocation.getCurrentPosition(
            async (position) => {
              setAbsoluteGeo([
                position.coords.longitude,
                position.coords.latitude,
              ]);
              await sendSessionOtp();
            },
            (error) => {
              if (error.code === error.PERMISSION_DENIED) {
                showSnackbar(
                  "Please Enable Location Access to Continue",
                  "negative",
                );
              }
            },
          );
        } else if (state === "denied") {
          await showSnackbar(
            "Please Enable Location Access to Continue",
            "negative",
          );
        } else {
          await showSnackbar(
            "Please Enable Location Access to Continue",
            "info",
          );
        }
      } catch (error) {
        await showSnackbar(
          "An error occurred while checking location permissions",
          "negative",
        );
      }
    } else {
      await sendSessionOtp();
    }
  };
  const sendSessionOtp = async () => {
    const requestSessionOtpResponse = await showLoadingDialog(
      async () =>
        await getIt(OnboardingApiServices).generateOtpForSession(session_id),
    );
    if (requestSessionOtpResponse?.detail?.error) {
      await showErrorDialog(
        requestSessionOtpResponse?.detail?.detail +
          ", Try again after some time",
      );
    } else {
      setOtpSent(true);
    }
  };

  return (
    <>
      <APScrollView>
        <APColumn style={{ width: "100%", height: "100%" }}>
          <APCard style={{ borderRadius: "8px", width: "100%" }}>
            {IntroScreen(session_meta)}
          </APCard>
          <APCard
            style={{
              borderRadius: "8px",
              display: "flex",
              flexDirection: "row",
              marginTop: "8px",
              marginBottom: "8px",
            }}
          >
            <APColumn>
              <APRow>
                <Checkbox
                  checked={consentChecked}
                  onChange={() => setConsentChecked(true)}
                  disabled={consentChecked || otpSent}
                />
                {session_meta.consent}
              </APRow>
            </APColumn>
          </APCard>
          <APColumn>
            {otpSent && (
              <APFormFieldText
                style={{ width: "100%" }}
                label="Enter OTP"
                onChanged={async (value) => {
                  if (value && value.length === 6) {
                    const otpVerificationResponse = await showLoadingDialog(
                      async () =>
                        await getIt(OnboardingApiServices).verifyOtpForSession(
                          session_id,
                          value,
                        ),
                    );
                    if (otpVerificationResponse?.status_code === 0) {
                      window.localStorage.setItem(
                        "kma",
                        otpVerificationResponse.token,
                      );
                      dispatch(
                        updateOnBoardingSession({
                          token: otpVerificationResponse.token,
                        }),
                      );
                      setOtpValidated(otpVerificationResponse?.token);
                    } else {
                      await showSnackbar(
                        otpVerificationResponse.error,
                        "negative",
                      );
                    }
                  }
                }}
              />
            )}
            <APButton
              size="large"
              style={{ width: "100%", marginTop: "1vh" }}
              onClick={checkLocationAndAllow}
              disabled={!consentChecked || otpSent}
            >
              {!otpSent ? "Get OTP" : "Proceed"}
            </APButton>
          </APColumn>
        </APColumn>
      </APScrollView>
    </>
  );
};

export default OnboardingAuth;
