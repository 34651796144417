import * as React from "react";

// Interface for file picker options
export interface FilePickerOptions {
  minFileSize?: number; // Minimum file size in MB
  maxFileSize?: number; // Maximum file size in MB
}

// Interface for file picker errors
export interface FilePickerErrors {
  hasInvalidFileSize?: boolean; // Flag for invalid file size
  hasInvalidPDF?: boolean; // Flag for invalid PDF file type
}

// Interface for file input props
export interface FileInputProps {
  accept?: string; // File types to accept (MIME type)
  multiple?: boolean; // Allow multiple files selection
}

// Constant for bytes per megabyte
const BYTES_PER_MEGABYTE = 1000000;

// Hook function useFilePicker
export const useFilePickerPDF = (options: FilePickerOptions = {}) => {
  const { minFileSize, maxFileSize } = options;

  // Reference to file input element
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  // State to hold selected files and errors
  const [files, setFileList] = React.useState<File[] | null>(null);
  const [errors, setError] = React.useState<FilePickerErrors>({});

  // Function to handle file selection change
  const onChange = async (fileList: FileList | null): Promise<void> => {
    if (!fileList || !fileList.length) return;

    // Scrub previous data from state
    setError(() => ({}));
    setFileList(null);

    // Convert native file list to iterable array
    let iterableFileList = Array.from(fileList) as File[];

    // Validate file size
    if (minFileSize || maxFileSize) {
      iterableFileList.forEach((file) => {
        const fileSizeInMB = file.size / BYTES_PER_MEGABYTE;
        if (minFileSize && fileSizeInMB < minFileSize) {
          setError((prevErrors) => ({
            ...prevErrors,
            hasInvalidFileSize: true,
          }));
        }
        if (maxFileSize && fileSizeInMB > maxFileSize) {
          setError((prevErrors) => ({
            ...prevErrors,
            hasInvalidFileSize: true,
          }));
        }
      });
    }

    // Validate file type (only accept PDF)
    const isValidPDF = iterableFileList.every(
      (file) => file.type === "application/pdf",
    );
    if (!isValidPDF) {
      setError((prevErrors) => ({
        ...prevErrors,
        hasInvalidPDF: true,
      }));
      return;
    }

    // Set the selected files to state
    setFileList(iterableFileList);

    // Clear the file input value
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  // Function to reset file picker state
  const reset = (): void => {
    setFileList(null);
  };

  // Function to handle file input click
  const onClick = (): void => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  // Hidden file input component
  const HiddenFileInput = ({
    multiple,
    accept,
  }: FileInputProps): React.ReactElement => {
    return (
      <input
        type="file"
        ref={fileInputRef}
        multiple={multiple}
        accept={accept || "application/pdf"}
        style={{ display: "none" }}
        onChange={(evt): void => {
          const target = evt.target as HTMLInputElement;
          onChange(target.files);
        }}
      />
    );
  };

  // Return files, errors, onClick, reset, and HiddenFileInput
  return {
    files,
    errors,
    onClick,
    reset,
    HiddenFileInput,
  };
};
