import { Container, Grid } from "@mui/material";
import { motion } from "framer-motion";
import ProductCard from "./Products/ProductCard";
import React, { useEffect, useState } from "react";
import { showLoadingDialog } from "../../codemod_components/dialogs/showLoadingDialog";
import { getIt } from "../../codemod_components/dialogs/DialogHelper";
import FinacuityProfileApiServices from "../../requests/UserRequests";
import { MediaCardDefault } from "../../assembled-components/MediaCard";
// @ts-ignore
import loanBanner from "../../images/banners/businessLoan1.jpeg";
import { APScrollView } from "../../codemod_components/layout/APScrollView";
import TopBar from "./TopHeaderBar";
import {
  APExpanded,
  APIntrinsicHeight,
  APSizedBox,
} from "../../codemod_components/layout/Common";
import { APColumn } from "../../codemod_components/layout/APFlex";
import { useNavigate } from "react-router-dom";
import { APPalette } from "../../codemod_components/utils/APPalette";

const HomeScreen = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const getProducts = async () => {
    const { data } = await showLoadingDialog(
      async () =>
        await getIt(FinacuityProfileApiServices).getHomeScreenProducts(),
    );
    setProducts(data.filter((i) => i.is_live === true && i));
  };
  useEffect(() => {
    getProducts();
  }, []);

  const handleOnProductClick = (product) => {
    navigate(`/apply/${product.productName.split(":")[1]}`);
  };

  return (
    <>
      <APColumn
        crossAxisAlignment="stretch"
        mainAxisSize="max"
        style={{ height: "100%" }}
      >
        <TopBar />
        <MediaCardDefault
          title={"123"}
          createdAt={new Date()}
          views={10 ?? 0}
          imgUrls={[loanBanner]}
          videoId={null}
          duration={"20"}
          onClick={console.log}
        />
        <APColumn
          mainAxisSize="min"
          crossAxisAlignment="stretch"
          style={{
            backgroundColor: APPalette["grey-50"],
          }}
        >
          <APScrollView>
            <Container>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
              >
                <Grid container>
                  {products.map((product) => (
                    <Grid item xs={12} key={product.productName}>
                      <ProductCard
                        product={product}
                        onProductClick={handleOnProductClick}
                      />
                    </Grid>
                  ))}
                </Grid>
              </motion.div>
            </Container>
          </APScrollView>
        </APColumn>
      </APColumn>
    </>
  );
};

export default HomeScreen;
