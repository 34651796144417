import { Typography } from "@mui/material";
import React from "react";
import { APStack } from "codemod_components/layout/APStack";

const OnboardingHeader = ({ meta }) => {
  return (
    <APStack
      style={{
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
        display: "flex",
        margin: "0.5rem",
        float: "left",
      }}
    >
      <img
        src={meta?.partner_info?.partner_logo}
        alt="Logo"
        style={{ width: "10rem", height: "2rem", marginRight: "3rem" }}
      />
      <Typography>{meta?.minor_purpose}</Typography>
    </APStack>
  );
};

export default OnboardingHeader;
