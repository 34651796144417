import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const loanApplicationSlice = createSlice({
  name: "loanApplication",
  initialState: {
    application: {},
  },
  reducers: {
    dispatchUpdatePersonalLoanApplication(state, action) {
      state.application = action.payload;
      return state;
    },
  },
});

export const { dispatchUpdatePersonalLoanApplication } =
  loanApplicationSlice.actions;

export default loanApplicationSlice.reducer;
