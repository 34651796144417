import React from "react";
import { ReactComponent as FinacuityLogo } from "./finacuity_logo.svg";
import { ReactComponent as AdvisorFilled } from "./finacuity_logo.svg";
import { ReactComponent as BackIcon } from "./back.svg";
import { APPalette } from "codemod_components/utils/APPalette";

export type SVGIcon =
  | "advisorFilled"
  | "advisorOutline"
  | "archive"
  | "arrowDown"
  | "arrowUp"
  | "assetplus"
  | "attachment"
  | "bank"
  | "barChart01"
  | "barChart02"
  | "bond"
  | "book"
  | "bookmark"
  | "briefcase"
  | "bulb"
  | "calculator"
  | "calendar"
  | "camera"
  | "cartFilled"
  | "cartOutline"
  | "chat"
  | "checkCircle"
  | "check"
  | "checkOutline"
  | "chevronUpDouble"
  | "circleOutline"
  | "clappingFilled"
  | "clapping"
  | "clock"
  | "close"
  | "closeOutline"
  | "coinsStacked"
  | "consultFilled"
  | "consultOutline"
  | "copy"
  | "creditCard"
  | "crossMark"
  | "delete"
  | "document"
  | "doubleCheck"
  | "downRound"
  | "down"
  | "downloadArrow"
  | "download"
  | "drag"
  | "edit"
  | "error"
  | "eyeOff"
  | "eye"
  | "faq"
  | "favFilled"
  | "favOutline"
  | "fileAttachment"
  | "filter"
  | "folder"
  | "fundFinder"
  | "gift"
  | "grid"
  | "handCoin"
  | "history"
  | "homeFilled"
  | "homeOutline"
  | "horizontalMore"
  | "hourglass"
  | "inbox"
  | "income"
  | "info"
  | "insuranceFilled"
  | "insuranceOutline"
  | "investFilled"
  | "investOutline"
  | "justiceScale"
  | "language"
  | "layers"
  | "leftArrow"
  | "left"
  | "lineChart"
  | "link"
  | "list"
  | "loading"
  | "lock"
  | "logOut"
  | "loss"
  | "mail"
  | "marketing"
  | "medicalCross"
  | "message"
  | "notificationFilled"
  | "notificationOutline"
  | "notificationActiveFilled"
  | "notificationActiveOutline"
  | "passcode"
  | "pencilFilled"
  | "pencilOutline"
  | "phone"
  | "pin"
  | "play"
  | "plus"
  | "plusCircle"
  | "plusRounded"
  | "poster"
  | "profile"
  | "profit"
  | "radioOn"
  | "refreshDouble"
  | "refresh"
  | "report"
  | "retirement"
  | "right"
  | "rocketFilled"
  | "rocketOutline"
  | "rupee"
  | "save"
  | "scales"
  | "searchRefraction"
  | "search"
  | "send"
  | "settingsFilled"
  | "settingsOutline"
  | "shareTool"
  | "share"
  | "shield"
  | "sort"
  | "starFilled"
  | "starOutline"
  | "support"
  | "switch"
  | "tickRound"
  | "tool01"
  | "tool"
  | "trash"
  | "truck"
  | "umbrella"
  | "unlock"
  | "upRound"
  | "up"
  | "upload"
  | "userFilled"
  | "userOutline"
  | "userPlus"
  | "usersFilled"
  | "usersOutline"
  | "vault"
  | "verticalMore"
  | "video"
  | "view"
  | "wand"
  | "warning"
  | "whatsapp"
  | "infoCircle"
  | "finacuityLogo"
  | "backButton";

function getIcon(icon: SVGIcon) {
  switch (icon) {
    case "finacuityLogo":
      return <FinacuityLogo />;
    case "backButton":
      return <BackIcon />;
    case "advisorFilled":
      return <AdvisorFilled />;
    case "advisorOutline":
      return <AdvisorFilled />;
    case "archive":
      return <AdvisorFilled />;
    case "arrowDown":
      return <AdvisorFilled />;
    case "arrowUp":
      return <AdvisorFilled />;
    case "assetplus":
      return <AdvisorFilled />;
    case "attachment":
      return <AdvisorFilled />;
    case "bank":
      return <AdvisorFilled />;
    case "barChart01":
      return <AdvisorFilled />;
    case "barChart02":
      return <AdvisorFilled />;
    case "bond":
      return <AdvisorFilled />;
    case "book":
      return <AdvisorFilled />;
    case "bookmark":
      return <AdvisorFilled />;
    case "briefcase":
      return <AdvisorFilled />;
    case "bulb":
      return <AdvisorFilled />;
    case "calculator":
      return <AdvisorFilled />;
    case "calendar":
      return <AdvisorFilled />;
    case "camera":
      return <AdvisorFilled />;
    case "cartFilled":
      return <AdvisorFilled />;
    case "cartOutline":
      return <AdvisorFilled />;
    case "chat":
      return <AdvisorFilled />;
    case "checkCircle":
      return <AdvisorFilled />;
    case "check":
      return <AdvisorFilled />;
    case "checkOutline":
      return <AdvisorFilled />;
    case "chevronUpDouble":
      return <AdvisorFilled />;
    case "circleOutline":
      return <AdvisorFilled />;
    case "clappingFilled":
      return <AdvisorFilled />;
    case "clapping":
      return <AdvisorFilled />;
    case "clock":
      return <AdvisorFilled />;
    case "close":
      return <AdvisorFilled />;
    case "closeOutline":
      return <AdvisorFilled />;
    case "coinsStacked":
      return <AdvisorFilled />;
    case "consultFilled":
      return <AdvisorFilled />;
    case "consultOutline":
      return <AdvisorFilled />;
    case "copy":
      return <AdvisorFilled />;
    case "creditCard":
      return <AdvisorFilled />;
    case "crossMark":
      return <AdvisorFilled />;
    case "delete":
      return <AdvisorFilled />;
    case "document":
      return <AdvisorFilled />;
    case "doubleCheck":
      return <AdvisorFilled />;
    case "downRound":
      return <AdvisorFilled />;
    case "down":
      return <AdvisorFilled />;
    case "downloadArrow":
      return <AdvisorFilled />;
    case "download":
      return <AdvisorFilled />;
    case "drag":
      return <AdvisorFilled />;
    case "edit":
      return <AdvisorFilled />;
    case "error":
      return <AdvisorFilled />;
    case "eyeOff":
      return <AdvisorFilled />;
    case "eye":
      return <AdvisorFilled />;
    case "faq":
      return <AdvisorFilled />;
    case "favFilled":
      return <AdvisorFilled />;
    case "favOutline":
      return <AdvisorFilled />;
    case "fileAttachment":
      return <AdvisorFilled />;
    case "filter":
      return <AdvisorFilled />;
    case "folder":
      return <AdvisorFilled />;
    case "fundFinder":
      return <AdvisorFilled />;
    case "gift":
      return <AdvisorFilled />;
    case "grid":
      return <AdvisorFilled />;
    case "handCoin":
      return <AdvisorFilled />;
    case "history":
      return <AdvisorFilled />;
    case "homeFilled":
      return <AdvisorFilled />;
    case "homeOutline":
      return <AdvisorFilled />;
    case "horizontalMore":
      return <AdvisorFilled />;
    case "hourglass":
      return <AdvisorFilled />;
    case "inbox":
      return <AdvisorFilled />;
    case "income":
      return <AdvisorFilled />;
    case "info":
      return <AdvisorFilled />;
    case "insuranceFilled":
      return <AdvisorFilled />;
    case "insuranceOutline":
      return <AdvisorFilled />;
    case "investFilled":
      return <AdvisorFilled />;
    case "investOutline":
      return <AdvisorFilled />;
    case "justiceScale":
      return <AdvisorFilled />;
    case "language":
      return <AdvisorFilled />;
    case "layers":
      return <AdvisorFilled />;
    case "leftArrow":
      return <AdvisorFilled />;
    case "left":
      return <AdvisorFilled />;
    case "lineChart":
      return <AdvisorFilled />;
    case "link":
      return <AdvisorFilled />;
    case "list":
      return <AdvisorFilled />;
    case "loading":
      return <AdvisorFilled />;
    case "lock":
      return <AdvisorFilled />;
    case "logOut":
      return <AdvisorFilled />;
    case "loss":
      return <AdvisorFilled />;
    case "mail":
      return <AdvisorFilled />;
    case "marketing":
      return <AdvisorFilled />;
    case "medicalCross":
      return <AdvisorFilled />;
    case "message":
      return <AdvisorFilled />;
    case "notificationFilled":
      return <AdvisorFilled />;
    case "notificationOutline":
      return <AdvisorFilled />;
    case "notificationActiveFilled":
      return <AdvisorFilled />;
    case "notificationActiveOutline":
      return <AdvisorFilled />;
    case "passcode":
      return <AdvisorFilled />;
    case "pencilFilled":
      return <AdvisorFilled />;
    case "pencilOutline":
      return <AdvisorFilled />;
    case "phone":
      return <AdvisorFilled />;
    case "pin":
      return <AdvisorFilled />;
    case "play":
      return <AdvisorFilled />;
    case "plus":
      return <AdvisorFilled />;
    case "plusCircle":
      return <AdvisorFilled />;
    case "plusRounded":
      return <AdvisorFilled />;
    case "poster":
      return <AdvisorFilled />;
    case "profile":
      return <AdvisorFilled />;
    case "profit":
      return <AdvisorFilled />;
    case "radioOn":
      return <AdvisorFilled />;
    case "refreshDouble":
      return <AdvisorFilled />;
    case "refresh":
      return <AdvisorFilled />;
    case "report":
      return <AdvisorFilled />;
    case "retirement":
      return <AdvisorFilled />;
    case "right":
      return <AdvisorFilled />;
    case "rocketFilled":
      return <AdvisorFilled />;
    case "rocketOutline":
      return <AdvisorFilled />;
    case "rupee":
      return <AdvisorFilled />;
    case "save":
      return <AdvisorFilled />;
    case "scales":
      return <AdvisorFilled />;
    case "searchRefraction":
      return <AdvisorFilled />;
    case "search":
      return <AdvisorFilled />;
    case "send":
      return <AdvisorFilled />;
    case "settingsFilled":
      return <AdvisorFilled />;
    case "settingsOutline":
      return <AdvisorFilled />;
    case "shareTool":
      return <AdvisorFilled />;
    case "share":
      return <AdvisorFilled />;
    case "shield":
      return <AdvisorFilled />;
    case "sort":
      return <AdvisorFilled />;
    case "starFilled":
      return <AdvisorFilled />;
    case "starOutline":
      return <AdvisorFilled />;
    case "support":
      return <AdvisorFilled />;
    case "switch":
      return <AdvisorFilled />;
    case "tickRound":
      return <AdvisorFilled />;
    case "tool01":
      return <AdvisorFilled />;
    case "tool":
      return <AdvisorFilled />;
    case "trash":
      return <AdvisorFilled />;
    case "truck":
      return <AdvisorFilled />;
    case "umbrella":
      return <AdvisorFilled />;
    case "unlock":
      return <AdvisorFilled />;
    case "upRound":
      return <AdvisorFilled />;
    case "up":
      return <AdvisorFilled />;
    case "upload":
      return <AdvisorFilled />;
    case "userFilled":
      return <AdvisorFilled />;
    case "userOutline":
      return <AdvisorFilled />;
    case "userPlus":
      return <AdvisorFilled />;
    case "usersFilled":
      return <AdvisorFilled />;
    case "usersOutline":
      return <AdvisorFilled />;
    case "vault":
      return <AdvisorFilled />;
    case "verticalMore":
      return <AdvisorFilled />;
    case "video":
      return <AdvisorFilled />;
    case "view":
      return <AdvisorFilled />;
    case "wand":
      return <AdvisorFilled />;
    case "warning":
      return <AdvisorFilled />;
    case "whatsapp":
      return <AdvisorFilled />;
    case "infoCircle":
      return <AdvisorFilled />;
    default:
      return <div></div>;
  }
}

interface APIconProps {
  icon: SVGIcon;
  /**
   * @default 24
   */
  size?: number;
  /**
   * @default APPalette['grey-700']
   */
  color?: string;
  /**
   * @default 0
   */
}

export function APIcon({
  icon,
  size = 24,
  color = APPalette["grey-700"],
}: APIconProps) {
  return React.cloneElement(getIcon(icon), {
    color: color,
    width: size,
    height: size,
  });
}
