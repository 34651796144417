import React, { useState } from "react";
import {
    Box,
    Button,
    Card,
    CardContent,
    Modal,
    Tab,
    Tabs,
    Typography
} from "@mui/material";
import { motion } from "framer-motion";
import SwipeableViews from 'react-swipeable-views';

const PlansModal = ({ open, onClose, plansData, handlePlanSelect }) => {
    const [selectedTab, setSelectedTab] = useState(0);
    const tabKeys = plansData && Object.keys(
        Object.fromEntries(
            Object.entries(plansData).filter(([_, value]) => Array.isArray(value))
        )
    );

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    if (!plansData) return null;

    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    width: '100%',
                    maxWidth: 600,
                    bgcolor: 'background.paper',
                    borderRadius: 2,
                    boxShadow: 24,
                    p: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    margin: 'auto',
                    height: '100%',
                    overflowY: 'auto',
                }}
            >
                <Typography variant="h6" component="h2" gutterBottom>
                    Choose a Plan
                </Typography>
                <Tabs value={selectedTab} onChange={handleTabChange}>
                    {tabKeys.map((key, index) => (
                        <Tab label={key} key={index} sx={{ fontWeight: 'bold' }} />
                    ))}
                </Tabs>

                <SwipeableViews
                    index={selectedTab}
                    onChangeIndex={(index) => setSelectedTab(index)}
                    enableMouseEvents
                >
                    {tabKeys.map((key, tabIndex) => (
                        <Box sx={{ mt: 2 }} key={tabIndex}>
                            {plansData[key].map((item, index) => {
                                const [calls, data, sms, details] = item.desc.split('|').map(s => s.trim());
                                return (
                                    <motion.div
                                        key={index}
                                        initial={{ opacity: 0, scale: 0.9 }}
                                        animate={{ opacity: 1, scale: 1 }}
                                        transition={{ duration: 0.2 }}
                                    >
                                        <Card sx={{ mb: 2 }} onClick={() => handlePlanSelect(item)}>
                                            <CardContent>
                                                <Typography variant="body1" fontWeight="bold">
                                                    ₹{item.rs}
                                                </Typography>
                                                <Typography variant="body2">
                                                    Validity: {item.validity}
                                                </Typography>
                                                <Typography variant="body2" sx={{ mt: 1 }}>
                                                    {calls} | {data} | {sms} | {details}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </motion.div>
                                );
                            })}
                        </Box>
                    ))}
                </SwipeableViews>

                <Button variant="outlined" onClick={onClose} sx={{ mt: 2 }}>
                    Close
                </Button>
            </Box>
        </Modal>
    );
};

export default PlansModal;
