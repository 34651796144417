import { Route, Routes } from "react-router-dom";
import React from "react";
import LoginScreen from "./LoginScreen";
import CustomerOnboardingForm from "./CustomerOnboardingForm";
import Verify from "./Verify";
import IntroScreen from "./IntroScreen";

const DummyRegisterScreen = () => <>Dummy Register Screen</>;

const AuthRouter = () => {
  return (
    <Routes>
      <Route path="intro" element={<IntroScreen />} />
      <Route path="login" element={<LoginScreen />} />
      <Route path="register" element={<DummyRegisterScreen />} />
      <Route path="onboard" element={<CustomerOnboardingForm />} />
      <Route path="verify" element={<Verify />} />
    </Routes>
  );
};

export default AuthRouter;
