import { APPalette } from "codemod_components/utils/APPalette";

export class APTypography {
  static "h0" = {
    fontSize: 42,
    fontWeight: 800,
    lineHeight: 28 / 20,
    color: APPalette["grey-700"],
  };

  static "h1" = {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: 28 / 20,
    color: APPalette["grey-700"],
  };

  static "h2" = {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: 26 / 18,
    color: APPalette["grey-700"],
  };

  static "h3" = {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: 24 / 16,
    color: APPalette["grey-700"],
  };

  static "h4" = {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 20 / 14,
    color: APPalette["grey-700"],
  };

  static "h5" = {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 16 / 12,
    color: APPalette["grey-700"],
    textTransform: "uppercase",
  };

  static "paragraph-xsmall" = {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 16 / 12,
    color: APPalette["grey-600"],
  };

  static "paragraph-small" = {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 20 / 14,
    color: APPalette["grey-600"],
  };

  static "paragraph-medium" = {
    fontSize: 15,
    fontWeight: 400,
    lineHeight: 22 / 15,
    color: APPalette["grey-600"],
  };

  static "paragraph-large" = {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 24 / 16,
    color: APPalette["grey-600"],
  };

  static "button-small" = {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: 16 / 12,
    color: APPalette["grey-700"],
  };

  static "button-medium" = {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 20 / 14,
    color: APPalette["grey-700"],
  };

  static "button-large" = {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: 24 / 16,
    color: APPalette["grey-700"],
  };

  static "link-small" = {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 16 / 12,
    color: APPalette["info-300"],
    textDecoration: "underline",
  };

  static "link-medium" = {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 20 / 14,
    color: APPalette["info-300"],
    textDecoration: "underline",
  };

  static "link-large" = {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 24 / 16,
    color: APPalette["info-300"],
    textDecoration: "underline",
  };
}
