import { useContext, useState } from "react";
import { APFormContext } from "codemod_components/forms/APForm";

import { APButton, ButtonProps } from "./APButton";
import { APRow } from "codemod_components/layout/APFlex";
import { APFormControl } from "codemod_components/forms/useAPForm";

export type APButtonColor = "inherit" | "primary" | "secondary";

export interface IAsyncButtonProps extends ButtonProps {
  onClick?: () => Promise<unknown>;
  nonSubmit?: boolean;
}

export function APAsyncButton(props: IAsyncButtonProps) {
  let { onClick, nonSubmit, children, color, variant, leading, trailing } =
    props;

  // warnDeprecation({ color, variant }, ['color', 'variant'], "APAsyncButton")

  const [disabled, setDisabled] = useState(false);

  async function onclick() {
    if (!disabled) {
      setDisabled(true);
      if (onClick) await onClick();
      setDisabled(false);
    }
  }

  var control2: APFormControl | undefined = useContext(APFormContext);

  if (nonSubmit) control2 = undefined;

  if (control2 !== undefined) {
    control2.submit = onclick;
    console.warn(
      "APAsyncButton should not be used in APForm anymore. Use APFormSubmit instead.",
    );
  }

  var disabled2 = disabled;
  if (!onClick) {
    disabled2 = true;
  }

  let newProps = {
    ...props,
    onClick: !disabled && onClick ? onclick : undefined,
    leading: undefined,
    trailing: undefined,
  };

  return (
    <APButton {...newProps}>
      <>
        <APRow
          style={{
            position: "relative",
            visibility: disabled ? "hidden" : undefined,
          }}
          mainAxisAlignment="center"
          gap="8px"
        >
          {leading}
          {children}
          {trailing}
        </APRow>
        {disabled && <CustomLoading />}
      </>
    </APButton>
  );
}

export function CustomLoading() {
  return (
    <div className="ispinner">
      <div className="ispinner-blade"></div>
      <div className="ispinner-blade"></div>
      <div className="ispinner-blade"></div>
      <div className="ispinner-blade"></div>
      <div className="ispinner-blade"></div>
      <div className="ispinner-blade"></div>
      <div className="ispinner-blade"></div>
      <div className="ispinner-blade"></div>
    </div>
  );
}
