import moment from "moment";

moment.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "a few secs",
    ss: "%d secs",
    m: "a min",
    mm: "%d mins",
    h: "an hour",
    hh: "%d hrs",
    d: "a day",
    dd: "%d days",
    M: "a mth",
    MM: "%d mths",
    y: "a yr",
    yy: "%d yrs",
  },
});

type TextCapitalizationType = "words" | "sentences" | "characters" | "none";

declare global {
  interface Array<T> {
    sum(): number;
    last(): T | undefined;
    sortDir(
      on: (
        a: T,
      ) => number | string | undefined | (number | string | undefined)[],
      dir: "asc" | "desc" | undefined,
    ): Array<T>;
    groupBy(on: (a: T) => string): Array<{ group: string; items: Array<T> }>;
    cumulative(addUp: (a: T, sum: number) => number): number;
  }

  interface Number {
    currency(digits?: number, short?: boolean): string;
    percentage(digits?: number): string;
  }

  interface String {
    /**
     * @type
     * words: "hello world" => "Hello World"
     *
     * sentences: "hello world" => "Hello world"
     *
     * characters: "hello world" => "HELLO WORLD"
     *
     * none: "Hello world" => "hello world"
     */
    textCapitalization(type: TextCapitalizationType): string;
  }
}

export function NArray(length: number): number[] {
  var arr = [];
  for (var i = 0; i < length; i++) {
    arr.push(i);
  }
  return arr;
}

if (!Array.prototype.sum)
  // eslint-disable-next-line no-extend-native
  Array.prototype.sum = function (): number {
    return this.reduce((a: number, b: number) => a + b, 0);
  };

if (!Array.prototype.last) {
  // eslint-disable-next-line no-extend-native
  Array.prototype.last = function () {
    if (!this.length) {
      return undefined;
    }
    return this[this.length - 1];
  };
}

type SORT_VAL = number | string | undefined;
if (!Array.prototype.sortDir) {
  // eslint-disable-next-line no-extend-native
  Array.prototype.sortDir = function (
    on: (a: any) => SORT_VAL | SORT_VAL[],
    dir: "asc" | "desc" | undefined,
  ) {
    if (dir === undefined) {
      return this;
    }

    function diff(aVal: SORT_VAL, bVal: SORT_VAL) {
      if (
        (aVal === null || aVal === undefined) &&
        (bVal === null || bVal === undefined)
      ) {
        return 0;
      }
      if (aVal === null || aVal === undefined) {
        return 1;
      }
      if (bVal === null || bVal === undefined) {
        return -1;
      }
      if (typeof aVal == "string" && typeof bVal == "string") {
        return (dir === "asc" ? 1 : -1) * aVal.localeCompare(bVal);
      }
      if (typeof aVal == "number" && typeof bVal == "number") {
        return (dir === "asc" ? 1 : -1) * (aVal - bVal);
      }
      return 0;
    }

    return this.slice().sort((a, b) => {
      var aVal = on(a);
      var bVal = on(b);
      if (!Array.isArray(aVal) && !Array.isArray(bVal)) {
        return diff(aVal, bVal);
      }
      if (Array.isArray(aVal) && Array.isArray(bVal)) {
        var diffVal = 0;
        for (var i = 0; i < aVal.length; i++) {
          diffVal = diff(aVal[i], bVal[i]);
          if (diffVal !== 0) return diffVal;
        }
      }
      return 0;
    });
  };
}

if (!Array.prototype.groupBy) {
  // eslint-disable-next-line no-extend-native
  Array.prototype.groupBy = function (
    on: (a: any) => string,
  ): Array<{ group: string; items: Array<any> }> {
    var dataMap: any = {};

    this.forEach((item) => {
      var key = on(item);
      if (!dataMap[key]) dataMap[key] = [];

      dataMap[key].push(item);
    });

    return Object.keys(dataMap).map((key) => {
      return {
        group: key,
        items: dataMap[key],
      };
    });
  };
}

if (!Array.prototype.cumulative) {
  // eslint-disable-next-line no-extend-native
  Array.prototype.cumulative = function (
    addUp: (a: any, sum: number) => number,
  ): number {
    var sum = 0;
    this.forEach((item) => {
      sum = addUp(item, sum);
    });
    return sum;
  };
}

if (!Number.prototype.currency) {
  // eslint-disable-next-line no-extend-native
  Number.prototype.currency = function (
    digits: number = 0,
    short: boolean = false,
  ) {
    //@ts-ignore
    let current: number = this;
    var absolute = Math.abs(current);
    if (short) {
      if (absolute >= 10000000)
        return (this.valueOf() / 10000000).currency(digits, true) + "Cr";
      else if (absolute >= 100000)
        return (this.valueOf() / 100000).currency(digits, true) + "L";
      else if (absolute >= 1000)
        return (this.valueOf() / 1000).currency(digits, true) + "K";
      else return this.currency(digits);
    }

    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: digits,
      maximumFractionDigits: digits,
    }).format(this.valueOf());
  };
}

if (!Number.prototype.percentage) {
  // eslint-disable-next-line no-extend-native
  Number.prototype.percentage = function (digits: number = 1) {
    return this.valueOf().toFixed(digits) + "%";
  };
}

if (!String.prototype.textCapitalization) {
  String.prototype.textCapitalization = function (
    type: TextCapitalizationType,
  ) {
    switch (type) {
      case "words":
        return this.split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
      case "sentences":
        return this.charAt(0).toUpperCase() + this.slice(1);
      case "characters":
        return this.toUpperCase();
      case "none":
        return this.toLowerCase();
      default:
        return this.valueOf();
    }
  };
}
