import { Avatar, Box, Button, CircularProgress, Grid, Modal, Paper, Typography } from "@mui/material";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useLoadingWrapper } from "../../utils/useLoadingWrapper";
import axios from "axios";
import { v4 as uuid } from 'uuid';
import { useCountDown } from "../../codemod_components/hooks/useCountDown";
import { QRCodeSVG } from 'qrcode.react';
import Dialog from "@mui/material/Dialog";
import { formatTimeLeft, openDeepLink } from "../../utils/string-utils";

const RechargePaymentModal = ({
                                  open,
                                  selectedRegion,
                                  selectedPlan,
                                  selectedOperator,
                                  selectedRechargeType,
                                  onClose,
                                  selectedCircle,
                                  selectedMobileNumber
                              }) => {
    const [timeLeft, { start }] = useCountDown(5 * 60 * 1000, 1000);
    const [invoiceDetail, setInvoiceDetail] = useState(null);

    const [generateRechargePaymentLink, generateRechargePaymentLinkLoading] = useLoadingWrapper(async () => {
        const { data } = await axios.post("https://lms.codemodulo.in/recharge/v1/user/recharge",{
            recharge_mobile: selectedMobileNumber,
            operator: selectedOperator,
            circle: selectedCircle,
            recharge_amount: Number(selectedPlan.rs),
            type: selectedRechargeType,
            client_request_id: uuid(),
            partner_customer_id: uuid(),
        }, {headers: {Authorization: "Bearer DZ8BARXWgsaorcgS3uwtLtvQ_I4l6CubuS2ggt4JZfNyap05czx-NWgIl3URBmnfNvsgUxQ"}});

        if (data) {
            setInvoiceDetail(data.data.payment);
            openDeepLink(data.data.payment.deep_link);
            console.log(data.data.payment.deep_link)
            start();
        }
    });

    const [checkPaymentComplete, checkPaymentCompleteLoading] = useLoadingWrapper(async () => {
        if (!invoiceDetail) return;
        const { data } = await axios.get(`https://lms.codemodulo.in/recharge/v1/user/status/${invoiceDetail.invoice_id}`, {headers: {Authorization: "Bearer DZ8BARXWgsaorcgS3uwtLtvQ_I4l6CubuS2ggt4JZfNyap05czx-NWgIl3URBmnfNvsgUxQ"}});
        console.log(data.data);
    });

    useEffect(() => {
        if (timeLeft <= 0 && invoiceDetail) {
            onClose();
        } else if (timeLeft % 5000 === 0 && invoiceDetail?.invoice_id) {
            checkPaymentComplete();
        }
    }, [timeLeft]);

    useEffect(() => {
        if (selectedPlan) generateRechargePaymentLink();
    }, [selectedPlan]);

    const RenderPaymentInitiatedModal = () => (
        <Dialog open={!!invoiceDetail} onClose={onClose} fullWidth maxWidth="sm">
            <Box component={motion.div} initial={{ y: '100%' }} animate={{ y: 0 }} exit={{ y: '100%' }} sx={{ padding: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {invoiceDetail?.upi_link && <QRCodeSVG value={invoiceDetail?.upi_link} style={{ width: 150, height: 150 }} />}
                <Typography variant="body2" color="textSecondary" sx={{ marginTop: 1 }}>Scan to Pay</Typography>
                <Typography variant="h6" component="div" sx={{ mt: 2 }}>Complete Payment in:</Typography>
                <Typography variant="h4" sx={{ color: '#f5222d', margin: '8px 0' }}>{formatTimeLeft(timeLeft)}</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: '100%' }}>
                    <Button variant="contained" color="primary" onClick={checkPaymentComplete} disabled={checkPaymentCompleteLoading} fullWidth>
                        {checkPaymentCompleteLoading ? <CircularProgress size={24} /> : 'Payment Completed?'}
                    </Button>
                    <Button variant="outlined" color="error" onClick={onClose} fullWidth>Cancel Payment</Button>
                </Box>
            </Box>
        </Dialog>
    );

    if (!selectedPlan) return null;

    return (
        <Modal open={open} onClose={onClose}>
            <Paper
                elevation={4}
                sx={{
                    width: '100%',
                    maxWidth: 600,
                    bgcolor: 'background.paper',
                    borderRadius: 2,
                    boxShadow: 24,
                    p: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    margin: 'auto',
                    height: '100%',
                    overflowY: 'auto',
                }}
            >
                <Box sx={{ textAlign: 'center', marginBottom: 3 }}>
                    <Typography variant="h5" fontWeight="600" gutterBottom>Recharge</Typography>
                    <Typography variant="body1" color="textSecondary">Paying for Prepaid</Typography>
                </Box>
                <Box sx={{ textAlign: 'center', marginBottom: 3 }}>
                    <Typography variant="h3" fontWeight="bold" color="primary">₹ {selectedPlan.rs}</Typography>
                    <Typography variant="body1" sx={{ color: '#757575' }}>Recharge for +91 {selectedMobileNumber}</Typography>
                </Box>
                <Box sx={{ textAlign: 'center', marginBottom: 2 }}>
                    <Typography variant="body2" color="textSecondary">Select a UPI App to Make Payment</Typography>
                </Box>
                <Grid container spacing={3} justifyContent="center">
                    {[
                        { name: 'Paytm', icon: '/icons/paytm.png' },
                        { name: 'PhonePe', icon: '/icons/phonepe.png' },
                        { name: 'GPay', icon: '/icons/gpay.png' },
                        { name: 'Other UPI', icon: '/icons/upi.png' },
                    ].map(app => (
                        <Grid item key={app.name}>
                            <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }} transition={{ type: 'spring', stiffness: 300 }}>
                                <Button onClick={generateRechargePaymentLink} variant="outlined" sx={{
                                    width: 100,
                                    height: 100,
                                    borderRadius: '16px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderColor: '#ddd',
                                    '&:hover': { borderColor: 'primary.main' },
                                }}>
                                    <Avatar src={app.icon} alt={app.name} sx={{ width: 50, height: 50, marginBottom: 1 }} />
                                    <Typography variant="caption" sx={{ color: '#555' }}>{app.name}</Typography>
                                </Button>
                            </motion.div>
                        </Grid>
                    ))}
                </Grid>
                {RenderPaymentInitiatedModal()}
            </Paper>
        </Modal>
    );
}

export default RechargePaymentModal;
