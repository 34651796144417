interface Palettes {
  "brand-100": string;
  "brand-200": string;
  "brand-300": string;
  "brand-400": string;
  "brand-500": string;

  "negative-100": string;
  "negative-200": string;
  "negative-300": string;
  "negative-400": string;
  "negative-500": string;

  "positive-100": string;
  "positive-200": string;
  "positive-300": string;
  "positive-400": string;
  "positive-500": string;

  "warning-100": string;
  "warning-300": string;
  "warning-200": string;
  "warning-500": string;
  "warning-400": string;

  "info-100": string;
  "info-200": string;
  "info-300": string;
  "info-500": string;
  "info-400": string;

  "grey-50": string;
  "grey-100": string;
  "grey-200": string;
  "grey-300": string;
  "grey-400": string;
  "grey-500": string;
  "grey-600": string;
  "grey-700": string;
  "grey-800": string;
  "grey-900": string;

  white: string;
  black: string;
}

export class APPalette {
  static "brand-100" = "rgb(213, 224, 242)";
  static "brand-200" = "rgb(128, 161, 217)";
  static "brand-300" = "rgb(43, 98, 192)";
  static "brand-400" = "rgb(34, 77, 150)";
  static "brand-500" = "rgb(27, 62, 120)";

  static "negative-100" = "rgb(255, 232, 231)";
  static "negative-200" = "rgb(243, 110, 102)";
  static "negative-300" = "rgb(213, 31, 47)";
  static "negative-400" = "rgb(188, 10, 0)";
  static "negative-500" = "rgb(141, 8, 0)";

  static "positive-100" = "rgb(214, 244, 222, 1)";
  static "positive-200" = "rgb(133, 221, 155, 1)";
  static "positive-300" = "rgb(52, 199, 89, 1)";
  static "positive-400" = "rgb(42, 159, 71, 1)";
  static "positive-500" = "rgb(31, 119, 53, 1)";

  static "warning-100" = "rgb(255, 235, 204)";
  static "warning-300" = "rgb(255, 153, 0)";
  static "warning-200" = "rgb(255, 194, 102)";
  static "warning-500" = "rgb(153, 92, 0)";
  static "warning-400" = "rgb(204, 122, 0)";

  static "info-100" = "rgb(221, 218, 241)";
  static "info-200" = "rgb(154, 143, 214)";
  static "info-300" = "rgb(87, 69, 186)";
  static "info-500" = "rgb(52, 41, 112)";
  static "info-400" = "rgb(70, 55, 149)";

  static "grey-50" = "rgb(249, 250, 251)";
  static "grey-100" = "rgb(244, 246, 250)";
  static "grey-200" = "rgb(226, 230, 239)";
  static "grey-300" = "rgb(203, 210, 226)";
  static "grey-400" = "rgb(148, 160, 184)";
  static "grey-500" = "rgb(96, 108, 132)";
  static "grey-600" = "rgb(65, 73, 91)";
  static "grey-700" = "rgb(35, 39, 48)";
  static "grey-800" = "rgb(27, 31, 40)";
  static "grey-900" = "rgb(10, 13, 20)";

  static "white" = "rgb(255, 255, 255)";
  static "black" = "rgb(0, 0, 0)";

  static withOpacity(colorName: keyof Palettes, opacity: number): string {
    const color = APPalette[colorName];
    if (!color) {
      throw new Error(`Color "${colorName}" not found in the APPalette.`);
    }

    // Ensure the opacity value is between 0 and 1
    const validOpacity = Math.min(Math.max(opacity, 0), 1);

    // Check if the color is in "rgb(...)" format
    if (color.startsWith("rgb(")) {
      return color.replace("rgb(", `rgba(`).replace(")", `, ${validOpacity})`);
    }

    // Check if the color is in "#..." format
    if (color.startsWith("#")) {
      return `${color}${Math.floor(validOpacity * 255).toString(16)}`;
    }

    // If the color format is not recognized, throw an error
    throw new Error(`Unrecognized color format for "${colorName}".`);
  }
}
