import { CSSProperties, useState } from "react";
import { APPalette } from "codemod_components/utils/APPalette";

export interface APIconButtonProps {
  children: JSX.Element;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  /**
   * @default APPalette["grey-100"]
   */
  hoverColor?: string;
  /**
   * @default 0
   */
  padding?: string;
  style?: CSSProperties;
}

/**
 * @author `Abhishek Sinha`
 */
export default function APIconButton({
  children,
  onClick,
  hoverColor = APPalette["grey-100"],
  padding,
  style,
}: APIconButtonProps) {
  const [onHover, setOnHover] = useState(false);

  return (
    <div
      onClick={onClick}
      onMouseEnter={() => setOnHover(true)}
      onMouseLeave={() => setOnHover(false)}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "50%",
        transition: "background-color 0.2s",
        backgroundColor: onHover ? hoverColor : "transparent",
        cursor: onClick ? "pointer" : "default",
        padding: padding,
        ...style,
      }}
    >
      {children}
    </div>
  );
}
