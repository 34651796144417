import { Deferred } from "codemod_components/utils/Deferred";
import ReactHtmlParser from "html-react-parser";
import { APButton } from "codemod_components/elements/APButton";
import { APRow } from "codemod_components/layout/APFlex";
import { APDialogCard } from "codemod_components/elements/APDialogCard";
import { APText } from "codemod_components/elements/APText";
import ErrorIcon from "@mui/icons-material/Error";

export function ErrorDialog(props: {
  deferred: Deferred<void>;
  title?: string;
  message: string;
  yesAction?: string;
}) {
  return (
    <APDialogCard
      style={{ minWidth: "300px", maxWidth: "500px" }}
      title={props.title ?? "Error"}
      leading={<ErrorIcon />}
      actions={
        <APRow mainAxisAlignment="end" gap="8px">
          <APButton
            size="small"
            onClick={() => {
              props.deferred.resolve();
            }}
            style={{ width: "40%" }}
          >
            {props.yesAction ?? "Okay"}{" "}
          </APButton>
        </APRow>
      }
    >
      <APText variant="paragraph-small" maxLines={2}>
        {ReactHtmlParser(props?.message || "Something went wrong")}{" "}
      </APText>
    </APDialogCard>
  );
}
