import { Box, Button, IconButton } from "@mui/material";
import React, { useRef, useState } from "react";
import AttachmentIcon from "@mui/icons-material/Attachment";
import DeleteIcon from "@mui/icons-material/Delete";

interface FileUploaderProps {
  accept?: string;
  onSelectFile: (file: File) => void;
  onDeleteFile: () => void;
  disabled?: boolean;
  file?: File | null;
}

const FileUploader: React.FC<FileUploaderProps> = ({
  accept = "application/pdf",
  onSelectFile,
  onDeleteFile,
  disabled = false,
  file = null,
}) => {
  const hiddenFileInput = useRef<HTMLInputElement | null>(null);
  const [localFile, setLocalFile] = useState<File | null>(file);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0] || null;
    if (selectedFile) {
      setLocalFile(selectedFile);
      onSelectFile(selectedFile);
    }
  };

  const handleDelete = () => {
    setLocalFile(null);
    if (hiddenFileInput.current) {
      hiddenFileInput.current.value = "";
    }
    onDeleteFile();
  };

  return (
    <Box
      sx={{
        border: "1px solid #ced4da",
        borderRadius: "15px",
        display: "flex",
        alignItems: "center",
        padding: "0.5rem",
        width: "100%",
        overflowX: "hidden",
        marginBottom: "1rem",
      }}
    >
      <Button
        sx={{ display: "flex", justifyContent: "flex-start" }}
        onClick={handleClick}
        disabled={disabled}
        fullWidth
      >
        <AttachmentIcon />
        <input
          type="file"
          accept={accept}
          ref={hiddenFileInput}
          onChange={handleChange}
          hidden
          disabled={disabled}
          data-testid="file-upload-input"
        />
        <Box
          ml={1}
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {localFile ? localFile.name : "Choose file"}
        </Box>
      </Button>
      {localFile && !disabled && (
        <IconButton
          aria-label="delete"
          disabled={disabled}
          color="primary"
          onClick={handleDelete}
        >
          <DeleteIcon />
        </IconButton>
      )}
    </Box>
  );
};

export default FileUploader;
