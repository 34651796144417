// src/components/CustomTopBar.tsx
import React, { useEffect } from "react";
import { IconButton, Badge, Typography, Box, Divider } from "@mui/material";
import {
  Notifications as NotificationsIcon,
  Settings as SettingsIcon,
} from "@mui/icons-material";
import { motion } from "framer-motion";
import { styled } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@reduxjs/toolkit/query";
import { showLoadingDialog } from "../../codemod_components/dialogs/showLoadingDialog";
import { getIt } from "../../codemod_components/dialogs/DialogHelper";
import FinacuityProfileApiServices from "../../requests/UserRequests";
import { dispatchUserProfile } from "../../store/slices/authenticationSlice";

const TopBarContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "0 16px",
  height: "60px",
  backgroundColor: "#fff",
  color: "#000",
  width: "100%",
  top: 0,
  left: 0,
});

const IconWithName = styled("div")({
  display: "flex",
  alignItems: "center",
});

const NameContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  height: "100%",
});

const CustomerName = styled(Typography)({
  fontSize: "18px",
  fontWeight: "bold",
});

const WelcomeText = styled(Typography)({
  fontSize: "14px",
  lineHeight: 1,
  marginTop: "2px", // Space between name and welcome text
});

const IconLabel = styled("div")({
  display: "flex",
  alignItems: "center",
  fontSize: "18px",
  fontWeight: "bold",
  backgroundColor: "#000",
  color: "#fff",
  borderRadius: "50%",
  width: "40px",
  height: "40px",
  justifyContent: "center",
  marginRight: "8px",
});

const TopBar: React.FC = () => {
  const dispatch = useDispatch();
  // @ts-ignore
  const { user, applications, profile } = useSelector(
    (state: RootState<any, any, any>) => state.authenticationSlice,
  );

  const fetchProfileData = async () => {
    const { data, status_code } = await showLoadingDialog(
      async () => await getIt(FinacuityProfileApiServices).getMeProfileData(),
    );
    if (status_code === 0) {
      dispatch(dispatchUserProfile(data?.profile));
    }
  };

  useEffect(() => {
    if (!profile || Object.keys(profile).length === 0) fetchProfileData();
  }, []);

  const [notifications] = React.useState<number>(0);

  return (
    <motion.div
      initial={{ y: -50 }}
      animate={{ y: 0 }}
      transition={{ type: "spring", stiffness: 300 }}
    >
      <TopBarContainer>
        <IconWithName>
          <IconLabel>
            {profile?.name?.substring(0, 1).toUpperCase() || "User"}
          </IconLabel>
          <NameContainer>
            <WelcomeText>Welcome</WelcomeText>
            <CustomerName>{profile?.name}</CustomerName>
          </NameContainer>
        </IconWithName>
        <Box>
          <IconButton color="inherit">
            <Badge badgeContent={notifications} color="error">
              <NotificationsIcon sx={{ color: "#000" }} />
            </Badge>
          </IconButton>
          <IconButton color="inherit" sx={{ ml: 2 }}>
            <SettingsIcon sx={{ color: "#000" }} />
          </IconButton>
        </Box>
      </TopBarContainer>
    </motion.div>
  );
};

export default TopBar;
