import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { BASE_CONFIG } from "../config";

export const convertB64StringToBytes = (base64String) => {
  const base64Data = base64String.split(",")[1]; // Get everything after the comma
  const byteCharacters = atob(base64Data);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  return new Uint8Array(byteNumbers);
};

export const convertBytesToBase64Image = (bytesArray) => {
  const bytes = new Uint8Array(bytesArray);
  let binaryString = "";
  bytes.forEach((byte) => {
    binaryString += String.fromCharCode(byte);
  });
  const base64String = btoa(binaryString);
  return `data:image/jpeg;base64,${base64String}`;
};

export const convertBytesToBase64PDF = (bytesArray) => {
  const bytes = new Uint8Array(bytesArray);
  let binaryString = "";
  bytes.forEach((byte) => {
    binaryString += String.fromCharCode(byte);
  });
  const base64String = btoa(binaryString);
  return `data:application/pdf;base64,${base64String}`;
};

export const collectUserInfo = async () => {
  let userInfo = {
    geoLocationData: undefined,
    pageName: undefined,
    browserData: null,
  };
  try {
    const geoLocationResponse = await fetch("https://ipapi.co/json/");
    userInfo.geoLocationData = await geoLocationResponse.json();
    const fp = await FingerprintJS.load();
    const result = await fp.get();
    userInfo["fingerPrint"] = result.components;
  } catch (error) {
    console.error("Error fetching geolocation data:", error);
  }
  userInfo.pageName = window.location.href;
  userInfo.browserData = {
    browserName: navigator.userAgent,
    cookieEnabled: navigator.cookieEnabled,
    browserLanguage: navigator.language,
    os: navigator.platform,
    userAgent: navigator.userAgent,
    pluginsInstalled: Array.from(navigator.plugins).map(
      (plugin) => plugin.name,
    ),
    browserVersion: navigator.appVersion,
    screenWidth: window.screen.width,
    screenHeight: window.screen.height,
    screenPixelDepth: window.screen.pixelDepth,
    screenColorDepth: window.screen.colorDepth,
    userLat: userInfo.geoLocationData
      ? userInfo.geoLocationData.latitude
      : null,
    userLong: userInfo.geoLocationData
      ? userInfo.geoLocationData.longitude
      : null,
  };

  return userInfo;
};

export function formatNumber(number: number): string {
  if (Math.abs(number) >= 1000000) {
    return (number / 1000000).toFixed(1) + "M";
  } else if (Math.abs(number) >= 1000) {
    return (number / 1000).toFixed(1) + "T";
  } else {
    return number.toString();
  }
}

const track = async (etype, eprops, orderHash = "") => {
  if (etype === "" || orderHash === "") {
    return;
  }

  const fp = await FingerprintJS.load();
  const result = await fp.get();
  const extendedComponents = {
    ...result.components,
    userString: { value: "" },
  };
  // @ts-ignore
  const visitorId = FingerprintJS.hashComponents(extendedComponents);
  const platformType =
    document.getElementsByTagName("body")[0].getAttribute("view") || "mobile";
  const tracker = {
    user_id: visitorId,
    device_id: visitorId,
    platform: platformType || "NoPlatform",
    language: navigator.language,
    event_type: "PGA " + etype.toLowerCase(),
    app_version: "CoreJs",
    event_properties: undefined,
  };

  if (eprops) {
    tracker.event_properties = eprops;
  }

  const base64Data = btoa(JSON.stringify(tracker));
  fetch(`${BASE_CONFIG.BASE_URL}/analytics/${orderHash}/track`, {
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({
      data: base64Data,
    }),
  });
};

export const formatCurrency = (amount) => {
  if (
    amount === null ||
    amount === undefined ||
    isNaN(Number(amount)) ||
    Number(amount) === 0
  ) {
    return "---";
  }
  return new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0,
  }).format(Number(amount));
};

export function convertDateFormat(dateStr) {
  if (dateStr === "") return;
  const [day, month, year] = dateStr.split("/");
  return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
}

export const convertFileToBase64 = (file) => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (typeof reader.result === "string") {
        resolve(reader.result);
      } else {
        reject(new Error("File reading error"));
      }
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};



export const camelCaseToWords = (str) => {
  var output = '';
  var len = str.length;
  var char;

  for (var i = 0; i < len; i++) {
    char = str.charAt(i);

    if (i == 0) {
      output += char.toUpperCase();
    } else if (char !== char.toLowerCase() && char === char.toUpperCase()) {
      output += ' ' + char;
    } else if (char == '-' || char == '_') {
      output += ' ';
    } else {
      output += char;
    }
  }

  return output;
};

export const formatTimeLeft = (milliseconds) => {
  const minutes = Math.floor(milliseconds / 1000 / 60);
  const seconds = Math.floor(milliseconds / 1000) % 60;
  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};


export const openDeepLink = (url) => {
  if (typeof window !== 'undefined') {
    window.location.href = url;
  }
};
