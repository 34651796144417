export const REGEX = {
  PAN: /^([A-Z]{3})([ABCFGHLJPTK])([A-Z])(\d{4})([A-Z])$/,
  EMAIL: /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/,
  PHONE: /^[0-9]{10}$/,
  PIN: /^\d{6}$/,
  IFSC: /^[A-Z]{4}0[A-Z0-9]{6}$/,
  AADHAAR: /^\d{12}$/,
  GST: /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}$/,
  GST_USERNAME: /^\w{4,}$/,
  GST_PASSWORD: /^.+$/,
  CIN: /^([LUu]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$/,
  NAME_REGEX: /^[A-Za-z][A-Za-z'-]*\s[A-Za-z][A-Za-z'-]*$/,
};
