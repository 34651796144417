import React, { useEffect, useState } from 'react';
import { Box, TextField, MenuItem, FormControl, Radio, RadioGroup, FormControlLabel, Button, Autocomplete } from '@mui/material';
import { motion } from 'framer-motion';
import { useLoadingWrapper } from "../../utils/useLoadingWrapper";
import axios from "axios";
import { useForceRender } from "../../codemod_components/hooks/useForceUpdate";
import PlansModal from "./RechargePlansModal";
import RechargePaymentModal from "./RechargePaymentModal";
import {useNavigate} from "react-router-dom";

const MobileRechargeForm = () => {
    const [selectedRechargeType, setSelectedRechargeType] = useState('prepaid');
    const [mobileNumber, setMobileNumber] = useState('');
    const [operators, setOperators] = useState([]);
    const [selectedOperator, setSelectedOperator] = useState('');
    const [selectedRegion, setSelectedRegion] = useState('');
    const [amount, setAmount] = useState('');
    const [regions, setRegions] = useState([]);
    const [plansData, setPlansData] = useState(null);
    const [openPlansModal, setOpenPlansModal] = useState(false);
    const navigate = useNavigate();
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [selectedCircle, setSelectedCircle] = useState(null);
    const { key, reload } = useForceRender();

    const [openPayModal, setOpenPayModal] = useState(false);

    const handleOpenPlansModal = () => {
        getOperatorPlans();
        setOpenPlansModal(true);
    };

    const handleClosePlansModal = () => setOpenPlansModal(false);

    const [getOperatorPlans, getOperatorPlansLoading] = useLoadingWrapper(async () => {
        const circle = regions.find(region => region.regions.includes(selectedRegion));
        if (circle) {
            setSelectedCircle(circle.circle);
            const { data } = await axios.get(`https://lms.codemodulo.in/recharge/v1/recharge/plans`, {
                params: {
                    operator: selectedOperator,
                    mobile: mobileNumber,
                    circle: circle.circle,
                    type: selectedRechargeType
                }
            });
            if (data) {
                setPlansData(data.data.plans);
            }
        }
    });

    const [getCirclesRegions, getCirclesRegionsLoading] = useLoadingWrapper(async () => {
        const { data } = await axios.get("https://lms.codemodulo.in/recharge/v1/recharge/operators-circles");
        if (data) {
            setOperators(data?.data.operator_circles.operators)
            setRegions(data?.data.operator_circles.circles);
            setSelectedOperator(data?.data.operator_circles.operators[0]); // Set default operator
        }
    });

    useEffect(() => {
        getCirclesRegions();
    }, []);

    const filteredRegions = regions.flatMap(item =>
        item.regions.map(region => ({ circle: item.circle, region }))
    );

    const handlePlanSelect = (plan) => {
        setSelectedPlan(plan);
        setAmount(plan.rs);
        handleClosePlansModal();
        reload();
    };

    const handleRechargeNowSubmitButton = (event) => {
        event.preventDefault();
        const circle = regions.find(region => region.regions.includes(selectedRegion));
        console.log(event);
        console.log(selectedRechargeType,mobileNumber, selectedOperator, circle?.circle)
        navigate("/recharge/login")
    };

    return (
        <Box
            component={motion.div}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
            sx={{
                maxWidth: 400,
                margin: 'auto',
                p: 3,
                borderRadius: 2,
                boxShadow: 3,
                backgroundColor: '#fff'
            }}
        >
            <form onSubmit={handleRechargeNowSubmitButton} key={key}>
                {/* Plan Type */}
                <FormControl component="fieldset" fullWidth>
                    <RadioGroup row value={selectedRechargeType} onChange={(e) => setSelectedRechargeType(e.target.value)}>
                        <FormControlLabel value="prepaid" control={<Radio />} label="Prepaid" />
                        <FormControlLabel value="postpaid" control={<Radio />} label="Postpaid" />
                    </RadioGroup>
                </FormControl>

                {/* Mobile Number */}
                <TextField
                    label="Mobile Number"
                    variant="outlined"
                    fullWidth
                    value={mobileNumber}
                    onChange={(e) => setMobileNumber(e.target.value)}
                    sx={{ mt: 2 }}
                />

                {/* Operator */}
                <FormControl fullWidth sx={{ mt: 2 }}>
                    <TextField
                        label="Operator"
                        select
                        value={selectedOperator}
                        onChange={(e) => setSelectedOperator(e.target.value)}
                    >
                        {operators.length > 0 && operators.map(operator => (
                            <MenuItem key={operator} value={operator}>
                                {operator}
                            </MenuItem>
                        ))}
                    </TextField>
                </FormControl>

                {/* Region Search */}
                <Autocomplete
                    freeSolo
                    fullWidth
                    options={filteredRegions.map(option => option.region)}
                    value={selectedRegion}
                    onChange={(event, newValue) => setSelectedRegion(newValue)}
                    renderInput={(params) => (
                        <TextField {...params} label="Search Region" variant="outlined" sx={{ mt: 2 }} />
                    )}
                />

                {/* Amount Field */}
                <Box display="flex" alignItems="center" sx={{ mt: 2 }}>
                    <TextField
                        label="Amount"
                        variant="outlined"
                        type="number"
                        fullWidth
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                        sx={{ mr: 1, width: selectedRechargeType === "prepaid" ? '50%' : '100%' }}
                    />
                    {selectedRechargeType === "prepaid" && (
                        <Button variant="contained" color="primary" sx={{ width: '50%' }} onClick={handleOpenPlansModal}>
                            Browse Plans
                        </Button>
                    )}
                </Box>

                {/* Submit Button */}
                <Button
                    type="submit"
                    component={motion.button}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    variant="contained"
                    fullWidth
                    sx={{ mt: 3 }}
                >
                    Recharge
                </Button>
            </form>
            <PlansModal
                open={openPlansModal}
                onClose={handleClosePlansModal}
                plansData={plansData}
                handlePlanSelect={handlePlanSelect}
            />
            <RechargePaymentModal
                selectedCircle={selectedCircle}
                open={openPayModal}
                onClose={() => setOpenPayModal(false)}
                selectedPlan={selectedPlan}
                selectedMobileNumber={mobileNumber}
                selectedOperator={selectedOperator}
                selectedRegion={selectedRegion}
                selectedRechargeType={selectedRechargeType}
            />
        </Box>
    );
};

export default MobileRechargeForm;
