import { APGrid } from "codemod_components/layout/APGrid";
import { showLoadingDialog } from "codemod_components/dialogs/showLoadingDialog";
import { getIt } from "codemod_components/dialogs/DialogHelper";
import { showErrorDialog } from "codemod_components/dialogs/showErrorDialog";
import React, { useEffect, useState } from "react";
import { APFormFieldText } from "codemod_components/forms/APFormFieldText";
import { REGEX } from "codemod_components/utils/REGEX";
import { useDispatch, useSelector } from "react-redux";
import { updateCoiForm } from "../../../store/slices/onboardingSlice";
import { useForceRender } from "codemod_components/hooks/useForceUpdate";
import axios from "axios";
import { convertBytesToBase64PDF } from "../../../utils/string-utils";
import { APForm } from "codemod_components/forms/APForm";
import { useAPForm } from "codemod_components/forms/useAPForm";
import { APFormSubmit } from "codemod_components/forms/APFormSubmit";
import { APExpanded } from "codemod_components/layout/Common";
import { APRow } from "codemod_components/layout/APFlex";
import { APTag } from "../../../codemod_components/elements/APTag";
import { APFormFieldPDF } from "codemod_components/forms/APFormFieldPdf";
import OnboardingApiServices from "../../../requests/OnboardingRequests";
import { APFormFieldDropdown } from "codemod_components/forms/APFormFieldDropdown";
import { useLocation, useNavigate } from "react-router-dom";

const RenderOcrInfoStatus = ({ kycForm, onChange }) => {
  if (!kycForm) return null;
  return (
    <>
      <APFormFieldText
        label="Company Name"
        initialValue={kycForm.company_name?.value}
        onChanged={(value) => onChange("company_name", value)}
        validator={(value) => {
          return null;
        }}
      />
      <APFormFieldText
        label="CIN"
        initialValue={kycForm.corporate_identity_number?.value}
        onChanged={(value) => onChange("corporate_identity_number", value)}
        validator={(value) => {
          if (!value || REGEX.CIN.test(value) == false) {
            return "Please enter a valid CIN number";
          }
          return null;
        }}
      />
      <APFormFieldText
        label="Incorporation Date"
        initialValue={kycForm.incorporation_date?.value}
        onChanged={(value) => onChange("incorporation_date", value)}
        validator={(value) => {
          if (!value || value.length < 10) {
            return "Please enter a valid date";
          }
          return null;
        }}
        type="date"
      />
      <APFormFieldText
        label="Certificate Issued By"
        initialValue={kycForm.certificate_issued_by?.value}
        onChanged={(value) => onChange("certificate_issued_by", value)}
        validator={(value) => {
          if (!value || value.length < 3) {
            return "Please enter a valid name";
          }
          return null;
        }}
      />
      <APFormFieldText
        label="Certificate Issued Date"
        initialValue={kycForm.certificate_issued_date?.value}
        onChanged={(value) => onChange("certificate_issued_date", value)}
        validator={(value) => {
          if (!value || value.length < 10) {
            return "Please enter a valid date";
          }
          return null;
        }}
        type="date"
      />
      <APFormFieldDropdown
        label="Company Type"
        items={[
          {
            value: "Company limited by shares",
            label: "Company limited by shares",
          },
          {
            value: "Company limited by shares",
            label: "Company limited by shares",
          },
        ]}
        initialValue={kycForm.company_type?.value}
        onChanged={(value) => onChange("company_type", value)}
        validator={(value) => {
          if (!value || value.length < 3) {
            return "Please enter a valid name";
          }
          return null;
        }}
      />
    </>
  );
};

const CoiForm = ({ goToNextStep, goToPrevStep, isLastStep }) => {
  const navigate = useNavigate();
  const coiFormControl = useAPForm();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const { coi, session_id, token, session_meta } = useSelector(
    (state: any) => state.onboardingSlice,
  );

  const { key, reload } = useForceRender();

  const preFillInfo = async () => {
    await showLoadingDialog(async () => {
      let { data, status_code } = await getIt(
        OnboardingApiServices,
      ).getSessionDetail(session_id, token);
      const coiPrefilledObject = data?.session?.coi;
      if (coiPrefilledObject?.doc_identifier) {
        let { data, status_code } = await showLoadingDialog(
          async () =>
            await getIt(OnboardingApiServices).downloadDocPreSignedUrl(
              session_id,
              "company_coi",
              token,
            ),
        );
        if (status_code === 0 && Array.isArray(data) && data.length > 0) {
          const response = await axios.get(data[0].url, {
            responseType: "arraybuffer",
          });
          if (response.status === 200) {
            dispatch(
              updateCoiForm({
                ...coiPrefilledObject,
                base64Image: convertBytesToBase64PDF(response.data),
              }),
            );
            reload();
          }
        }
      }
    });
  };

  useEffect(() => {
    preFillInfo();
  }, []);

  const handleImageUpload = async (value) => {
    if (value && value !== coi?.base64Image) {
      try {
        await showLoadingDialog(async () => {
          const resp = await getIt(OnboardingApiServices).generatePreSignedUrl(
            session_id,
            "company_coi",
            token,
          );
          await getIt(OnboardingApiServices).uploadDocumentToPreSignedUrl(
            resp.data.url,
            value,
          );
          const ocrResponseStatus = await getIt(
            OnboardingApiServices,
          ).performExecuteActionOnDocument(session_id, "company_coi", token);
          dispatch(
            updateCoiForm({
              base64Image: value,
              doc_identifier: resp.data.doc_identifier,
              ocr_extract: ocrResponseStatus?.data,
            }),
          );
          reload();
        });
      } catch (err) {
        await showErrorDialog(err.message);
      }
    }
  };

  const handlerSubmitInfo = async () => {
    const ocrResponseStatus = await getIt(
      OnboardingApiServices,
    ).updateSessionInfo(
      session_id,
      {
        coi: {
          ocr_extract: { ...coi.ocr_extract },
          doc_identifier: coi.doc_identifier,
        },
      },
      token,
    );
    if (isLastStep) {
      navigate(`/onboarding/${session_id}/success`);
    } else {
      goToNextStep();
    }
  };

  return (
    <>
      <APForm control={coiFormControl} style={{ width: "100%" }}>
        <APGrid count={1} columnGap={"16px"} key={key}>
          <APFormFieldPDF
            label="COI"
            src={coi?.base64Image}
            onChange={handleImageUpload}
            validator={(file) => {
              if (!file) {
                return "Please upload a valid image";
              }
              return null;
            }}
          />
          <RenderOcrInfoStatus
            kycForm={coi?.ocr_extract || {}}
            onChange={(label: any, value: any) =>
              dispatch(
                updateCoiForm({
                  ocr_extract: { ...coi.ocr_extract, ...{ [label]: value } },
                }),
              )
            }
          />
        </APGrid>
      </APForm>
      {errorMessage && <APTag variant="warning">{errorMessage}</APTag>}
      <APRow style={{ padding: "0px 16px 16px 16px" }}>
        <APExpanded>
          <APFormSubmit
            size="large"
            onClick={async () => {
              if (await coiFormControl.validate()) {
                try {
                  await handlerSubmitInfo();
                } catch (err: any) {
                  setErrorMessage(err.message);
                }
              }
            }}
          >
            Next
          </APFormSubmit>
        </APExpanded>
      </APRow>
    </>
  );
};

export default CoiForm;
