import React, { useState } from "react";
import { showLoadingDialog } from "../../../../codemod_components/dialogs/showLoadingDialog";
import { getIt } from "../../../../codemod_components/dialogs/DialogHelper";
import FinacuityLoanApplicationApiServices from "../../../../requests/LoanapplyRequests";
import { APFormFieldText } from "../../../../codemod_components/forms/APFormFieldText";
import { REGEX } from "../../../../codemod_components/utils/REGEX";
import { APButton } from "../../../../codemod_components/elements/APButton";
import { APSizedBox } from "../../../../codemod_components/layout/Common";
import { APOtp } from "../../../../codemod_components/forms/APOtp";
import IconButton from "@mui/material/IconButton";
import ReplayIcon from "@mui/icons-material/Replay";
import { APRow } from "../../../../codemod_components/layout/APFlex";
import { useCountDown } from "../../../../codemod_components/hooks/useCountDown";
import { showErrorDialog } from "../../../../codemod_components/dialogs/showErrorDialog";

const GstDetailExtractForm = ({
  kybForm,
  setKybForm,
  goToNextStep,
  goToPrevStep,
}) => {
  const [timeLeft, { start }] = useCountDown(60000, 1000);
  const [componentStateVars, setComponentStateVars] = useState({
    portal_user_name: "",
    otp_requested: false,
    otp_value: null,
  });
  const handleGetOTP = async () => {
    try {
      const { data, error, status_code } = await showLoadingDialog(
        async () =>
          await getIt(
            FinacuityLoanApplicationApiServices,
          ).generateGstPortalAccessOtp(
            kybForm?.application?.application_id,
            kybForm?.gstin,
            componentStateVars.portal_user_name,
          ),
        "Generating GST OTP!",
      );
      if (status_code === 0) {
        setComponentStateVars((prev) => ({
          ...prev,
          ...{ otp_requested: true },
        }));
      } else {
        await showErrorDialog(
          error?.message || "Incorrect GST Portal UserName",
        );
      }
    } catch (error) {
      console.error("Error fetching OTP:", error);
    }
  };

  return (
    <>
      <APFormFieldText
        style={{ width: "100%" }}
        textAppearance={"uppercase"}
        label="GST Number"
        initialValue={kybForm?.gstin}
        disabled={true}
      />
      <APFormFieldText
        style={{ width: "100%" }}
        label="Enter your GST Portal User Name"
        initialValue={kybForm?.portalUsername}
        onChanged={(v) =>
          setComponentStateVars((p) => ({ ...p, ...{ portal_user_name: v } }))
        }
        validator={(value) =>
          !value || !REGEX.GST_USERNAME.test(value)
            ? "Please enter a valid GST portal Username"
            : null
        }
        disabled={componentStateVars.otp_requested}
      />
      <APSizedBox height="12px" />
      {componentStateVars.otp_requested && (
        <APRow>
          <APOtp
            digits={6}
            onChanged={(v) =>
              setComponentStateVars((p) => ({ ...p, ...{ otp_value: v } }))
            }
          />
          <IconButton disabled={timeLeft > 0} onClick={console.log}>
            {timeLeft / 1000} <ReplayIcon />
          </IconButton>
        </APRow>
      )}
      <APButton style={{ width: "100%" }} onClick={handleGetOTP}>
        {componentStateVars.otp_requested ? "Submit OTP" : "Generate OTP"}
      </APButton>
    </>
  );
};

export default GstDetailExtractForm;
