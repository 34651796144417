import { CircularProgress } from "@mui/material";
import { launchDialog } from "codemod_components/dialogs/DialogHelper";
import { APText } from "codemod_components/elements/APText";
import { APCenter, APSizedBox } from "codemod_components/layout/Common";
import { APRow } from "codemod_components/layout/APFlex";

export async function showLoadingDialog<T>(
  promise: () => Promise<T>,
  loadingMessage = "Please wait",
): Promise<T> {
  return await launchDialog(
    (deferred) => {
      promise()
        .then((result) => {
          deferred.resolve(result);
        })
        .catch((error) => {
          deferred.reject(error);
        });

      return (
        <APSizedBox height="70px" width="180px">
          <APCenter>
            <APRow mainAxisSize="min" crossAxisAlignment="center" gap="20px">
              <APText variant="paragraph-medium">{loadingMessage}</APText>{" "}
              <CircularProgress size={"20px"} />
            </APRow>
          </APCenter>
        </APSizedBox>
      );
    },
    { disableClose: true },
  );
}
