import { APGrid } from "codemod_components/layout/APGrid";
import { showLoadingDialog } from "codemod_components/dialogs/showLoadingDialog";
import { getIt } from "codemod_components/dialogs/DialogHelper";
import OnboardingApiServices from "../../../requests/OnboardingRequests";
import { showErrorDialog } from "codemod_components/dialogs/showErrorDialog";
import { APFormFieldImage } from "codemod_components/forms/APFormFieldImage";
import React, { useEffect, useState } from "react";
import { APFormFieldText } from "codemod_components/forms/APFormFieldText";
import { REGEX } from "codemod_components/utils/REGEX";
import { APFormFieldDropdown } from "codemod_components/forms/APFormFieldDropdown";
import { OccupationTable } from "../IntroScreen";
import { useDispatch, useSelector } from "react-redux";
import { updatePoiForm } from "../../../store/slices/onboardingSlice";
import { useForceRender } from "codemod_components/hooks/useForceUpdate";
import axios from "axios";
import { convertBytesToBase64Image } from "../../../utils/string-utils";
import { APForm } from "codemod_components/forms/APForm";
import { useAPForm } from "codemod_components/forms/useAPForm";
import { APFormSubmit } from "codemod_components/forms/APFormSubmit";
import { APExpanded } from "codemod_components/layout/Common";
import { APRow } from "codemod_components/layout/APFlex";
import { APTag } from "../../../codemod_components/elements/APTag";
import { useLocation, useNavigate } from "react-router-dom";

const RenderOcrInfoStatus = ({ kycForm, onChange, disabled }) => {
  if (!kycForm) return null;
  return (
    <>
      <APFormFieldText
        label="Name"
        initialValue={kycForm.name}
        onChanged={(value) => onChange("name", value)}
        validator={(value) => (!value ? "Please enter a valid name" : null)}
        disabled={disabled}
      />
      <APFormFieldText
        label="PAN"
        initialValue={kycForm.number}
        onChanged={(value) => onChange("number", value)}
        validator={(value) =>
          !value || !REGEX.PAN.test(value)
            ? "Please enter a valid PAN number"
            : null
        }
        disabled={disabled}
      />
      <APFormFieldText
        label="Date of Birth"
        initialValue={kycForm.dob}
        onChanged={(value) => onChange("dob", value)}
        validator={(value) =>
          !value || value.length < 10 ? "Please enter a valid date" : null
        }
        type="date"
        disabled={disabled}
      />
      {/*<APFormFieldDropdown<"MARRIED" | "UNMARRIED">*/}
      {/*  label="Marital Status"*/}
      {/*  items={[*/}
      {/*    { value: "MARRIED", label: "Married" },*/}
      {/*    { value: "UNMARRIED", label: "Unmarried" },*/}
      {/*  ]}*/}
      {/*  initialValue={kycForm.maritalStatus}*/}
      {/*  onChanged={(value) => onChange("maritalStatus", value)}*/}
      {/*  validator={(value) => (!value ? "Please select marital status" : null)}*/}
      {/*  disabled={disabled}*/}
      {/*/>*/}
      {/*<APFormFieldDropdown*/}
      {/*  label="Occupation"*/}
      {/*  items={OccupationTable}*/}
      {/*  initialValue={kycForm.occupationCode}*/}
      {/*  onChanged={(value) =>*/}
      {/*    onChange(*/}
      {/*      "occupationCode",*/}
      {/*      OccupationTable.find((a) => a.value === value)?.label || ""*/}
      {/*    )*/}
      {/*  }*/}
      {/*  validator={(value) => (!value ? "Please select occupation" : null)}*/}
      {/*  disabled={disabled}*/}
      {/*/>*/}
      {/*<APFormFieldDropdown*/}
      {/*  label="Political Exposure"*/}
      {/*  items={[*/}
      {/*    { value: "n/a", label: "Not applicable" },*/}
      {/*    { value: "pep", label: "Politically Exposed Person" },*/}
      {/*    { value: "rpep", label: "Related to Politically Exposed Person" },*/}
      {/*  ]}*/}
      {/*  initialValue={kycForm.politicalExposure}*/}
      {/*  onChanged={(value) => onChange("politicalExposure", value)}*/}
      {/*  validator={(value) =>*/}
      {/*    !value ? "Please select political exposure" : null*/}
      {/*  }*/}
      {/*  disabled={disabled}*/}
      {/*/>*/}
      <APFormFieldText
        label="Father's Name"
        initialValue={kycForm.father_name}
        onChanged={(value) => onChange("father_name", value)}
        validator={(value) =>
          !value || value.length < 3 ? "Please enter a valid name" : null
        }
        disabled={disabled}
      />
      <APFormFieldText
        label="Mother's Name"
        initialValue={kycForm.motherName}
        onChanged={(value) => onChange("motherName", value)}
        validator={(value) =>
          !value || value.length < 3 ? "Please enter a valid name" : null
        }
        disabled={disabled}
      />
    </>
  );
};

const PoiForm = ({ goToNextStep, goToPrevStep, isLastStep }) => {
  const navigate = useNavigate();
  const poiFormControl = useAPForm();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const { poi, session_id, token } = useSelector(
    (state: any) => state.onboardingSlice,
  );
  const { key, reload } = useForceRender();

  const preFillInfo = async () => {
    await showLoadingDialog(async () => {
      let { data, status_code } = await getIt(
        OnboardingApiServices,
      ).getSessionDetail(session_id, token);
      const poiPrefilledObject = data?.session?.poi;
      if (poiPrefilledObject?.doc_identifier) {
        let { data, status_code } = await showLoadingDialog(
          async () =>
            await getIt(OnboardingApiServices).downloadDocPreSignedUrl(
              session_id,
              "individual_pan",
              token,
            ),
        );
        if (status_code === 0 && Array.isArray(data) && data.length > 0) {
          const response = await axios.get(data[0].url, {
            responseType: "arraybuffer",
          });
          if (response.status === 200) {
            dispatch(
              updatePoiForm({
                ...poiPrefilledObject,
                base64Image: convertBytesToBase64Image(response.data),
              }),
            );
            reload();
          }
        }
      }
    });
  };

  useEffect(() => {
    preFillInfo();
  }, []);

  const handleImageUpload = async (value) => {
    if (value && value !== poi?.base64Image) {
      try {
        await showLoadingDialog(async () => {
          const resp = await getIt(OnboardingApiServices).generatePreSignedUrl(
            session_id,
            "individual_pan",
            token,
          );
          await getIt(OnboardingApiServices).uploadDocumentToPreSignedUrl(
            resp.data.url,
            value,
          );
          const ocrResponseStatus = await getIt(
            OnboardingApiServices,
          ).performExecuteActionOnDocument(session_id, "individual_pan", token);
          dispatch(
            updatePoiForm({
              base64Image: value,
              doc_identifier: resp.data.doc_identifier,
              ocr_extract: ocrResponseStatus?.data,
            }),
          );
          reload();
        });
      } catch (err) {
        await showErrorDialog(err.message);
      }
    }
  };

  const handlerSubmitInfo = async () => {
    if (poi?.status !== "completed") {
      await getIt(OnboardingApiServices).updateSessionInfo(
        session_id,
        {
          poi: {
            ocr_extract: { ...poi.ocr_extract },
            doc_identifier: poi.doc_identifier,
          },
        },
        token,
      );
    }
    if (isLastStep) {
      navigate(`/onboarding/${session_id}/success`);
    } else {
      goToNextStep();
    }
  };

  return (
    <>
      <APForm control={poiFormControl} style={{ width: "100%" }}>
        <APGrid count={1} columnGap={"16px"} key={key}>
          <APFormFieldImage
            label="PAN Card"
            src={poi?.base64Image}
            onChange={handleImageUpload}
            validator={(file) => (!file ? "Please upload a valid image" : null)}
            disabled={poi?.status === "completed"}
          />
          <RenderOcrInfoStatus
            kycForm={poi?.ocr_extract || {}}
            onChange={(label: any, value: any) =>
              dispatch(
                updatePoiForm({
                  ocr_extract: { ...poi.ocr_extract, ...{ [label]: value } },
                }),
              )
            }
            disabled={poi?.status === "completed"}
          />
        </APGrid>
      </APForm>
      {errorMessage && <APTag variant="warning">{errorMessage}</APTag>}
      <APRow style={{ padding: "0px 16px 16px 16px" }}>
        <APExpanded>
          <APFormSubmit
            size="large"
            onClick={async () => {
              if (await poiFormControl.validate()) {
                try {
                  await handlerSubmitInfo();
                } catch (err: any) {
                  setErrorMessage(err.message);
                }
              }
            }}
          >
            Next
          </APFormSubmit>
        </APExpanded>
      </APRow>
    </>
  );
};

export default PoiForm;
