import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useMemo } from "react";
import { APCenter } from "codemod_components/layout/Common";
import { APTag } from "codemod_components/elements/APTag";
import { APJourneyApp } from "codemod_components/stepper_app/APJourneyApp";
import { APColumn } from "codemod_components/layout/APFlex";
import { collectUserInfo } from "../../utils/string-utils";
import { showLoadingDialog } from "codemod_components/dialogs/showLoadingDialog";
import { getIt } from "codemod_components/dialogs/DialogHelper";
import OnboardingApiServices from "../../requests/OnboardingRequests";
import { useForceRender } from "codemod_components/hooks/useForceUpdate";
import OnboardingFooter from "./Footer";
import OnboardingHeader from "./Header";
import EsignForm from "./forms/esignForm";
import { useDispatch, useSelector } from "react-redux";
import {
  setSessionMeta,
  updateOnBoardingSession,
} from "../../store/slices/onboardingSlice";
import OnboardingAuth from "./OnboardingAuth";
import PoiForm from "./forms/poiForm";
import PoaForm from "./forms/poaForm";
import BankForm from "./forms/bankForm";
import VideoForm from "./forms/videoForm";
import PhotoForm from "./forms/photoForm";
import NomineeForm from "./forms/nomineeForm";
import CoiForm from "./forms/coiForm";
import GstForm from "./forms/gstForm";

const Onboarding = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const session = location.pathname.split("/").slice(-1);
  let session_id = null;
  if (Array.isArray(session)) {
    session_id = session[0];
  }
  if (!navigator.mediaDevices) {
    alert("Your browser does not support the verification process,");
    return;
  }
  const { key, reload } = useForceRender();

  const { session_meta, token, session_status } = useSelector(
    (state: any) => state.onboardingSlice,
  );

  const fetchMetaDetail = async (session_id: string) => {
    const { data, status_code } = await showLoadingDialog(
      async () => await getIt(OnboardingApiServices).getSessionMeta(session_id),
    );
    if (status_code === 0) {
      dispatch(setSessionMeta({ session_id, session_meta: data }));
    }
  };

  const getSteps = useMemo(() => {
    const maps = {
      poi: PoiForm,
      bank: BankForm,
      poa: PoaForm,
      signature: (...args) => <></>,
      esign: EsignForm,
      video: VideoForm,
      photo: PhotoForm,
      gst: GstForm,
      coi: CoiForm,
      nominee: NomineeForm,
    };
    return (
      session_meta &&
      session_meta.steps.map((i) => ({
        title: i?.step_title,
        description: i?.secondary_header,
        form: maps[i?.type],
        onSubmit: () => null,
      }))
    );
  }, [session_meta]);

  const checkSessionTokenExists = async () => {
    let accessToken = window.localStorage.getItem("kma");
    if (accessToken) {
      try {
        await showLoadingDialog(async () => {
          let { data, status_code } = await getIt(
            OnboardingApiServices,
          ).getSessionDetail(session_id, accessToken);
          if (status_code === 0) {
            dispatch(
              updateOnBoardingSession({
                token: accessToken,
              }),
            );
          } else if (status_code === 401) {
            window.localStorage.removeItem("kma");
            navigate(`/onboarding/${session_id}`);
          }
        });
      } catch (error) {
        console.error("Error checking session token:", error);
        window.localStorage.removeItem("kma");
        navigate(`/onboarding/${session_id}`);
      }
    } else {
      navigate(`/onboarding/${session_id}`);
    }
    await fetchMetaDetail(session_id);
  };

  useEffect(() => {
    checkSessionTokenExists();
  }, []);

  useEffect(() => {
    if (token) asyncPushPreliminaryInfo(token);
  }, [token]);

  const asyncPushPreliminaryInfo = async (token: string) => {
    const forensics = await collectUserInfo();
    await showLoadingDialog(
      async () =>
        await getIt(OnboardingApiServices).updateSessionInfo(
          session_id,
          { user_forensics: forensics },
          token,
        ),
    );
  };

  if (!session_meta) {
    return (
      <APCenter>
        <APTag variant="info">
          User Not Found, Please Contact kyc@codemodulo.in
        </APTag>
      </APCenter>
    );
  }

  const handleCompletion = () => {
    navigate(`/onboarding/${session_id}/success`);
  };

  return (
    <>
      <OnboardingHeader meta={session_meta} />
      <APColumn>
        {token ? (
          <APJourneyApp
            key={key}
            style={{ width: "100%", height: "100%" }}
            mode="linear"
            title={session_meta?.header}
            description={session_meta?.minor_purpose || null}
            getSteps={getSteps}
            handleCompletion={handleCompletion}
            canChangeSteps={true}
          />
        ) : (
          <OnboardingAuth session_meta={session_meta} />
        )}
        <OnboardingFooter
          partnerLogo={session_meta?.partner_info?.partner_logo}
        />
      </APColumn>
    </>
  );
};

export default Onboarding;
