import { AxiosInstance } from "axios";
import { singleton } from "tsyringe";
import authenticatedHttp from "./baseHttp";
import { BASE_CONFIG } from "../config";

@singleton()
export default class FinacuityProfileApiServices {
  instance: AxiosInstance = authenticatedHttp;
  baseUrl: string = BASE_CONFIG.BASE_URL;

  async getMeProfileData() {
    let res = await this.instance.request({
      method: "GET",
      url: this.baseUrl + `/vapi/v1/profile/me`,
    });
    return res.data;
  }

  async exchangeRefreshToken(refreshToken: string) {
    let res = await this.instance.request({
      method: "POST",
      url: this.baseUrl + `/vapi/v1/auth/refresh-token`,
      headers: { str: refreshToken },
    });
    return res.data;
  }

  async getLoginOtp(mobile) {
    let res = await this.instance.request({
      method: "POST",
      url: this.baseUrl + `/vapi/v1/auth/sign-in-otp`,
      data: { mobile },
    });
    return res.data;
  }

  async login(mobile: any, otp: any) {
    let res = await this.instance.request({
      method: "POST",
      url: this.baseUrl + `/vapi/v1/auth/sign-in`,
      data: { mobile, otp },
    });
    return res.data;
  }

  async updateUserProfile(patchObj: any) {
    let res = await this.instance.patch(
      `${this.baseUrl}/vapi/v1/profile/me`,
      patchObj,
    );
    return res.data;
  }

  async getHomeScreenProducts() {
    let res = await this.instance.get(
      `${this.baseUrl}/vapi/v1/products/products`,
    );
    return res.data;
  }
}
