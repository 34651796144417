import { getIt } from "../../codemod_components/dialogs/DialogHelper";

export const checkSessionTokenExists = async (
  accessTokenKeyName = "sta",
  refreshTokenKeyName = "str",
  authModule,
  callbackFunction,
) => {
  const refreshToken = window.localStorage.getItem(refreshTokenKeyName);
  if (refreshToken && refreshToken.startsWith("e")) {
    try {
      const { data, status_code } =
        await getIt(authModule).exchangeRefreshToken(refreshToken);
      if (status_code === 0) {
        window.localStorage.setItem(accessTokenKeyName, data?.token);
        if (callbackFunction) callbackFunction(data);
      } else {
        window.localStorage.removeItem(accessTokenKeyName);
        window.localStorage.removeItem(refreshTokenKeyName);
      }
    } catch (e) {
      window.localStorage.removeItem(accessTokenKeyName);
      window.localStorage.removeItem(refreshTokenKeyName);
    }
  }
};
