import React from "react";

export interface APInkWellProps {
  /**
   * wrapper to provide `click` feature
   */
  onClick: () => any;
  children?: any;
  style?: React.CSSProperties;
  /**
   * disable the `onClick` attribute if provided
   */
  disabled?: boolean;
}

/**
 * @author `Abhishek Sinha`
 * @param APInkWellProps
 */
export function APInkWell(props: APInkWellProps) {
  var mStyles: React.CSSProperties = {
    display: "flex",
  };

  if (props.disabled) {
    mStyles = {
      color: "grey",
    };
  } else {
    mStyles = {
      cursor: "pointer",
    };
  }

  return (
    <div
      style={{ ...props.style, ...mStyles }}
      onClick={props.disabled ? () => {} : props.onClick}
    >
      {props.children}
    </div>
  );
}
