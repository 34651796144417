import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const authenticationSlice = createSlice({
  name: "authentication",
  initialState: {
    onboardingState: null,
    isHydrated: false,
    user: null,
    token: null,
    refresh: null,
    profile: {},
    applications: null,
  },
  reducers: {
    dispatchRegisterCurrentUser(state, action) {
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.refresh = action.payload.refresh;
      return state;
    },
    dispatchRefreshedToken(state, action) {
      state.token = action.payload.token;
      return state;
    },
    dispatchUserProfile(state, action) {
      state.profile = action.payload;
      return state;
    },
    dispatchUpdateUserLoanApplications(state, action) {
      state.applications = action.payload;
      return state;
    },
  },
});

export const {
  dispatchRegisterCurrentUser,
  dispatchRefreshedToken,
  dispatchUserProfile,
  dispatchUpdateUserLoanApplications,
} = authenticationSlice.actions;

export default authenticationSlice.reducer;
