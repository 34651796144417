import Button from "@mui/material/Button";
import React from "react";
import { APRow } from "codemod_components/layout/APFlex";
import { APPalette } from "codemod_components/utils/APPalette";

export type ButtonSize = "small" | "medium" | "large";
export type ButtonType = "primary" | "secondary" | "tertiary" | "negative";
export type MUIButtonVariant = "text" | "outlined" | "contained";

export interface ButtonProps {
  children: React.ReactNode;
  /**
   * @default medium
   */
  size?: ButtonSize;
  /**
   * @default primary
   */
  type?: ButtonType;
  style?: React.CSSProperties;
  /**
   * button enters disabled state if `onClick` is undefined
   */
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  /**
   * add any react node before the button label
   */
  leading?: JSX.Element;
  /**
   * add any react node after the button label
   */
  trailing?: JSX.Element;
  /**
   * @deprecated use attribute `type` instead
   */
  variant?: "contained" | "outlined" | "text";
  /**
   * @deprecated use attribute `type` instead
   */
  color?: "default" | "primary" | "secondary" | "inherit";
  /**
   * @deprecated pass undefined to `onClick` attribute instead
   */
  disabled?: boolean;
}

export function warnDeprecation(item: Object, values: string[], node: string) {
  for (const key of values) {
    if (item.hasOwnProperty(key)) {
      console.warn(`Property '${key}' is deprecated from ${node}`);
    }
  }
}

/**
 * @author `Abhishek Sinha`
 */
export function APButton({
  type = "primary",
  size = "medium",
  style = { width: "80%" },
  children,
  onClick,
  variant,
  color,
  disabled,
  leading,
  trailing,
}: ButtonProps) {
  switch (variant) {
    case "contained":
      type = "primary";
      break;
    case "outlined":
      type = "secondary";
      break;
    case "text":
      type = "secondary";
      break;
  }

  let muiVariant: MUIButtonVariant = "contained";
  let muiColor: "inherit" | "primary" | "secondary" | "default" = "primary";

  switch (type) {
    case "secondary":
      muiVariant = "outlined";
      muiColor = "primary";
      break;
    // @ts-ignore
    case "negative":
      muiVariant = "text";
      muiColor = "secondary";
      break;
    // @ts-ignore
    case "tertiary":
      muiVariant = "outlined";
      muiColor = "primary";
      break;
  }

  return (
    <Button
      size={size}
      style={style}
      onClick={onClick}
      color={muiColor}
      variant={muiVariant}
      disabled={disabled || !onClick}
      disableElevation
      sx={
        // @ts-ignore
        type === "tertiary"
          ? {
              "&.MuiButton-outlinedPrimary": {
                fontWeight: 400,
                color: APPalette["grey-600"],
                borderRadius: "40px",
                borderColor: APPalette["grey-200"],
                "&.Mui-disabled": {
                  opacity: 0.5,
                  border: `1px solid ${APPalette["grey-200"]}`,
                },
                "&:hover": {
                  backgroundColor: APPalette["grey-200"],
                  border: `1px solid ${APPalette["grey-200"]}`,
                },
                "&:active": {
                  backgroundColor: APPalette["grey-200"],
                  border: `1px solid ${APPalette["grey-200"]}`,
                },
              },
            }
          : undefined
      }
    >
      <APRow gap="4px" mainAxisAlignment="center">
        {leading}
        {children}
        {trailing}
      </APRow>
    </Button>
  );
}
