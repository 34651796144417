import React, { useEffect } from "react";
import CheckIcon from "@mui/icons-material/Check";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const Success = () => {
  const navigate = useNavigate();
  const { session_id } = useParams();
  const { session_meta } = useSelector((state: any) => state.onboardingSlice);

  const redirectUri = session_meta?.redirect_uri;

  const goBack = () => {
    if (redirectUri) {
      window.location.href = redirectUri;
    } else {
      navigate(`/onboarding/${session_id}`);
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (redirectUri) {
        window.location.href = redirectUri;
      } else {
        navigate(`/onboarding/${session_id}`);
      }
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [navigate, session_id, redirectUri]);

  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        mt={20}
        ml={10}
        mr={10}
      >
        <Box border={"2px dotted yellow"} borderRadius={"50%"} padding={"1rem"}>
          <Box
            border={"2px dotted yellow"}
            borderRadius={"50%"}
            padding={"1rem"}
          >
            <Box
              border={"0.1px solid black"}
              borderRadius={"50%"}
              bgcolor={"black"}
              padding={"0.4rem"}
            >
              <CheckIcon style={{ fontSize: "4rem", color: "white" }} />
            </Box>
          </Box>
        </Box>
      </Box>
      <Typography mt={5} textAlign={"center"}>
        Your KYC has been successfully completed.
      </Typography>
      <Box textAlign={"center"} mt={5}>
        <Button onClick={goBack} variant="contained">
          Go Back
        </Button>
      </Box>
    </>
  );
};

export default Success;
