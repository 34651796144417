import Axios, { AxiosInstance } from "axios";
import { singleton } from "tsyringe";
import axios from "axios";
import {
  convertB64StringToBytes,
  convertBytesToBase64Image,
} from "../utils/string-utils";
import { BASE_CONFIG } from "../config";

@singleton()
export default class OnboardingApiServices {
  instance: AxiosInstance = Axios.create({
    baseURL: BASE_CONFIG.BASE_URL + "/kavach/onboarding",
    // withCredentials: true,
  });

  constructor() {
    this.instance.interceptors.request.use(
      (config) => {
        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );

    this.instance.interceptors.response.use(
      (v) => v,
      (error) => {
        if (error?.response?.data?.userMsg)
          error.message = error.response.data.userMsg;
        return Promise.reject(error);
      },
    );
  }

  async getSessionMeta(session_id) {
    let res = await this.instance.request({
      method: "GET",
      url: `v1/${session_id}`,
    });
    return res.data;
  }
  async getSessionDetail(session_id, token) {
    let res = await this.instance.request({
      method: "GET",
      url: `v1/session/${session_id}`,
      headers: { kma: token },
    });
    return res.data;
  }
  async updateSessionInfo(session_id, data, token) {
    await this.instance.request({
      method: "POST",
      url: `/v1/update/${session_id}`,
      data,
      headers: { kma: token },
    });
  }
  async generateOtpForSession(session_id) {
    let res = await this.instance.request({
      method: "POST",
      url: `/v1/otp/${session_id}`,
    });
    return res.data;
  }
  async verifyOtpForSession(session_id, otp) {
    let res = await this.instance.request({
      method: "POST",
      url: `/v1/login/${session_id}/${otp}`,
    });
    return res.data;
  }
  async generatePreSignedUrl(session_id, doc_type, token) {
    let res = await this.instance.request({
      method: "POST",
      url: `/v1/upload/${session_id}/${doc_type}`,
      headers: { kma: token },
    });
    return res.data;
  }
  async downloadDocPreSignedUrl(session_id, doc_type, token) {
    let res = await this.instance.request({
      method: "GET",
      url: `/v1/docs/${session_id}/${doc_type}`,
      headers: { kma: token },
    });
    return res.data;
  }
  async uploadDocumentToPreSignedUrl(url, data) {
    let res = await axios.put(url, convertB64StringToBytes(data));
    return res.data;
  }
  async uploadVideoDocumentToPreSignedUrl(url, data, headers) {
    let res = await axios.put(url, data);
    return res.data;
  }
  async performExecuteActionOnDocument(session_id, doc_type, token) {
    let res = await this.instance.request({
      method: "POST",
      url: `/v1/execute/${session_id}/${doc_type}`,
      headers: { kma: token },
    });
    return res.data;
  }

  async getPDFDetail(session_id, token) {
    let res = await this.instance.request({
      method: "POST",
      url: `/v1/e-sign/${session_id}`,
      headers: { kma: token },
    });

    return res.data;
  }

  async getCaptcha(session_id, token) {
    let res = await this.instance.request({
      method: "GET",
      url: `/v1/e-sign/otp/${session_id}`,
      headers: { kma: token },
    });

    return res.data;
  }

  async sendOtpCaptcha(session_id, data, token) {
    let res = await this.instance.request({
      method: "POST",
      url: `/v1/e-sign/otp/${session_id}`,
      data,
      headers: { kma: token },
    });

    return res.data;
  }
}
