import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const partnerSlice = createSlice({
  name: "product",
  initialState: {
    products: [],
    services: [],
  },
  reducers: {
    dispatchProductsDetails(state, action) {
      state.products = action.payload.products;
      state.services = action.payload.services;
      return state;
    },
  },
});

export const { dispatchProductsDetails } = partnerSlice.actions;

export default partnerSlice.reducer;
