import { Route, Routes } from "react-router-dom";
import ApplyLamfLoan from "./Lamf/Lamf";
import ApplyBusinessLoan from "./Business/Business";
import React from "react";
import ProtectedRoute from "../../global-hooks/ProtectedRoute";
import ApplyPersonalLoan from "./Personal/Personal";
import LoanApplicationWrapperContainer from "./LoanApplicationWrapperContainer";

const LoansApplyRouter = () => {
  return (
    <>
      <Routes>
        <Route
          path="lamf"
          element={
            <ProtectedRoute>
              <ApplyLamfLoan />
            </ProtectedRoute>
          }
        />
        <Route
          path="business"
          element={
            <ProtectedRoute>
              <ApplyBusinessLoan />
            </ProtectedRoute>
          }
        />
        <Route
          path="personal"
          element={
            <ProtectedRoute>
              <ApplyPersonalLoan />
            </ProtectedRoute>
          }
        />
      </Routes>
    </>
  );
};

export default LoansApplyRouter;
