import React from 'react';
import { motion } from 'framer-motion';
import { Card, CardActionArea, CardContent, Typography, Box } from '@mui/material';
import {APText} from "../../../codemod_components/elements/APText";
import {APPalette} from "../../../codemod_components/utils/APPalette";
import {APColumn, APRow} from "../../../codemod_components/layout/APFlex";

interface AdditionalInfo {
  interest_range: [number, number];
  tenure_range: [number, number];
  amount_range: [number, number];
}

interface Product {
  productName: string;
  productBanner: string;
  productTitle: string;
  productDescription: string;
  additional: AdditionalInfo;
  is_live: boolean;
}

interface ProductCardProps {
  product: Product;
  onProductClick: (product: Product) => void;
}

const ProductCard: React.FC<ProductCardProps> = ({ product, onProductClick }) => {
  return (
      <motion.div
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          transition={{ type: 'spring', stiffness: 300 }}
      >
        <Card sx={{ maxWidth: '100%', mb: 1, mt: 1}}>
          <CardActionArea
              onKeyPress={() => onProductClick(product)}
              onClick={() => onProductClick(product)}
          >
            <CardContent>
              <APRow>
                <img
                    src={`data:image/jpeg;base64,${product.productBanner}`}
                    alt={product.productTitle}
                    style={{ width: '30%', height: 'auto', marginRight: '16px' }}
                />
                <APColumn>
                  <APText color={APPalette["grey-700"]} variant="h5" maxLines={1}>
                    {product.productTitle}
                  </APText>
                  {product.productDescription.split('\n').map((line, index) => (
                      <React.Fragment key={index}>
                        <APText color={APPalette["grey-700"]} variant="normal" maxLines={2}>
                          {line}
                        </APText>
                        <br />
                      </React.Fragment>
                  ))}
                </APColumn>
              </APRow>
            </CardContent>
          </CardActionArea>
        </Card>
      </motion.div>
  );
};

export default ProductCard;
