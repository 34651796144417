import { APText } from "../../../codemod_components/elements/APText";
import { APPalette } from "../../../codemod_components/utils/APPalette";
import { formatCurrency } from "../../../utils/string-utils";
import { APSizedBox } from "../../../codemod_components/layout/Common";
import { APColumn } from "../../../codemod_components/layout/APFlex";
import { Divider } from "@mui/material";
import { APAlign } from "../../../codemod_components/layout/APStack";
import { Tag } from "../../../codemod_components/elements/APMediaCard";
import React from "react";

const LamfLoanApprovedCommercials = ({ application }) => {
  return (
    <>
      <APColumn gap={"12px"} mainAxisSize="max" crossAxisAlignment="start">
        <APText variant="h3">{application.application_id}</APText>
        <APText variant="paragraph-small">
          {Number(application?.provisional?.loan_amount || 0) > 0
            ? "Congratulations, Based on your mutual fund holdings, your application is approved"
            : "Your held Mutual Funds dont support our policy"}
        </APText>
        <APText
          variant="subtext"
          color={APPalette["grey-400"]}
          maxLines={1}
          style={{ maxWidth: "320px" }}
        >
          <APText variant="h3">
            {formatCurrency(application?.provisional?.loan_amount || 0)}{" "}
          </APText>
        </APText>
        <APSizedBox height="8px" />
        <APColumn crossAxisAlignment="start" gap="12px">
          <APText variant="subtext">
            ROI : <strong>8% to 16%</strong>
            <br />
            Loan Tenure: <strong>12 to 36 Months</strong>
          </APText>
        </APColumn>
        <Divider />
        {Number(application?.provisional?.loan_amount || 0) > 0 ? (
          <APAlign align={"topRight"} noClone>
            <APColumn gap="8px" crossAxisAlignment="end">
              <Tag label={"Approved"} color={APPalette["positive-300"]} />
            </APColumn>
          </APAlign>
        ) : (
          <APAlign align={"topRight"} noClone>
            <APColumn gap="8px" crossAxisAlignment="end">
              <Tag label={"Declined"} color={APPalette["positive-300"]} />
            </APColumn>
          </APAlign>
        )}
      </APColumn>
    </>
  );
};

export default LamfLoanApprovedCommercials;
