import React, { useState, useEffect } from "react";
import { Box, IconButton } from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import slide1 from "../../images/banners/slide1.jpeg";
import slide2 from "../../images/banners/slide2.jpeg";
import slide3 from "../../images/banners/partnerLogin1.png";

const images = [slide1, slide2, slide3];

const IntroScreen = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    if (currentIndex === images.length - 1) {
      const timer = setTimeout(() => {
        navigate("/auth/login");
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [currentIndex, navigate]);

  const goToNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1,
    );
  };

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1,
    );
  };

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "55%",
          position: "relative",
          overflow: "hidden",
          marginBottom: "1rem",
        }}
      >
        <img
          src={images[currentIndex]}
          alt={`Slide ${currentIndex + 1}`}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "fill",
            transition: "transform 0.5s ease-in-out",
          }}
        />
      </Box>
      <Box
        sx={{
          position: "absolute",
          bottom: "16px",
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          paddingLeft: "2rem",
          paddingRight: "2rem",
          paddingBottom: "1rem",
        }}
      >
        <IconButton
          sx={{
            backgroundColor: "rgba(11, 156, 49, 0.5)",
            color: "white",
            "&:hover": {
              backgroundColor: "rgba(11, 156, 49, 0.5)",
            },
          }}
          onClick={goToPrevious}
          disabled={currentIndex === 0}
        >
          <ArrowBack />
        </IconButton>
        <Box
          sx={{
            display: "flex",
            gap: "8px",
            alignItems: "center",
          }}
        >
          {images.map((_, index) => (
            <Box
              key={index}
              sx={{
                width: "10px",
                height: "10px",
                borderRadius: "50%",
                backgroundColor: currentIndex === index ? "black" : "grey",
              }}
            />
          ))}
        </Box>
        <IconButton
          sx={{
            backgroundColor: "rgba(11, 156, 49, 0.5)",
            color: "white",
            "&:hover": {
              backgroundColor: "rgba(11, 156, 49, 0.5)",
            },
          }}
          onClick={goToNext}
          disabled={currentIndex === images.length - 1}
        >
          <ArrowForward />
        </IconButton>
      </Box>
    </Box>
  );
};

export default IntroScreen;
