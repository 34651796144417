import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import React, { useContext, useEffect, useRef, useState } from "react";

import { APFormContext } from "codemod_components/forms/APForm";
import {
  APFormControl,
  APFormFieldItem,
} from "codemod_components/forms/useAPForm";
import { APRow } from "codemod_components/layout/APFlex";
import { APPalette } from "codemod_components/utils/APPalette";

const StyledTextField = styled(TextField)({
  [`& .MuiInputBase-multiline`]: {
    padding: "0 !important",
  },
});

export function APFormFieldText({
  id,
  type,
  style,
  disabled,
  size = "medium",
  keyboardType = "text",
  textAppearance = "none",
  label,
  placeholder,
  initialValue,
  value,
  readOnly = false,
  helperText,
  multiline,
  onChanged,
  validator,
  obscureText = false,
  control,
  prefix,
  autoFocus,
  suffix,
  minRows,
  maxRows,
  required,
  autoValidate,
  maxLength,
  error,
}: {
  style?: React.CSSProperties;
  size?: "small" | "medium";
  keyboardType?: "text" | "number" | "date" | "tel" | "month";
  label?: string;
  placeholder?: string;
  helperText?: React.ReactNode;
  initialValue?: string;
  readOnly?: boolean;
  multiline?: boolean;
  value?: string;
  onChanged?: (v: string) => any;
  validator?: (
    v: string,
  ) => React.ReactNode | null | Promise<React.ReactNode | null>;
  obscureText?: boolean;
  control?: APFormControl;
  textAppearance?: "none" | "capitalize" | "uppercase" | "lowercase";
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  id?: string;
  autoFocus?: boolean;
  /**
   * @deprecated use keyboardType instead
   */
  type?: "text" | "number" | "date" | "tel" | "month";
  disabled?: boolean;
  minRows?: string | number;
  maxRows?: string | number;
  required?: boolean;
  autoValidate?: boolean;
  maxLength?: number;
  error?: React.ReactNode;
}) {
  let inputRef = useRef<any>(null);

  // warnDeprecation({ disabled, type }, ['disabled', 'type'], "APFormFieldText")

  var control2: APFormControl | undefined = useContext(APFormContext);

  if (control !== undefined) {
    control2 = control;
  }

  const [_value, setValue] = useState(value ?? initialValue ?? "");

  const [_error, setError] = useState<React.ReactNode | null>(null);

  const [hidden, setHidden] = useState<boolean>(obscureText);

  async function checkValues(): Promise<boolean> {
    var errorMessage = null;
    if (validator) {
      errorMessage = await validator(_value);
    }
    setError(errorMessage);
    if (errorMessage) {
      inputRef.current?.scrollIntoView?.({
        behavior: "smooth",
        block: "center",
      });
    }
    return !errorMessage;
  }

  var fieldItem: APFormFieldItem = {
    validate: checkValues,
    reset: () => {
      setValue(initialValue || "");
    },
  };

  useEffect(() => {
    if (control2 !== undefined) {
      control2.fields.add(fieldItem);
    }
    return () => {
      if (control2 !== undefined) {
        control2.fields.delete(fieldItem);
      }
    };
  });

  useEffect(() => {
    (async () => {
      if (validator && (autoValidate || _error !== null)) {
        setError(await validator(_value));
      }
      if (onChanged) onChanged(_value);
    })();
  }, [_value]);

  useEffect(() => {
    setError(error);
  }, [error]);

  return (
    <StyledTextField
      id={id}
      autoFocus={autoFocus}
      style={style}
      ref={inputRef}
      type={hidden ? "password" : keyboardType}
      label={
        <APRow>
          <span style={{ color: APPalette["grey-500"] }}>{label}</span>
          {required && (
            <span
              style={{
                color: !!_error
                  ? APPalette["negative-400"]
                  : APPalette["grey-500"],
              }}
            >
              &nbsp;*
            </span>
          )}
        </APRow>
      }
      variant="outlined"
      size={size}
      InputLabelProps={keyboardType === "date" ? { shrink: true } : {}}
      color="primary"
      value={value ?? _value}
      helperText={_error || helperText}
      error={!!_error}
      disabled={disabled}
      minRows={minRows}
      maxRows={maxRows}
      margin="normal"
      onChange={(e: any) => {
        if (e.target.value.length > 0)
          switch (textAppearance) {
            case "uppercase":
              setValue(e.target.value.toUpperCase());
              break;
            case "lowercase":
              setValue(e.target.value.toLowerCase());
              break;
            case "capitalize":
              let words = e.target.value
                .split(" ")
                .map((f: any) => {
                  if (f.length > 2) {
                    return f[0].toUpperCase() + f.substring(1);
                  }
                  return f;
                })
                .join(" ");
              setValue(words);
              break;
            default:
              setValue(e.target.value);
              break;
          }
        else setValue(e.target.value);
      }}
      sx={{
        "& .MuiInputBase-input.Mui-disabled": {
          WebkitTextFillColor: APPalette["grey-500"],
          opacity: 0.6,
        },
      }}
      multiline={multiline && !obscureText}
      inputProps={{
        maxLength: maxLength,
        style: {
          textTransform: textAppearance,
          padding: multiline && !label ? "14px 16px" : undefined,
          backgroundColor: readOnly ? APPalette["grey-50"] : APPalette.white,
          opacity: readOnly ? 0.8 : undefined,
          borderRadius: "8px",
        },
      }}
      InputProps={{
        readOnly,
        placeholder,
        startAdornment: prefix ? (
          <InputAdornment position="start">{prefix}</InputAdornment>
        ) : null,
        endAdornment: obscureText ? (
          <InputAdornment position="end">
            <IconButton
              onClick={() => setHidden(!hidden)}
              style={{ marginRight: 0 }}
              size="small"
              tabIndex={-1}
            >
              {hidden ? (
                <Visibility></Visibility>
              ) : (
                <VisibilityOff></VisibilityOff>
              )}
            </IconButton>
          </InputAdornment>
        ) : suffix ? (
          <InputAdornment position="end">{suffix}</InputAdornment>
        ) : null,
      }}
    ></StyledTextField>
  );
}
