import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Document, Page } from "react-pdf";
import { IconButton } from "@mui/material";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import OnboardingApiServices from "../../../requests/OnboardingRequests";
import { getIt } from "codemod_components/dialogs/DialogHelper";
import { APFormFieldText } from "codemod_components/forms/APFormFieldText";
import { APButton } from "codemod_components/elements/APButton";
import { REGEX } from "codemod_components/utils/REGEX";
import { showLoadingDialog } from "codemod_components/dialogs/showLoadingDialog";
import { showErrorDialog } from "codemod_components/dialogs/showErrorDialog";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useDispatch, useSelector } from "react-redux";
import { updateOnBoardingSession } from "../../../store/slices/onboardingSlice";
import { APColumn } from "codemod_components/layout/APFlex";
import { APText } from "codemod_components/elements/APText";

const EsignForm = ({ goToNextStep, goToPrevStep }) => {
  const [pdfBlob, setPdfBlob] = useState<Blob | null>(null);
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [aadhaar, setAadhaar] = useState<string>("");
  const [captcha, setCaptcha] = useState<string>("");
  const [captchaImage, setCaptchaImage] = useState<string>("");
  const [otp, setOtp] = useState<string>("");
  const [showOtpField, setShowOtpField] = useState<boolean>(false);
  const [captchaFetchedSuccessfully, setCaptchaFetchedSuccessfully] =
    useState<boolean>(false);
  const [captchaTxnId, setCaptchaTxnId] = useState<string>("");
  const [disableNavigation, setDisableNavigation] = useState<boolean>(false);
  const [isCaptchaValid, setIsCaptchaValid] = useState<boolean>(true);

  const dispatch = useDispatch();
  const { megaFilledFormObject } = useSelector(
    (state: any) => state.onboardingSlice,
  );

  useEffect(() => {
    const fetchPdf = async () => {
      try {
        const { data } = await showLoadingDialog(
          async () =>
            await getIt(OnboardingApiServices).getPDFDetail(
              megaFilledFormObject.session_id,
              megaFilledFormObject.token,
            ),
        );

        const arrayBuffer = base64ToArrayBuffer(data);
        const blob = new Blob([arrayBuffer], { type: "application/pdf" });
        setPdfBlob(blob);
      } catch (error) {
        console.error("Error fetching PDF:", error);
      }
    };

    fetchPdf();
  }, [megaFilledFormObject.session_id, megaFilledFormObject.token]);

  const base64ToArrayBuffer = (base64: string) => {
    const binaryString = window.atob(base64);
    const length = binaryString.length;
    const bytes = new Uint8Array(length);

    for (let i = 0; i < length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    return bytes.buffer;
  };

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const goToNextPage = () => {
    if (!disableNavigation && pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
      resetInputs();
    }
  };

  const goToPrevPage = () => {
    if (!disableNavigation && pageNumber > 1) {
      setPageNumber(pageNumber - 1);
      resetInputs();
    }
  };

  const resetInputs = () => {
    setAadhaar("");
    setCaptcha("");
    setShowOtpField(false);
    setOtp("");
    setIsCaptchaValid(true);
  };

  const fetchCaptchaImage = useCallback(async () => {
    try {
      const { data } = await showLoadingDialog(
        async () =>
          await getIt(OnboardingApiServices).getCaptcha(
            megaFilledFormObject.session_id,
            megaFilledFormObject.token,
          ),
      );

      setCaptchaImage(`data:image/jpeg;base64,${data.captcha_b64}`);
      setCaptchaFetchedSuccessfully(true);
      setCaptchaTxnId(data.captcha_txn_id);
      setAadhaar("");
      setCaptcha("");
      setDisableNavigation(false);
    } catch (error) {
      console.error("Error fetching captcha image:", error);
      setCaptchaFetchedSuccessfully(false);
    }
  }, [megaFilledFormObject.session_id, megaFilledFormObject.token]);

  useEffect(() => {
    if (pageNumber === numPages) {
      fetchCaptchaImage();
    }
  }, [pageNumber, numPages, fetchCaptchaImage]);

  const handleSendOtp = async () => {
    setShowOtpField(true);

    try {
      const { data } = await getIt(OnboardingApiServices).sendOtpCaptcha(
        megaFilledFormObject.session_id,
        {
          captcha_value: captcha,
          captcha_txn_id: captchaTxnId,
          uid_number: aadhaar,
        },
        megaFilledFormObject.token,
      );

      if (data.error) {
        await showErrorDialog(data.error);
        setShowOtpField(false);
        setOtp("");
        setIsCaptchaValid(false);
      } else {
        setDisableNavigation(true);
        setIsCaptchaValid(true);

        dispatch(
          updateOnBoardingSession((prevState) => ({
            ...prevState,
            esign: {
              ...prevState.esign,
              aadhaar,
              captcha,
              captchaTxnId,
            },
          })),
        );
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      setIsCaptchaValid(false);
    }
  };

  const refreshCaptcha = async () => {
    try {
      await fetchCaptchaImage();
    } catch (error) {
      console.error("Error refreshing captcha:", error);
    }
  };

  const pdfFileUrl = useMemo(() => {
    if (pdfBlob) {
      return URL.createObjectURL(pdfBlob);
    }
    return null;
  }, [pdfBlob]);

  useEffect(() => {
    return () => {
      if (pdfFileUrl) {
        URL.revokeObjectURL(pdfFileUrl);
      }
    };
  }, [pdfFileUrl]);

  const captchaValidator = (value) => {
    if (value.length === 6) {
      setIsCaptchaValid(true);
    } else {
      setIsCaptchaValid(false);
    }
    setCaptcha(value);
  };

  const isSendOtpButtonEnabled = useMemo(() => {
    return (
      REGEX.AADHAAR.test(aadhaar) &&
      captcha.length === 6 &&
      captchaFetchedSuccessfully &&
      isCaptchaValid
    );
  }, [aadhaar, captcha, captchaFetchedSuccessfully, isCaptchaValid]);

  useEffect(() => {
    if (pageNumber === numPages) {
      setAadhaar("");
      setCaptcha("");
      setDisableNavigation(false);
    }
  }, [pageNumber, numPages]);

  const submitOTP = () => {
    console.log("submitted");
  };

  return (
    <APColumn>
      {pdfFileUrl && (
        <Document file={pdfFileUrl} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={pageNumber} width={window.innerWidth * 0.9} />
        </Document>
      )}

      <APColumn>
        <IconButton
          onClick={goToPrevPage}
          disabled={pageNumber === 1 || disableNavigation}
        >
          <KeyboardArrowLeft />
        </IconButton>
        <APText variant="normal">
          {pageNumber} of {numPages}
        </APText>
        <IconButton
          onClick={goToNextPage}
          disabled={pageNumber === numPages || disableNavigation}
        >
          <KeyboardArrowRight />
        </IconButton>
      </APColumn>

      {pageNumber === numPages && captchaFetchedSuccessfully && (
        <APColumn>
          <APFormFieldText
            label="Aadhaar Number"
            initialValue={aadhaar}
            onChanged={(value) => setAadhaar(value)}
            disabled={showOtpField}
            validator={(value) => {
              if (!value || !REGEX.AADHAAR.test(value)) {
                return "Please enter a valid Aadhaar number";
              }
              return null;
            }}
            style={{ width: "100%" }}
          />
          {captchaFetchedSuccessfully && (
            <APColumn>
              <img
                src={captchaImage}
                alt="Captcha"
                style={{ width: "7rem", marginRight: "0.5rem" }}
              />
              <APButton onClick={refreshCaptcha}>
                <RefreshIcon />
              </APButton>
              <APFormFieldText
                label="Enter Captcha"
                value={captcha}
                onChanged={captchaValidator}
                disabled={showOtpField}
                style={{ width: "10rem" }}
              />
            </APColumn>
          )}
          <APButton
            style={{ width: "6rem" }}
            variant="contained"
            color="primary"
            onClick={handleSendOtp}
            disabled={!isSendOtpButtonEnabled || showOtpField}
          >
            Send OTP
          </APButton>

          {showOtpField && (
            <APColumn>
              <APFormFieldText
                label="Enter OTP"
                value={otp}
                onChanged={(value) => setOtp(value)}
              />
              <APButton onClick={submitOTP} variant="contained" color="primary">
                Submit OTP
              </APButton>
            </APColumn>
          )}
        </APColumn>
      )}
    </APColumn>
  );
};

export default EsignForm;
