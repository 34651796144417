import React from "react";

export function APScrollView(props: {
  children: any;
  style?: React.CSSProperties;
}) {
  return (
    <div style={{ ...props.style, overflowY: "auto" }}>{props.children}</div>
  );
}
