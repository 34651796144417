import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "@reduxjs/toolkit/query";
import { useEffect, ReactNode } from "react";

interface ProtectedRouteProps {
  children: ReactNode;
}

const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useSelector((state: any) => state.authenticationSlice);

  useEffect(() => {
    if (!user) {
      const redirectTo = `/auth/login?continue=${encodeURIComponent(location.pathname + location.search)}`;
      navigate(redirectTo);
    }
  }, [user, navigate, location]);

  if (!user) {
    return null; // Render nothing or a loading spinner
  }

  return <>{children}</>;
};

export default ProtectedRoute;
