import React, { useEffect, useState } from "react";
import { APColumn, APRow } from "codemod_components/layout/APFlex";
import { APExpanded } from "codemod_components/layout/Common";
import { APFormSubmit } from "codemod_components/forms/APFormSubmit";
import { Button, IconButton, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import * as pdfjsLib from "pdfjs-dist";
import { APFormFieldText } from "codemod_components/forms/APFormFieldText";
import { APTag } from "codemod_components/elements/APTag";
import AttentionModal from "../Modal/AttentionModal";
import { GlobalWorkerOptions } from "pdfjs-dist";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import ContentPasteOffIcon from "@mui/icons-material/ContentPasteOff";
import FileUploader from "../Uploader/FileUploader";
import { getIt } from "codemod_components/dialogs/DialogHelper";
import FinacuityLoanApplicationApiServices from "requests/LoanapplyRequests";
import { useSelector } from "react-redux";
import { RootState } from "@reduxjs/toolkit/dist/query";
import { convertFileToBase64 } from "utils/string-utils";

GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url,
).toString();

const BankStatementForm = ({ goToNextStep, goToPrevStep }) => {
  const { application } = useSelector(
    (state: RootState<any, any, any>) => state.personalSlice,
  );
  const { profile } = useSelector(
    (state: RootState<any, any, any>) => state.authenticationSlice,
  );

  const application_id = application?.[0]?.application_id;
  const apiUrl = `bs-hook/${application_id}`;

  const [pdfs, setPdfs] = useState([
    {
      file: null,
      password: "",
      isProtected: false,
      base64: "",
      uploadStatus: "pending",
    },
  ]);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [countdown, setCountdown] = useState(10);
  const [isModalOpen, setIsModalOpen] = useState(true);

  useEffect(() => {
    if (errorMessage) {
      const timer = setTimeout(() => {
        setErrorMessage(undefined);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [errorMessage]);

  useEffect(() => {
    if (profile?.documents?.length > 0) {
      const bankStatements = profile.documents.filter(
        (doc) => doc.doc_type === "bank_statement",
      );
      if (bankStatements.length > 0) {
        setIsModalOpen(false);
        setPdfs(
          bankStatements.map((doc) => ({
            file: null,
            password: "",
            isProtected: false,
            base64: "",
            uploadStatus: "pending",
          })),
        );
      }
    }
  }, [profile?.documents]);

  const handleAddPDF = () => {
    setPdfs([
      ...pdfs,
      {
        file: null,
        password: "",
        isProtected: false,
        base64: "",
        uploadStatus: "pending",
      },
    ]);
  };

  const handleRemovePDF = (index) => {
    const newPdfs = pdfs.filter((_, i) => i !== index);
    setPdfs(newPdfs);
  };

  const checkIfProtected = async (file) => {
    if (!file) {
      return null;
    }

    try {
      const arrayBuffer = await file.arrayBuffer();
      const loadingTask = pdfjsLib.getDocument({
        data: arrayBuffer,
        password: "",
      });

      await loadingTask.promise;
      return false;
    } catch (error) {
      if (error.name === "PasswordException") {
        return true;
      }
      console.error("Error checking PDF protection:", error);
      return false;
    }
  };

  const handlePDFChange = async (index, file) => {
    if (!file) return;

    const newPdfs = [...pdfs];
    newPdfs[index].file = file;
    newPdfs[index].uploadStatus = "pending";

    try {
      const isProtected = await checkIfProtected(file);
      newPdfs[index].isProtected = isProtected;

      if (!isProtected) {
        const base64 = await convertFileToBase64(file);
        newPdfs[index].base64 = base64.split(",")[1];
      }

      setPdfs(newPdfs);
    } catch (error) {
      console.error("Error", error);
    }
  };

  const handlePasswordChange = (index, password) => {
    setPdfs((prevPdfs) => {
      const newPdfs = [...prevPdfs];
      newPdfs[index] = { ...newPdfs[index], password };
      return newPdfs;
    });
  };

  const validatePasswords = () => {
    return pdfs.every((pdf) => !pdf.isProtected || pdf.password);
  };

  const validatePasswordForPDF = async (file, password) => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const loadingTask = pdfjsLib.getDocument({
        data: arrayBuffer,
        password,
      });

      await loadingTask.promise;
      return true;
    } catch (error) {
      if (error.name === "PasswordException") {
        return false;
      }
      console.error("Error validating PDF password:", error);
      return false;
    }
  };

  const handleSubmit = async () => {
    if (!validatePasswords()) {
      setErrorMessage("Please provide a password for protected PDF.");
      return;
    }
    try {
      let uploadSuccess = false;
      await Promise.all(
        pdfs.map(async (pdf, index) => {
          if (!pdf.file) return;

          if (pdf.isProtected) {
            const isValidPassword = await validatePasswordForPDF(
              pdf.file,
              pdf.password,
            );
            if (!isValidPassword) {
              setErrorMessage("Incorrect password for a protected PDF.");
              return;
            }
            const base64 = await convertFileToBase64(pdf.file);
            pdf.base64 = base64.split(",")[1];
          }

          const payload = {
            password: pdf.isProtected ? pdf.password : null,
            doc_as_b64: pdf.base64,
            document_meta: {
              last_modified: pdf.file.lastModified,
              file_name: pdf.file.name,
              size: pdf.file.size,
              type: pdf.file.type,
            },
          };

          try {
            const res = await getIt(
              FinacuityLoanApplicationApiServices,
            ).getUploadStatement(apiUrl, payload);
            setPdfs((prevPdfs) => {
              const newPdfs = [...prevPdfs];
              newPdfs[index] = { ...newPdfs[index], uploadStatus: "success" };
              return newPdfs;
            });
            if (res.status_code === 0) {
              uploadSuccess = true;
            }
          } catch (error) {
            setErrorMessage("Failed to upload PDF. Please try again.");
            setPdfs((prevPdfs) => {
              const newPdfs = [...prevPdfs];
              newPdfs[index] = { ...newPdfs[index], uploadStatus: "failed" };
              return newPdfs;
            });
          }
        }),
      );
      if (uploadSuccess) {
        goToNextStep();
      }
    } catch (error) {
      setErrorMessage(error);
    }
  };

  const onDeleteFileHandler = (index) => {
    handleRemovePDF(index);
  };

  const handleButtonClick = () => {
    setIsModalOpen(false);
  };

  const modalContent = (
    <>
      <APColumn gap="1rem">
        <APRow>
          <AccountBalanceIcon sx={{ marginRight: 1 }} />
          <Typography>
            Upload only official bank-generated PDF statements.
          </Typography>
        </APRow>
        <APRow>
          <DocumentScannerIcon sx={{ marginRight: 1 }} />
          <Typography>Scanned images or photos are not accepted.</Typography>
        </APRow>
        <APRow>
          <ContentPasteOffIcon sx={{ marginRight: 1 }} />
          <Typography>
            Avoid altering the PDF statement via external tools.
          </Typography>
        </APRow>
      </APColumn>
      <Typography paragraph mt={6} textAlign={"center"}>
        This screen will skip automatically in {countdown} sec
      </Typography>
      <APRow style={{ padding: "0px 30px 16px 16px" }}>
        <APExpanded>
          <APFormSubmit size="large" onClick={async () => handleButtonClick()}>
            Acknowledge and skip
          </APFormSubmit>
        </APExpanded>
      </APRow>
    </>
  );

  return (
    <>
      {profile?.documents?.length > 0 && !isModalOpen ? (
        <APColumn gap="1rem">
          {profile.documents
            .filter((doc) => doc.doc_type === "bank_statement")
            .map((doc, index) => (
              <FileUploader
                key={index}
                file={{
                  name: doc.doc_name,
                  url: `https://your-s3-bucket-url/${doc.s3_key}`,
                }}
                disabled={true}
              />
            ))}
          <APRow style={{ padding: "0px 16px 16px 16px" }}>
            <APExpanded>
              <APFormSubmit size="large" onClick={goToNextStep}>
                Next
              </APFormSubmit>
            </APExpanded>
          </APRow>
        </APColumn>
      ) : (
        <>
          {isModalOpen && (
            <AttentionModal
              title="Attention"
              countdown={countdown}
              setCountdown={setCountdown}
              onClose={handleButtonClick}
            >
              {modalContent}
            </AttentionModal>
          )}
          {pdfs.map((pdf, index) => (
            <React.Fragment key={index}>
              <FileUploader
                accept="application/pdf"
                onSelectFile={(file) => handlePDFChange(index, file)}
                onDeleteFile={() => onDeleteFileHandler(index)}
                disabled={false}
              />
              {pdf.isProtected && (
                <APFormFieldText
                  label="Protected Password"
                  initialValue={pdf.password}
                  onChanged={(newPassword) =>
                    handlePasswordChange(index, newPassword)
                  }
                  style={{ width: "100%", marginTop: "8px" }}
                  obscureText
                />
              )}
              {index > 0 && (
                <Button color="warning" onClick={() => handleRemovePDF(index)}>
                  <IconButton>
                    <RemoveIcon color="warning" />
                  </IconButton>
                  <span>Remove Statement</span>
                </Button>
              )}
            </React.Fragment>
          ))}
          <Button color="success" onClick={handleAddPDF}>
            <IconButton>
              <AddIcon color="success" />
            </IconButton>
            <span>Add Statement</span>
          </Button>
          {errorMessage && <APTag variant="warning">{errorMessage}</APTag>}
          <APRow style={{ padding: "0px 16px 16px 16px" }}>
            <APExpanded>
              <APFormSubmit size="large" onClick={handleSubmit}>
                Next
              </APFormSubmit>
            </APExpanded>
          </APRow>
        </>
      )}
    </>
  );
};

export default BankStatementForm;
