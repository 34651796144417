import { APCard } from "codemod_components/elements/APCard";
import { APAlign, APStack } from "codemod_components/layout/APStack";
import { APCenter, APSizedBox } from "codemod_components/layout/Common";
import { APColumn } from "codemod_components/layout/APFlex";
import { APText } from "codemod_components/elements/APText";
import { APPalette } from "codemod_components/utils/APPalette";
import { Tag } from "codemod_components/elements/APMediaCard";
import { APButton } from "codemod_components/elements/APButton";
import React from "react";
import { formatCurrency } from "../../utils/string-utils";
import { APFormSubmit } from "../../codemod_components/forms/APFormSubmit";
import { Divider } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LamfLoanApprovedCommericals from "./Lamf/LamfLoanApprovedCommericals";

const ExistingLoanApplications = ({
  applications,
  onClick,
  isHovered,
  setIsHovered,
  completeJourneyButton,
}) => {
  return (
    <>
      {applications &&
        applications.map((i, index) => {
          return (
            <APCard
              key={index}
              style={{
                borderRadius: "8px",
                cursor: onClick ? "pointer" : "auto",
                transition: "all 0.3s",
                filter: isHovered
                  ? "drop-shadow(0px 8px 24px rgba(35, 39, 48, 0.15))"
                  : "none",
                margin: "1rem",
              }}
              onClick={onClick}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <APStack style={{ minHeight: "124px", width: "100%" }}>
                <APCenter>
                  <LamfLoanApprovedCommericals application={i} />
                </APCenter>

                {Number(i?.provisional?.loan_amount) > 0 ? (
                  <APFormSubmit
                    style={{ width: "100%" }}
                    onClick={() => completeJourneyButton(i.application_id)}
                  >
                    Apply Now
                  </APFormSubmit>
                ) : (
                  <APFormSubmit
                    style={{ width: "100%" }}
                    onClick={() => completeJourneyButton(i.application_id)}
                  >
                    Apply Now for Loan Against Shares
                  </APFormSubmit>
                )}
              </APStack>
            </APCard>
          );
        })}
    </>
  );
};

export default ExistingLoanApplications;
