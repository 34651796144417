import React from "react";
import { APPalette } from "codemod_components/utils/APPalette";

export interface APCardProps {
  /**
   * min width of the card
   */
  width?: string;
  /**
   * min height of the card
   */
  height?: string;
  /**
   * padding of the card
   * @default 16px
   */
  padding?: string;
  /**
   * overriding css of the card
   */
  style?: React.CSSProperties;
  /**
   * content of the Card
   */
  children: React.ReactNode;
  onClick?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => void | Promise<void>;
  onMouseEnter?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => void | Promise<void>;
  onMouseLeave?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => void | Promise<void>;
}

/**
 * @author `Abhishek Sinha`
 * @default
 * padding "16px"
 * borderRadius "16px"
 * border "1px solid APPalette['grey-200']"
 * background "APPalette.white"
 */
export function APCard(props: APCardProps) {
  var mStyle: React.CSSProperties = {
    borderRadius: "16px",
    border: `1px solid  ${APPalette["grey-200"]}`,
    display: "flex",
    alignItems: "self-start",
    flexDirection: "column",
    background: APPalette.white,
    padding: props.padding ?? "16px",
    height: props.height,
    width: props.width,
    ...props.style,
  };

  return (
    <div
      style={mStyle}
      onClick={props.onClick}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    >
      {props.children}
    </div>
  );
}
