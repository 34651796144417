import { APTag } from "codemod_components/elements/APTag";
import { APText } from "codemod_components/elements/APText";
import { APForm } from "codemod_components/forms/APForm";
import { APFormFieldText } from "codemod_components/forms/APFormFieldText";
import { APFormSubmit } from "codemod_components/forms/APFormSubmit";
import { useAPForm } from "codemod_components/forms/useAPForm";
import { APColumn, APRow } from "codemod_components/layout/APFlex";
import { APGrid } from "codemod_components/layout/APGrid";
import { APExpanded, APPadding } from "codemod_components/layout/Common";
import { REGEX } from "codemod_components/utils/REGEX";
import { Checkbox } from "@mui/material";
import { useState } from "react";

const CustomerOnboardingForm = () => {
  const customerFormControl = useAPForm();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [isCheckboxChecked, setIsCheckboxChecked] = useState<boolean>(false);
  const [formValues, setFormValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    dob: "",
  });

  const handleFieldChange = (field: string, value: string) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
  };

  const handlerSubmitInfo = async () => {
    console.log("sumitted");
  };

  return (
    <>
      <APForm control={customerFormControl} style={{ width: "100%" }}>
        <APGrid count={1} columnGap={"16px"}>
          <APPadding>
            <APColumn>
              <APFormFieldText
                style={{ width: "100%" }}
                label="First Name"
                initialValue={formValues.first_name || ""}
                onChanged={(value) => handleFieldChange("first_name", value)}
                validator={(value) => !value && "Please enter first name"}
              />
              <APFormFieldText
                style={{ width: "100%" }}
                label="Last Name"
                initialValue={formValues.last_name || ""}
                onChanged={(value) => handleFieldChange("last_name", value)}
                validator={(value) => !value && "Please enter last name"}
              />
              <APFormFieldText
                style={{ width: "100%" }}
                label="Enter Email Address"
                initialValue={formValues.email || ""}
                onChanged={(value) => handleFieldChange("email", value)}
                textAppearance="lowercase"
                validator={(value) => {
                  if (!value || !REGEX.EMAIL.test(value)) {
                    return "Please enter a valid Email Address";
                  }
                  return null;
                }}
              />
              <APFormFieldText
                style={{ width: "100%" }}
                label="Date of Birth"
                initialValue={formValues.dob || ""}
                onChanged={(value) => handleFieldChange("dob", value)}
                validator={(value) => {
                  if (!value || value.length < 10) {
                    return "Please enter a valid date";
                  }
                  return null;
                }}
                type="date"
                // keyboardType="date"
              />
            </APColumn>
          </APPadding>
        </APGrid>
      </APForm>
      <APRow>
        <Checkbox
          checked={isCheckboxChecked}
          onChange={() => setIsCheckboxChecked((prev) => !prev)}
        />
        <APText variant="paragraph-xsmall">Contact me on whatsapp</APText>
      </APRow>
      {errorMessage && <APTag variant="warning">{errorMessage}</APTag>}
      <APRow style={{ padding: "0px 16px 16px 16px" }}>
        <APExpanded>
          <APFormSubmit
            size="large"
            onClick={async () => {
              if (await customerFormControl.validate()) {
                try {
                  await handlerSubmitInfo();
                } catch (err: any) {
                  setErrorMessage(err.message);
                }
              }
            }}
          >
            Next
          </APFormSubmit>
        </APExpanded>
      </APRow>
    </>
  );
};

export default CustomerOnboardingForm;
