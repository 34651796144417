import { APColumn, APRow } from "../../codemod_components/layout/APFlex";
import { APPalette } from "../../codemod_components/utils/APPalette";
import { APInkWell } from "../../codemod_components/elements/APInkWell";
import { APIcon } from "../../codemod_components/icons/APIcon";
import { APExpanded } from "../../codemod_components/layout/Common";
import { APText } from "../../codemod_components/elements/APText";
import { MediaCardDefault } from "../../assembled-components/MediaCard";
// @ts-ignore
import hero from "../../images/banners/hero.png";
import { APScrollView } from "../../codemod_components/layout/APScrollView";
import React from "react";
import { useNavigate } from "react-router-dom";
import ApplyPersonalLoan from "./Personal/Personal";

const LoanApplicationWrapperContainer = ({
  productCode,
  headerTitle,
  children,
}) => {
  const navigate = useNavigate();
  return (
    <APColumn>
      <APColumn
        style={{ background: APPalette.white, paddingTop: "0.5rem" }}
        mainAxisSize="max"
        crossAxisAlignment="stretch"
      >
        <APColumn
          style={{ padding: "8px 16px" }}
          gap="8px"
          crossAxisAlignment="stretch"
        >
          <APRow
            mainAxisSize="max"
            crossAxisAlignment="start"
            mainAxisAlignment="center"
          >
            <APInkWell onClick={() => navigate("/")}>
              <APIcon icon="backButton" size={20} />
            </APInkWell>
            <APExpanded>
              <APColumn>
                <APText center variant="h3">
                  {headerTitle}
                </APText>
              </APColumn>
            </APExpanded>
          </APRow>
        </APColumn>
        <MediaCardDefault
          title={productCode}
          createdAt={new Date()}
          views={10}
          imgUrls={[hero]}
          videoId={null}
          duration={"20"}
          onClick={console.log}
        />
        <APColumn
          mainAxisSize="min"
          crossAxisAlignment="stretch"
          style={{
            backgroundColor: APPalette["grey-50"],
          }}
        >
          <APScrollView>{children}</APScrollView>
        </APColumn>
      </APColumn>
    </APColumn>
  );
};

export default LoanApplicationWrapperContainer;
