import axios from "axios";
import { checkSessionTokenExists } from "../Screens/AuthModule/auth-utils";

const authenticatedHttp = axios.create();

authenticatedHttp.interceptors.request.use(
  (config) => {
    const token = window.localStorage.getItem("sta");
    if (token) {
      config.headers["sta"] = token;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

authenticatedHttp.interceptors.response.use(
  (response) => {
    if (response.data && response.data.status_code === 419) {
      return Promise.reject({
        ...response,
        isTokenExpired: true,
      });
    }
    return response;
  },
  async (error) => {
    if (error.isTokenExpired) {
      const originalRequest = error.config;
      const refreshTokenKeyName = "str";
      const accessTokenKeyName = "sta";
      try {
        await checkSessionTokenExists(
          accessTokenKeyName,
          refreshTokenKeyName,
          "authModule",
          (data) => {
            window.localStorage.setItem(accessTokenKeyName, data?.token);
          },
        );
        const newToken = window.localStorage.getItem(accessTokenKeyName);
        if (newToken) {
          originalRequest.headers["sta"] = newToken;
          return authenticatedHttp(originalRequest);
        }
      } catch (e) {
        console.error("Failed to refresh token:", e);
      }
    }
    return Promise.reject(error);
  },
);
export default authenticatedHttp;
