import "reflect-metadata";
import React from "react";
import { ThemeProvider } from "@emotion/react";
import { StyledEngineProvider, CssBaseline } from "@mui/material";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { createTheme } from "@mui/material/styles";
import LoginScreen from "./Screens/AuthModule/LoginScreen";
import { ReduxStoreProvider } from "./store/globalStore";
import "./index.css";

import { DialogHelper } from "codemod_components/dialogs/DialogHelper";
import { styles } from "codemod_components/utils/styles";
import OnboardingRouter from "./Screens/OnboardingModule/OnboardingRouter";
import LoansApplyRouter from "./Screens/LoansApplyModule/LoanApplyRouter";
import AuthRouter from "./Screens/AuthModule/AuthRouter";
import FreeCreditReportModule from "./Screens/FreeCreditReportModule/FreeCreditReportModule";
import HomeScreen from "./Screens/HomeScreenModule/HomeScreen";
import ProtectedRoute from "./global-hooks/ProtectedRoute";
import PayRouter from "./Screens/PayScreenModule/PayRouter";
import MobileRechargeForm from "./Screens/Recharge/index";
import RechargeAuthLoginScreen from "./Screens/AuthModule/RechargeAuthLoginScreen";

const theme = createTheme({
  ...styles,
});

const NotFoundScreen = () => <>Not Found</>;

const RouterWrapper = () => {
  return (
    <Routes>
    <Route path="/recharge/login" element={< RechargeAuthLoginScreen/>} />
     <Route path="/recharge/*" element={<MobileRechargeForm />} />
      {/*onboarding here refers to kavach services*/}
      <Route path="/onboarding/*" element={<OnboardingRouter />} />
      <Route path="/pay/*" element={<PayRouter />} />
      <Route path="/auth/*" element={<AuthRouter />} />
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <HomeScreen />
          </ProtectedRoute>
        }
      />
      <Route path="/apply/*" element={<LoansApplyRouter />} />
      <Route path="/credit-report/*" element={<FreeCreditReportModule />} />
      <Route path="/404" element={<NotFoundScreen />} />
    </Routes>
  );
};

function App() {
  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <ReduxStoreProvider>
          <CssBaseline />
          <div className="outer-div">
            <div className="inner-div">
              <div className="innermost-div">
                <Router>
                  <RouterWrapper />
                </Router>
              </div>
            </div>
          </div>
          <DialogHelper />
        </ReduxStoreProvider>
      </StyledEngineProvider>
    </ThemeProvider>
  );
}

export default App;
