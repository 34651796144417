import { Routes, Route } from "react-router-dom";
import Success from "Screens/OnboardingModule/Success/Success";
import Onboarding from "./Onboarding";

const OnboardingRouter = () => {
  return (
    <>
      <Routes>
        <Route path="/:session_id" element={<Onboarding />} />
        <Route path="/:session_id/success" element={<Success />} />
      </Routes>
    </>
  );
};

export default OnboardingRouter;
