import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const onboardingSlice = createSlice({
  name: "onboarding",
  initialState: {
    session_meta: null,
    session_id: null,
    token: null,
    session_status: null,
    poi: {
      base64Image: null,
      ocr_extract: null,
      doc_identifier: null,
    },
    poa: {
      base64ImageFront: "",
      base64ImageBack: "",
      ocr_extract: null,
      aadhaar_back_masked_doc_identifier: null,
      aadhaar_front_masked_doc_identifier: null,
    },
    bank: {
      base64Image: "",
      ocr_extract: null,
      doc_identifier: null,
    },
    photo: {
      base64Image: "",
      doc_identifier: null,
      ocr_extract: null,
    },
    video: {
      doc_identifier: null,
      ocr_extract: null,
      base64Image: "",
    },
    signature: {
      doc_identifier: null,
      ocr_extract: null,
      base64Image: "",
    },
    gst: {
      doc_identifier: null,
      ocr_extract: null,
      base64Image: "",
    },
    coi: {
      doc_identifier: null,
      ocr_extract: null,
      base64Image: "",
    },
    moa: {
      doc_identifier: null,
      ocr_extract: null,
      base64Image: "",
    },
    aoa: {
      doc_identifier: null,
      ocr_extract: null,
      base64Image: "",
    },
    nominee: {},
    esign: {
      doc_identifier: null,
      base64Image: "",
      otpRequested: null,
    },
  },
  reducers: {
    updateOnBoardingSession: (state, action) => {
      state = {
        ...state,
        ...action.payload,
      };
      return state;
    },
    updatePoiForm: (state, action) => {
      state.poi = {
        ...state.poi,
        ...action.payload,
      };
      return state;
    },
    updatePoaForm: (state, action) => {
      state.poa = {
        ...state.poa,
        ...action.payload,
      };
      return state;
    },
    updateBankForm: (state, action) => {
      state.bank = {
        ...state.bank,
        ...action.payload,
      };
      return state;
    },
    setSessionMeta: (state, action: PayloadAction<object>) => {
      // @ts-ignore
      state.session_id = action.payload?.session_id;
      // @ts-ignore
      state.session_meta = action.payload?.session_meta;
      return state;
    },
    updateVideoForm: (state, action) => {
      state.video = {
        ...state.video,
        ...action.payload,
      };
      return state;
    },
    updateNomineeForm: (state, action) => {
      state.nominee = {
        ...state.nominee,
        ...action.payload,
      };
      return state;
    },
    updateCoiForm: (state, action) => {
      state.coi = {
        ...state.coi,
        ...action.payload,
      };
      return state;
    },
    updateGstForm: (state, action) => {
      state.gst = {
        ...state.gst,
        ...action.payload,
      };
      return state;
    },
    updatePhotoForm: (state, action) => {
      state.photo = {
        ...state.photo,
        ...action.payload,
      };
      return state;
    },
  },
});

export const {
  updateOnBoardingSession,
  updatePoiForm,
  updatePoaForm,
  updateBankForm,
  updateVideoForm,
  updateNomineeForm,
  updateCoiForm,
  updateGstForm,
  updatePhotoForm,
  setSessionMeta,
} = onboardingSlice.actions;

export default onboardingSlice.reducer;
