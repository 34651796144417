import { APColumn, APRow } from "codemod_components/layout/APFlex";
import { APPalette } from "codemod_components/utils/APPalette";
import React from "react";
import { APExpanded, APPadding } from "codemod_components/layout/Common";
import { APText } from "codemod_components/elements/APText";
import { APImage } from "codemod_components/elements/APImage";

function VideoDuration({ time, type }: { time?: string; type?: string }) {
  return (
    <APPadding
      padding={"4px"}
      style={{ backgroundColor: "white", borderRadius: "4px" }}
    >
      <APRow mainAxisSize="min">
        <APImage src={"/icons/icon-youtube.svg"} />
        {time && (
          <APText
            variant={`${type == "default" ? "paragraph-xsmall" : "paragraph-small"}`}
            color={APPalette["grey-500"]}
          >
            {time}
          </APText>
        )}
      </APRow>
    </APPadding>
  );
}

export const MediaCardDefault = ({
  title,
  createdAt,
  views,
  imgUrls,
  videoId,
  duration,
  author,
  onClick,
}: {
  title: string;
  createdAt: Date;
  views: number;
  imgUrls?: string[];
  videoId?: String;
  author?: string;
  duration?: string;
  onClick?: () => void | Promise<void>;
}) => {
  return (
    <APRow
      gap="12px"
      style={{ height: "12rem", cursor: onClick ? "pointer" : "auto" }}
      onClick={onClick}
    >
      <APExpanded>
        <APColumn
          crossAxisAlignment="stretch"
          mainAxisAlignment="spaceBetween"
          mainAxisSize="max"
          style={{ padding: "4px 0" }}
        >
          <APRow gap={"5px"}>
            {imgUrls &&
              imgUrls.map((i) => (
                <APImage
                  imageFit={"cover"}
                  width={"100%"}
                  height={"100%"}
                  style={{ backgroundColor: APPalette["brand-100"] }}
                  src={i ?? ""}
                  allowCors={true}
                />
              ))}
          </APRow>
        </APColumn>
      </APExpanded>
    </APRow>
  );
};

// <APStack style={{ height: '4rem', minWidth: 'auto' }}>
//     <APAlign align='fill'>
//         <APImage
//             fit
//             width={'100%'}
//             height={'100%'}
//             style={{ backgroundColor: APPalette['brand-100'], }}
//             src={imgUrl ?? ''}
//             allowCors={true}
//         />
//     </APAlign>
// </APStack>
