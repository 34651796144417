import React, { useEffect, useMemo, useState } from "react";
import { APJourneyApp } from "codemod_components/stepper_app/APJourneyApp";
import PersonalInformation from "./form/PersonalInformation";
import EmploymentDetails from "./form/EmploymentDetails";
import BankStatementForm from "../Business/forms/bankStatementForm";
import { useDispatch, useSelector } from "react-redux";
import { showLoadingDialog } from "codemod_components/dialogs/showLoadingDialog";
import { getIt } from "codemod_components/dialogs/DialogHelper";
import FinacuityProfileApiServices from "requests/UserRequests";
import { dispatchUserProfile } from "store/slices/authenticationSlice";
import { RootState } from "@reduxjs/toolkit/dist/query";
import FinacuityLoanApplicationApiServices from "requests/LoanapplyRequests";
import { dispatchUpdatePersonalLoanApplication } from "store/slices/personalSlice";
import LoanApplicationWrapperContainer from "../LoanApplicationWrapperContainer";

const ApplyPersonalLoan = () => {
  const dispatch = useDispatch();
  // @ts-ignore
  const { user, applications, profile } = useSelector(
    (state: RootState<any, any, any>) => state.authenticationSlice,
  );

  const fetchProfileData = async () => {
    const { data, status_code } = await showLoadingDialog(
      async () => await getIt(FinacuityProfileApiServices).getMeProfileData(),
    );
    if (status_code === 0) {
      dispatch(dispatchUserProfile(data?.profile));
    }
  };

  const fetchCustomerLoanApplications = async () => {
    const { data, status_code } = await showLoadingDialog(
      async () =>
        await getIt(FinacuityLoanApplicationApiServices).getLoanApplications(),
    );
    if (status_code === 0) {
      dispatch(dispatchUpdatePersonalLoanApplication(data.applications));
    }
  };

  useEffect(() => {
    if (!profile || Object.keys(profile).length === 0) fetchProfileData();
    fetchCustomerLoanApplications();
  }, []);

  const getSteps = useMemo(() => {
    return [
      {
        title: "Employment Details",
        description: "Enter your employment details.",
        form: EmploymentDetails,
      },

      {
        title: "Personal Information",
        description: "Enter your personal details.",
        form: PersonalInformation,
      },

      {
        title: "Bank Statement",
        description: "Upload your bank statements.",
        form: BankStatementForm,
      },
    ];
  }, []);

  return (
    <LoanApplicationWrapperContainer
      headerTitle={"Personal Loan"}
      productCode={"loan:personal"}
    >
      <APJourneyApp
        style={{ width: "100%", height: "100%" }}
        mode="linear"
        title=""
        description=""
        getSteps={getSteps}
        handleCompletion={console.log}
        canChangeSteps={true}
      />
    </LoanApplicationWrapperContainer>
  );
};

export default ApplyPersonalLoan;
