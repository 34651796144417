import React, { useEffect, useState } from "react";
import { APGrid } from "codemod_components/layout/APGrid";
import { APColumn, APRow } from "codemod_components/layout/APFlex";
import { APFormFieldText } from "codemod_components/forms/APFormFieldText";
import { APFormFieldDropdown } from "codemod_components/forms/APFormFieldDropdown";
import { Box, Button, Divider, IconButton, Stack } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { RelationshipTable, SalutationTable } from "../IntroScreen";
import { APForm } from "codemod_components/forms/APForm";
import { useAPForm } from "codemod_components/forms/useAPForm";
import { APExpanded } from "codemod_components/layout/Common";
import { APFormSubmit } from "codemod_components/forms/APFormSubmit";
import MutualFundApiServices from "requests/OnboardingRequests";
import { useDispatch, useSelector } from "react-redux";
import { getIt } from "codemod_components/dialogs/DialogHelper";
import { APTag } from "codemod_components/elements/APTag";
import { updateNomineeForm } from "store/slices/onboardingSlice";
import { showLoadingDialog } from "codemod_components/dialogs/showLoadingDialog";
import { useForceRender } from "codemod_components/hooks/useForceUpdate";
import { useLocation, useNavigate } from "react-router-dom";

const NomineeForm = ({ goToNextStep, goToPrevStep, isLastStep }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { session_id, token, session_meta } = useSelector(
    (state: any) => state.onboardingSlice,
  );
  const { key, reload } = useForceRender();
  const nomineeFormControl = useAPForm();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [nominees, setNominees] = useState([]);

  const preFillInfo = async () => {
    await showLoadingDialog(async () => {
      try {
        const { data, status_code } = await getIt(
          MutualFundApiServices,
        ).getSessionDetail(session_id, token);

        const nomineePrefilledObject = data?.session?.nominee;
        if (nomineePrefilledObject) {
          dispatch(updateNomineeForm(nomineePrefilledObject));
          const nomineesData = nomineePrefilledObject.data_as_array.map(
            (nom) => ({
              ...nom,
              guardians: [...nom.guardians],
            }),
          );
          setNominees(nomineesData);
        }
        reload();
      } catch (error) {
        setErrorMessage(error.message);
      }
    });
  };

  useEffect(() => {
    preFillInfo();
  }, []);

  const calculateAge = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };

  const handleNomineeChange = (index, field, value) => {
    const updatedNominees = [...nominees];

    if (field === "guardian") {
      updatedNominees[index].guardians[0] = { ...value };
    } else {
      updatedNominees[index] = { ...updatedNominees[index], [field]: value };

      if (field === "date_of_birth") {
        try {
          const dob = new Date(value);
          if (isNaN(dob.getTime())) {
            throw new Error("Invalid date");
          }
          const formattedDate = dob.toISOString().slice(0, 10);
          updatedNominees[index].date_of_birth = formattedDate;

          const age = calculateAge(value);
          if (age < 18) {
            updatedNominees[index].guardians = [
              {
                salutation: "",
                first_name: "",
                last_name: "",
              },
            ];
          } else {
            updatedNominees[index].guardians = [];
          }
        } catch (error) {
          console.error("Error parsing date:", error);
        }
      } else if (field === "percentage") {
        const totalPercentage = updatedNominees.reduce(
          (sum, nominee, idx) =>
            idx === index ? sum : sum + nominee.percentage,
          0,
        );
        const newPercentage = parseFloat(value);
        if (totalPercentage + newPercentage > 100) {
          setErrorMessage("Total percentage cannot exceed 100%");
          return;
        } else {
          updatedNominees[index].percentage = newPercentage;
        }
      }
    }
    setNominees(updatedNominees);
    setErrorMessage(undefined);
  };

  const addNominee = () => {
    const remainingPercentage =
      100 - nominees.reduce((sum, nominee) => sum + nominee.percentage, 0);
    if (remainingPercentage <= 0) {
      setErrorMessage(
        "Cannot add more nominees, total percentage already 100%",
      );
      return;
    }
    setNominees([
      ...nominees,
      {
        salutation: "",
        first_name: "",
        last_name: "",
        relation: "",
        date_of_birth: "",
        percentage: remainingPercentage,
        guardians: [],
      },
    ]);
    setErrorMessage(undefined);
  };

  const removeNominee = (indexToRemove) => {
    const updatedNominees = nominees.filter(
      (_, index) => index !== indexToRemove,
    );
    setNominees(updatedNominees);
  };

  const handlerSubmitInfo = async () => {
    const nomineeData = nominees.map((nominee) => ({
      ...nominee,
      guardians: nominee.guardians.map((guardian) => ({
        ...guardian,
      })),
    }));

    try {
      const ocrResponseStatus = await getIt(
        MutualFundApiServices,
      ).updateSessionInfo(
        session_id,
        {
          nominee: {
            data_as_array: nomineeData,
          },
        },
        token,
      );

      dispatch(updateNomineeForm({ data_as_array: nomineeData }));
      if (isLastStep) {
        navigate(`/onboarding/${session_id}/success`);
      } else {
        goToNextStep();
      }
    } catch (error) {
      console.error("Error updating session info:", error);
      setErrorMessage(error.message);
    }
  };

  return (
    <>
      {nominees.map((nominee, index) => (
        <APForm control={nomineeFormControl} style={{ width: "100%" }}>
          <APGrid count={1} columnGap={"16px"} key={key}>
            <APColumn
              gap={"12px"}
              mainAxisSize="min"
              crossAxisAlignment="stretch"
            >
              <APRow gap={"12px"}>
                <APFormFieldDropdown
                  style={{ width: "100%" }}
                  label="Salutation"
                  items={SalutationTable}
                  initialValue={nominee.salutation}
                  onChanged={(value) =>
                    handleNomineeChange(index, "salutation", value)
                  }
                  validator={(value) => !value && "Please select a salutation"}
                />
                <APFormFieldText
                  style={{ width: "100%" }}
                  label="Percentage"
                  initialValue={nominee.percentage.toString()}
                  onChanged={(value) =>
                    handleNomineeChange(index, "percentage", value)
                  }
                  validator={(value) => {
                    const numValue = parseFloat(value);
                    return numValue <= 0 || isNaN(numValue) || numValue > 100
                      ? "Please enter valid percentage"
                      : null;
                  }}
                  keyboardType="number"
                />
              </APRow>
              <APRow gap={"12px"}>
                <APFormFieldText
                  style={{ width: "100%" }}
                  label="First Name"
                  initialValue={nominee.first_name}
                  onChanged={(value) =>
                    handleNomineeChange(index, "first_name", value)
                  }
                  validator={(value) => !value && "Please enter first name"}
                />
                <APFormFieldText
                  style={{ width: "100%" }}
                  label="Last Name"
                  initialValue={nominee.last_name}
                  onChanged={(value) =>
                    handleNomineeChange(index, "last_name", value)
                  }
                  validator={(value) => !value && "Please enter last name"}
                />
              </APRow>
              <APRow gap={"12px"}>
                <APFormFieldDropdown
                  style={{ width: "100%" }}
                  label="Relationship"
                  items={RelationshipTable}
                  initialValue={nominee.relation}
                  onChanged={(value) =>
                    handleNomineeChange(index, "relation", value)
                  }
                  validator={(value) => !value && "Please select relationship"}
                />
                <APFormFieldText
                  style={{ width: "100%" }}
                  label="Date of Birth"
                  initialValue={nominee.date_of_birth}
                  onChanged={(value) =>
                    handleNomineeChange(index, "date_of_birth", value)
                  }
                  validator={(value) => !value && "Please enter date of birth"}
                  keyboardType="date"
                />
              </APRow>
              <Divider />
              {nominee.date_of_birth &&
                calculateAge(nominee.date_of_birth) < 18 &&
                nominee.guardians.map((guardian, guardianIndex) => (
                  <Box key={guardianIndex}>
                    <APFormFieldDropdown
                      style={{ width: "35%" }}
                      label="Salutation"
                      items={SalutationTable}
                      initialValue={guardian.salutation}
                      onChanged={(value) =>
                        handleNomineeChange(index, "guardian", {
                          ...guardian,
                          salutation: value,
                        })
                      }
                      validator={(value) =>
                        !value && "Please select guardian's salutation"
                      }
                    />
                    <APRow gap={"12px"}>
                      <APFormFieldText
                        style={{ width: "100%" }}
                        label="Guardian First Name"
                        initialValue={guardian.first_name}
                        onChanged={(value) =>
                          handleNomineeChange(index, "guardian", {
                            ...guardian,
                            first_name: value,
                          })
                        }
                        validator={(value) =>
                          !value && "Please enter guardian's first name"
                        }
                      />
                      <APFormFieldText
                        style={{ width: "100%" }}
                        label="Guardian Last Name"
                        initialValue={guardian.last_name}
                        onChanged={(value) =>
                          handleNomineeChange(index, "guardian", {
                            ...guardian,
                            last_name: value,
                          })
                        }
                        validator={(value) =>
                          !value && "Please enter guardian's last name"
                        }
                      />
                    </APRow>
                  </Box>
                ))}
            </APColumn>
          </APGrid>
        </APForm>
      ))}
      <Stack direction={"row"} alignContent={"space-around"}>
        <Button color="success" onClick={addNominee}>
          <IconButton>
            <AddIcon color="success" />
          </IconButton>
          <span>Add Nominee</span>
        </Button>
        {nominees.length > 1 && (
          <Button
            color="warning"
            onClick={() => removeNominee(nominees.length - 1)}
          >
            <IconButton>
              <RemoveIcon color="warning" />
            </IconButton>
            <span>Remove Nominee</span>
          </Button>
        )}
      </Stack>
      {errorMessage && <APTag variant="warning">{errorMessage}</APTag>}
      <APRow style={{ padding: "0px 16px 16px 16px" }}>
        <APExpanded>
          <APFormSubmit
            size="large"
            onClick={async () => {
              if (await nomineeFormControl.validate()) {
                try {
                  await handlerSubmitInfo();
                } catch (err: any) {
                  setErrorMessage(err.message);
                }
              }
            }}
          >
            Next
          </APFormSubmit>
        </APExpanded>
      </APRow>
    </>
  );
};

export default NomineeForm;
