import { APFormFieldImage } from "codemod_components/forms/APFormFieldImage";
import { showLoadingDialog } from "codemod_components/dialogs/showLoadingDialog";
import { getIt } from "codemod_components/dialogs/DialogHelper";
import OnboardingApiServices from "../../../requests/OnboardingRequests";
import { showErrorDialog } from "codemod_components/dialogs/showErrorDialog";
import { APGrid } from "codemod_components/layout/APGrid";
import React, { useEffect, useState } from "react";
import { APFormFieldText } from "codemod_components/forms/APFormFieldText";
import { REGEX } from "codemod_components/utils/REGEX";
import { APFormFieldDropdown } from "codemod_components/forms/APFormFieldDropdown";
import { useForceRender } from "codemod_components/hooks/useForceUpdate";
import { useDispatch, useSelector } from "react-redux";
import { updateBankForm } from "../../../store/slices/onboardingSlice";
import { useAPForm } from "codemod_components/forms/useAPForm";
import axios from "axios";
import { convertBytesToBase64Image } from "../../../utils/string-utils";
import { APForm } from "codemod_components/forms/APForm";
import { APRow } from "codemod_components/layout/APFlex";
import { APExpanded } from "codemod_components/layout/Common";
import { APFormSubmit } from "codemod_components/forms/APFormSubmit";
import { APTag } from "codemod_components/elements/APTag";
import { useLocation, useNavigate } from "react-router-dom";

const RenderOcrInfoStatus = ({ kycForm, onChange, disabled }) => {
  return (
    <>
      <APFormFieldText
        label="Account Number"
        initialValue={kycForm.account_number}
        onChanged={(value) => onChange("account_number", value)}
        validator={(value) => {
          return null;
        }}
        disabled={disabled}
      />

      <APFormFieldText
        label="IFSC Code"
        initialValue={kycForm.ifsc}
        onChanged={(value) => onChange("ifsc", value)}
        validator={(value) => {
          if (!value || REGEX.IFSC.test(value) == false) {
            return "Please enter a valid IFSC Code";
          }
          return null;
        }}
        disabled={disabled}
      />
      <APFormFieldDropdown<"SAVINGS" | "CURRENT">
        label="Account Type"
        items={[
          {
            value: "SAVINGS",
            label: "Savings",
          },
          {
            value: "CURRENT",
            label: "Current",
          },
        ]}
        initialValue={
          kycForm.account_type && kycForm.account_type.toUpperCase()
        }
        onChanged={(value) => onChange("account_type", value)}
        validator={(value) => {
          if (!value) {
            return "Please select Account Type";
          }
          return null;
        }}
        disabled={disabled}
      />
    </>
  );
};

const BankForm = ({ goToNextStep, goToPrevStep, isLastStep }) => {
  const navigate = useNavigate();
  const bankFormControl = useAPForm();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const { key, reload } = useForceRender();
  const { bank, session_id, token, session_meta } = useSelector(
    (state: any) => state.onboardingSlice,
  );

  const preFillInfo = async () => {
    await showLoadingDialog(async () => {
      let { data, status_code } = await getIt(
        OnboardingApiServices,
      ).getSessionDetail(session_id, token);
      const bankPrefilledObject = data?.session?.bank;
      if (bankPrefilledObject?.doc_identifier) {
        let { data, status_code } = await showLoadingDialog(
          async () =>
            await getIt(OnboardingApiServices).downloadDocPreSignedUrl(
              session_id,
              "cheque",
              token,
            ),
        );
        if (status_code === 0 && Array.isArray(data) && data.length > 0) {
          const response = await axios.get(data[0].url, {
            responseType: "arraybuffer",
          });
          if (response.status === 200) {
            dispatch(
              updateBankForm({
                ...bankPrefilledObject,
                base64Image: convertBytesToBase64Image(response.data),
              }),
            );
            reload();
          }
        }
      }
    });
  };

  useEffect(() => {
    preFillInfo();
  }, []);

  const handleImageUpload = async (value) => {
    if (value && value !== bank?.base64Image) {
      try {
        await showLoadingDialog(async () => {
          const resp = await getIt(OnboardingApiServices).generatePreSignedUrl(
            session_id,
            "cheque",
            token,
          );
          await getIt(OnboardingApiServices).uploadDocumentToPreSignedUrl(
            resp.data.url,
            value,
          );
          const ocrResponseStatus = await getIt(
            OnboardingApiServices,
          ).performExecuteActionOnDocument(session_id, "cheque", token);
          dispatch(
            updateBankForm({
              base64Image: value,
              doc_identifier: resp.data.doc_identifier,
              ocr_extract: ocrResponseStatus?.data,
            }),
          );
          reload();
        });
      } catch (err) {
        await showErrorDialog(err.message);
      }
    }
  };

  const handlerSubmitInfo = async () => {
    await showLoadingDialog(async () => {
      await getIt(OnboardingApiServices).updateSessionInfo(
        session_id,
        {
          bank: {
            ocr_extract: { ...bank.ocr_extract },
            doc_identifier: bank.doc_identifier,
          },
        },
        token,
      );
    });
    if (isLastStep) {
      navigate(`/onboarding/${session_id}/success`);
    } else {
      goToNextStep();
    }
  };

  return (
    <>
      <APForm control={bankFormControl} style={{ width: "100%" }}>
        <APGrid count={1} columnGap={"16px"} key={key}>
          <APFormFieldImage
            label="Signed Cancelled Cheque"
            src={bank?.base64Image}
            onChange={handleImageUpload}
            validator={(file) => {
              if (!file) {
                return "Please upload a valid image";
              }
              return null;
            }}
            disabled={bank?.status === "completed"}
          />

          <RenderOcrInfoStatus
            kycForm={bank?.ocr_extract || {}}
            onChange={(label: any, value: any) =>
              dispatch(
                updateBankForm({
                  ocr_extract: { ...bank.ocr_extract, ...{ [label]: value } },
                }),
              )
            }
            disabled={bank?.status === "completed"}
          />
        </APGrid>
      </APForm>
      {errorMessage && <APTag variant="warning">{errorMessage}</APTag>}
      <APRow style={{ padding: "0px 16px 16px 16px" }}>
        <APExpanded>
          <APFormSubmit
            size="large"
            onClick={async () => {
              if (await bankFormControl.validate()) {
                try {
                  await handlerSubmitInfo();
                } catch (err: any) {
                  setErrorMessage(err.message);
                }
              }
            }}
          >
            Next
          </APFormSubmit>
        </APExpanded>
      </APRow>
    </>
  );
};
export default BankForm;
