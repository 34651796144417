import { DeprecatedThemeOptions } from "@mui/material/styles";
import React from "react";
import { APPalette } from "codemod_components/utils/APPalette";

declare module "@mui/material/styles/overrides" {
  export interface ComponentNameToClassKey {
    // @ts-ignore
    MuiAlert: any;
  }
}

const ButtonStyleOverrides: { [key: string]: React.CSSProperties } = {
  small: {
    padding: "8px",
    height: `32px`,
    fontSize: "12px",
    lineHeight: `16px`,
    boxShadow: "none",
  },
  medium: {
    padding: "12px 8px",
    height: `40px`,
    fontSize: "12px",
    lineHeight: `16px`,
    boxShadow: "none",
  },
  large: {
    padding: "12px 8px",
    height: `48px`,
    fontSize: `16px`,
    lineHeight: `24px`,
    boxShadow: "none",
  },
};

export const styles: DeprecatedThemeOptions = {
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  typography: {
    fontFamily: "Roboto",
    fontSize: 14,
    body1: {
      fontSize: "14px",
    },
  },
  palette: {
    primary: {
      main: APPalette["brand-300"],
      contrastText: "#FFF",
    },
    secondary: {
      main: APPalette["white"],
      contrastText: APPalette["brand-300"],
    },
    error: {
      main: APPalette["negative-300"],
    },
    background: {
      default: "#f2f1f6",
    },
  },
  shape: {
    borderRadius: 10,
  },
  overrides: {
    MuiDivider: {
      root: {
        borderColor: APPalette["grey-200"],
      },
    },
    MuiBreadcrumbs: {
      separator: {
        color: APPalette["grey-600"],
      },
    },
    MuiAutocomplete: {
      inputRoot: {
        '&[class*="MuiOutlinedInput-root"]': {
          padding: "22px 16px 6px",
          width: "calc(100% - 4px)",
          "& .MuiAutocomplete-input": {
            padding: 0,
          },
          "& .MuiAutocomplete-input:first-child": {
            paddingLeft: 0,
          },
        },
      },
    },
    MuiLink: {
      underlineHover: {
        "&:hover": {
          // textDecoration: "none",
          color: APPalette["grey-500"],
        },
      },
    },
    MuiTypography: {
      body1: {
        fontSize: "14px",
        lineHeight: "20px",
      },
    },
    MuiDialog: {
      container: {
        backgroundColor: "rgba(0,0,0,0.25)",
        // backdropFilter: "blur(2px)"// For blur effect in the backdrop
      },
    },
    MuiSlider: {
      root: {
        height: "8px",
      },
      rail: {
        height: "8px",
        borderRadius: "4px",
        opacity: 1,
        backgroundColor: APPalette["grey-300"],
      },
      track: {
        height: "8px",
        borderRadius: "4px 0 0 4px",
      },
      thumb: {
        width: "24px",
        height: "24px",
        border: `2px solid ${APPalette.white}`,
        "&:hover": {
          boxShadow: "none",
        },
        "&.MuiSlider-active": {
          boxShadow: "none",
        },
      },
    },
    MuiTabs: {
      root: {
        minHeight: "40px",
      },
      indicator: {
        background: APPalette["brand-300"],
      },
    },
    MuiTab: {
      root: {
        minWidth: 0,
        "@media (min-width: 0px)": {
          minWidth: 0,
        },
        padding: "8px 16px",
        minHeight: "40px",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: 1.5,
        textTransform: "none",
        "&.Mui-selected": {
          color: APPalette["brand-300"],
        },
      },
      textColorPrimary: {
        color: APPalette["grey-600"],
        "&:hover": {
          backgroundColor: APPalette["grey-50"],
        },
      },
    },
    MuiAlert: {
      root: {
        fontSize: "16px",
        lineHeight: 1.5,
        padding: "0 16px",
        alignItems: "center",
      },
      icon: {
        marginRight: "8px",
      },
      action: {
        paddingLeft: "8px",
      },
      standardSuccess: {
        backgroundColor: APPalette["positive-100"],
        color: APPalette["grey-700"],
        "& .MuiAlert-action": {
          color: APPalette["positive-400"],
        },
      },
      standardError: {
        backgroundColor: APPalette["negative-100"],
        color: APPalette["grey-700"],
        "& .MuiAlert-action": {
          color: APPalette["negative-400"],
        },
      },
      standardInfo: {
        backgroundColor: APPalette["info-100"],
        color: APPalette["grey-700"],
        "& .MuiAlert-action": {
          color: APPalette["info-400"],
        },
      },
      standardWarning: {
        backgroundColor: APPalette["warning-100"],
        color: APPalette["grey-700"],
        "& .MuiAlert-action": {
          color: APPalette["warning-400"],
        },
      },
    },
    MuiAccordion: {
      rounded: {
        "&:last-child": {
          borderBottomLeftRadius: "8px",
          borderBottomRightRadius: "8px",
        },
        "&:first-child": {
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
          borderBottomLeftRadius: "8px",
          borderBottomRightRadius: "8px",
        },
      },
    },
    MuiAccordionSummary: {
      root: {
        minHeight: "40px",
        // backgroundColor: APPalette.white,
        overflow: "hidden",
        padding: "0 8px",
        "& .MuiIconButton-root": {
          padding: "0 8px",
        },
        "&.Mui-expanded": {
          minHeight: "40px",
        },
      },
      content: {
        // backgroundColor: APPalette.white,
        margin: "8px 0",
        "&.Mui-expanded": {
          margin: 0,
        },
      },
    },
    MuiAccordionDetails: {
      root: {
        padding: "0 8px 8px",
      },
    },
    MuiInputBase: {
      input: {
        "&.Mui-disabled": {
          opacity: 0.6,
        },
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: "8px",
      },
    },
    MuiFormControl: {
      marginNormal: {
        marginTop: "8px",
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: "16px",
        lineHeight: "24px",
        "&.Mui-disabled": {
          color: APPalette["grey-500"],
          opacity: 0.6,
        },
      },
    },
    MuiFormHelperText: {
      root: {
        color: APPalette["grey-500"],
        marginTop: "2px",
        "&.Mui-disabled": {
          color: APPalette["grey-500"],
          opacity: 0.6,
        },
      },
      contained: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
    MuiOutlinedInput: {
      root: {
        marginLeft: "2px",
        marginRight: "2px",
        borderRadius: "8px",
        "&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error) fieldset":
          {
            borderColor: APPalette["brand-300"],
          },
        "&.Mui-disabled": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: APPalette["grey-200"],
            opacity: 0.6,
          },
          "& .MuiInputBase-readOnly": {
            opacity: 1,
          },
        },
        // Focused state of the TextField
        "&.Mui-focused": {
          "& .MuiOutlinedInput-notchedOutline": {
            boxShadow: `0px 0px 0px 2px ${APPalette.withOpacity("brand-200", 0.35)}`,
            borderWidth: "1px",
          },
        },
      },
      input: {
        padding: "22px 16px 6px",
        height: "24px",
        color: APPalette["grey-600"],
        fontSize: "16px",
        lineHeight: 1.5,
        // '&:focus': {
        //     boxShadow: "0px 0px 0px 2px rgba(103, 143, 211, 0.5)",
        // }
      },
      multiline: {
        padding: "14px 16px 14px",
        "& .MuiInputAdornment-positionEnd": {
          marginBottom: "16px",
        },
      },
      notchedOutline: {
        borderColor: APPalette["grey-200"],
        "&:hover:not(.Mui-disabled)": {
          borderColor: APPalette["brand-300"],
        },
      },
    },
    MuiInputLabel: {
      outlined: {
        color: APPalette["grey-500"],
        transform: "translate(16px, 15px) scale(1)",
        "&.MuiInputLabel-shrink": {
          transform: "translate(16px, 5px) scale(0.75)",
        },
      },
    },
    MuiSelect: {
      outlined: {
        borderRadius: "8px",
        "&.MuiSelect-outlined": {
          // height: "52px"
        },
      },
    },
    MuiList: {
      padding: {
        paddingRight: "8px",
        paddingLeft: "8px",
      },
    },
    MuiListItem: {
      root: {
        color: APPalette["grey-600"],
        borderRadius: "8px",
        paddingTop: "8px",
        paddingBottom: "8px",
        "&.Mui-selected": {
          backgroundColor: APPalette["brand-100"],
          color: APPalette["brand-300"],
        },
        "&.Mui-selected:hover": {
          backgroundColor: APPalette["brand-100"],
          color: APPalette["brand-300"],
        },
        MuiSelected: {
          "&:hover": {
            backgroundColor: APPalette["brand-100"],
          },
        },
      },
      gutters: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
      button: {
        "&:hover": {
          backgroundColor: APPalette["grey-50"],
          color: APPalette["grey-700"],
        },
      },
    },
    MuiMenuItem: {
      root: {
        color: APPalette["grey-600"],
        borderRadius: "8px",
        paddingTop: "8px",
        paddingBottom: "8px",
        "&.Mui-selected": {
          backgroundColor: APPalette["brand-100"],
          color: APPalette["brand-300"],
        },
        "&.Mui-selected:hover": {
          backgroundColor: APPalette["brand-100"],
          color: APPalette["brand-300"],
        },
        MuiSelected: {
          "&:hover": {
            backgroundColor: APPalette["brand-100"],
          },
        },
      },
      gutters: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
      // button: {
      //     '&:hover': {
      //         backgroundColor: APPalette['grey-50'],
      //         color: APPalette['grey-700'],
      //     }
      // },
    },
    MuiRadio: {
      root: {
        color: APPalette["grey-500"],
      },
    },
    MuiFormControlLabel: {
      root: {
        "&.Mui-disabled": {
          opacity: 0.6,
        },
      },
    },
    MuiSwitch: {
      colorPrimary: {
        // '&.Mui-checked + .MuiSwitch-track': {
        //     opacity: 1,
        //     backgroundColor: APPalette['brand-200'],
        // },
        // '&.Mui-disabled + .MuiSwitch-track': {
        //     backgroundColor: APPalette['grey-300'],
        // },
        // '&.Mui-disabled.Mui-checked + .MuiSwitch-track': {
        //     backgroundColor: APPalette['brand-200'],
        // },
        "&.Mui-disabled.Mui-checked": {
          color: APPalette["brand-300"],
          border: "none",
        },
        "&.Mui-disabled": {
          borderColor: APPalette["grey-300"],
          color: APPalette["grey-50"],
          border: "none",
        },
      },
      switchBase: {
        "&.Mui-disabled": {
          color: APPalette["brand-300"],
        },
      },
      thumb: {
        boxShadow:
          "0px 3px 8px rgba(0, 0, 0, 0.15), 0px 1px 1px rgba(0, 0, 0, 0.16)",
        border: `0.5px solid ${APPalette["grey-300"]}`,
      },
      track: {
        // backgroundColor: APPalette['grey-100']
      },
    },
    MuiButton: {
      root: {
        fontWeight: 600,
        textTransform: "none",
        borderRadius: `8px`,
        minWidth: "80px",
        "&.Mui-disabled": {
          opacity: 0.6,
          color: APPalette["brand-300"],
        },
      },
      containedSizeSmall: {
        ...ButtonStyleOverrides.small,
      },
      contained: {
        ...ButtonStyleOverrides.medium,
      },
      containedPrimary: {
        "&.Mui-disabled": {
          color: APPalette.white,
          background: APPalette["brand-300"],
        },
        "&:hover": {
          backgroundColor: APPalette["brand-400"],
        },
        "&:active": {
          backgroundColor: APPalette["brand-500"],
        },
      },
      outlined: {
        ...ButtonStyleOverrides.medium,
      },
      text: {
        ...ButtonStyleOverrides.medium,
      },
      textSecondary: {
        color: APPalette["negative-300"],
        "&.Mui-disabled": {
          color: APPalette["negative-300"],
        },
        "&:hover": {
          backgroundColor: APPalette["grey-100"],
          color: APPalette["negative-400"],
        },
        "&:active": {
          backgroundColor: APPalette["grey-200"],
          color: APPalette["negative-400"],
        },
      },
      outlinedPrimary: {
        border: `1px solid ${APPalette["grey-300"]}`,
        color: APPalette["brand-300"],
        backgroundColor: "#FFFFFF",
        "&.Mui-disabled": {
          opacity: 0.6,
          border: `1px solid ${APPalette["grey-300"]}`,
          color: APPalette["brand-300"],
        },
        "&:hover": {
          backgroundColor: APPalette["grey-100"],
          border: `1px solid ${APPalette["grey-300"]}`,
        },
        "&:active": {
          backgroundColor: APPalette["grey-200"],
          color: APPalette["brand-300"],
        },
      },
      containedSizeLarge: {
        ...ButtonStyleOverrides.large,
      },
      outlinedSizeSmall: {
        ...ButtonStyleOverrides.small,
      },
      outlinedSizeLarge: {
        ...ButtonStyleOverrides.large,
      },
      textSizeSmall: {
        ...ButtonStyleOverrides.small,
      },
      textSizeLarge: {
        ...ButtonStyleOverrides.large,
      },
    },
    MuiBadge: {
      badge: {
        height: "16px",
        color: APPalette["grey-700"],
        backgroundColor: APPalette["grey-200"],
      },
      colorPrimary: {
        color: APPalette.white,
        backgroundColor: APPalette["brand-300"],
      },
      colorSecondary: {
        color: APPalette["brand-300"],
        backgroundColor: APPalette.white,
      },
      colorError: {
        color: APPalette.white,
        backgroundColor: APPalette["negative-300"],
      },
      dot: {
        height: "12px",
        minWidth: "12px",
        border: `2px solid ${APPalette.white}`,
        borderRadius: "10px",
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: "12px",
        lineHeight: 1.33,
        color: "#FFFFFF",
        backgroundColor: APPalette.withOpacity("black", 0.8),
        borderRadius: "8px",
        padding: "8px 16px",
        boxShadow: `0 4px 8px 0 ${APPalette.withOpacity("grey-700", 0.15)}`,
      },
    },
  },
};

// export default styles;
