import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { APColumn, APRow } from "codemod_components/layout/APFlex";
import { APPalette } from "codemod_components/utils/APPalette";
import { APCard } from "codemod_components/elements/APCard";
import { APButton } from "codemod_components/elements/APButton";
import { APFormFieldText } from "codemod_components/forms/APFormFieldText";
import { REGEX } from "codemod_components/utils/REGEX";
import { APTag } from "codemod_components/elements/APTag";
import { showLoadingDialog } from "codemod_components/dialogs/showLoadingDialog";
import { showErrorDialog } from "codemod_components/dialogs/showErrorDialog";
import { useAPForm } from "codemod_components/forms/useAPForm";
import { APFormFieldImage } from "codemod_components/forms/APFormFieldImage";
import { APFormFieldDate } from "codemod_components/forms/APFormFieldDate";
import { APExpanded } from "codemod_components/layout/Common";
import { getIt } from "codemod_components/dialogs/DialogHelper";
import FinacuityLoanApplicationApiServices from "../../../../requests/LoanapplyRequests";
import OnboardingApiServices from "../../../../requests/OnboardingRequests";
import { RootState } from "@reduxjs/toolkit/query";
import { APOtp } from "codemod_components/forms/APOtp";
import { APForm } from "codemod_components/forms/APForm";
import { convertDateFormat } from "utils/string-utils";
import { useForceRender } from "codemod_components/hooks/useForceUpdate";
import { APGrid } from "codemod_components/layout/APGrid";
import { dispatchUpdatePersonalLoanApplication } from "store/slices/personalSlice";
import CountdownButton from "Screens/AuthModule/AnimatedButton/AnimatedButton";
import { useCountDown } from "codemod_components/hooks/useCountDown";

const PersonalLoanApplicationForm = ({
  kycForm,
  setKycForm,
  handleImageUpload,
  handlerResendOtpRequest,
  timeLeft,
}) => {
  const formatDate = (timestamp) => {
    if (!timestamp || isNaN(timestamp)) {
      return "";
    }
    const date = new Date(timestamp);
    return date.toISOString().split("T")[0];
  };

  const timestamp = (dateString) => {
    const number = Number(dateString);
    return !isNaN(number) && number.toString().length >= 10;
  };

  const formatDob = (dob) => {
    if (timestamp(dob)) {
      return formatDate(dob);
    }
    return dob;
  };

  const initialDob = formatDob(kycForm.dob);

  const allFieldsPrefilled =
    kycForm.name &&
    kycForm.email &&
    kycForm.pan &&
    kycForm.mobile &&
    kycForm.dob;

  return (
    <>
      <APFormFieldText
        disabled={allFieldsPrefilled}
        style={{ width: "100%" }}
        label="Email"
        textAppearance={"lowercase"}
        initialValue={kycForm.email}
        onChanged={(value) => setKycForm((prev) => ({ ...prev, email: value }))}
        validator={(value) =>
          !value || !REGEX.EMAIL.test(value)
            ? "Please enter a valid Email"
            : null
        }
      />
      <APFormFieldText
        disabled={allFieldsPrefilled}
        style={{ width: "100%" }}
        keyboardType={"tel"}
        label="Mobile [registered with Aadhaar]"
        initialValue={kycForm.mobile}
        onChanged={(value) =>
          setKycForm((prev) => ({ ...prev, mobile: value }))
        }
        validator={(value) =>
          !value || !REGEX.PHONE.test(value)
            ? "Please enter a valid Phone"
            : null
        }
      />
      {!allFieldsPrefilled && (
        <APFormFieldImage
          label="PAN Card"
          src={kycForm?.base64Image}
          onChange={handleImageUpload}
          validator={(file) => (!file ? "Please upload a valid image" : null)}
        />
      )}
      <APGrid count={2} columnGap={"4px"}>
        <APFormFieldText
          disabled={allFieldsPrefilled}
          style={{ width: "100%" }}
          textAppearance={"capitalize"}
          label="Name"
          initialValue={kycForm.name}
          onChanged={(value) =>
            setKycForm((prev) => ({ ...prev, name: value }))
          }
          validator={(value) =>
            !value || !REGEX.NAME_REGEX.test(value)
              ? "Please enter First and Last Name"
              : null
          }
        />
        <APFormFieldText
          disabled={allFieldsPrefilled}
          style={{ width: "100%" }}
          label="PAN"
          textAppearance={"uppercase"}
          initialValue={kycForm.pan}
          onChanged={(value) =>
            setKycForm((prev) => ({ ...prev, pan: value.toUpperCase().trim() }))
          }
          validator={(value) => {
            const formattedValue = value?.toUpperCase().trim();
            return !formattedValue || !REGEX.PAN.test(formattedValue)
              ? "Please enter a valid PAN number"
              : null;
          }}
        />
      </APGrid>
      <APFormFieldDate
        disabled={allFieldsPrefilled}
        style={{ width: "100%" }}
        label="Date of Birth"
        initialValue={initialDob}
        onChanged={(value) => setKycForm((prev) => ({ ...prev, dob: value }))}
        validator={(value) =>
          !value || value.length < 10 ? "Please enter a valid date" : null
        }
      />
      {!allFieldsPrefilled && (
        <APColumn>
          <APOtp
            digits={6}
            onChanged={(value) =>
              setKycForm((prev) => ({ ...prev, otp: value }))
            }
          />
          <APRow style={{ justifyContent: "start" }}>
            <CountdownButton
              timeLeft={timeLeft}
              onClick={handlerResendOtpRequest}
              duration={60000 / 1000}
            />
          </APRow>
        </APColumn>
      )}
    </>
  );
};

const PersonalInformation = ({ goToNextStep }) => {
  const dispatch = useDispatch();
  const personalLoanApplicationFormControl = useAPForm();
  const { key, reload } = useForceRender();

  const { profile, user } = useSelector(
    (state: RootState<any, any, any>) => state.authenticationSlice,
  );

  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [initialForm, setInitialForm] = useState({
    base64Image: "",
    consent: false,
    name: profile?.name || "",
    email: profile?.email || "",
    pan: profile?.pan || "",
    dob: profile?.dob || "",
    otpRequested: false,
    otp: null,
    mobile: profile?.mobile || "",
  });

  const [canResendOtp, setCanResendOtp] = useState(true);
  const [timeLeft, { start }] = useCountDown(60000, 1000);

  useEffect(() => {
    if (initialForm.otpRequested) {
      start();
    }
  }, [initialForm.otpRequested, start]);

  useEffect(() => {
    setInitialForm({
      base64Image: "",
      consent: false,
      name: profile?.name || "",
      email: profile?.email || "",
      pan: profile?.pan || "",
      dob: profile?.dob || "",
      otpRequested: false,
      otp: null,
      mobile: profile?.mobile || "",
    });
    reload();
  }, [profile]);

  const generateBureauPullOtpReq = async () => {
    const { data, status_code, error } = await showLoadingDialog(
      async () =>
        await getIt(
          FinacuityLoanApplicationApiServices,
        ).getPersoanlLoanApplicationOtp({ mobile: initialForm.mobile }),
    );
    if (status_code === 0) {
      start();
      setInitialForm((prevState) => ({
        ...prevState,
        otpRequested: true,
      }));
    } else {
      await showErrorDialog(error?.message);
    }
  };

  const handlerNextAction = async () => {
    try {
      if (!initialForm.otpRequested) {
        await generateBureauPullOtpReq();
      } else if (initialForm.otpRequested && initialForm.otp?.length === 6) {
        await submitOtp();
      } else if (initialForm.otpRequested && !initialForm.otp) {
        await showErrorDialog("Please enter the OTP");
      }
    } catch (error) {
      await showErrorDialog(error?.message);
    }
  };

  const submitOtp = async () => {
    const { data, status_code, error } = await showLoadingDialog(
      async () =>
        await getIt(
          FinacuityLoanApplicationApiServices,
        ).submitPersonalLoanApplicationOtp({
          name: initialForm.name,
          email: initialForm.email.toLowerCase(),
          pan: initialForm.pan.toUpperCase(),
          dob: new Date(initialForm.dob).getTime(),
          mobile: initialForm.mobile,
          otp: initialForm.otp,
        }),
    );
    if (status_code === 0 && data?.application) {
      dispatch(dispatchUpdatePersonalLoanApplication(data?.application));
      goToNextStep();
    } else if (status_code === 406) {
      await showErrorDialog("Your OTP Expired, Try Resending the OTP");
    } else {
      await showErrorDialog(error?.message || "Something went wrong");
    }
  };

  const handleImageUpload = async (value: string) => {
    if (value && value !== initialForm?.base64Image) {
      try {
        await showLoadingDialog(async () => {
          const resp = await getIt(
            FinacuityLoanApplicationApiServices,
          ).generatePreSignedUrl(user?.fu_id, "individual_pan");
          await getIt(OnboardingApiServices).uploadDocumentToPreSignedUrl(
            resp.data.url,
            value,
          );
          const { data, status_code } = await getIt(
            FinacuityLoanApplicationApiServices,
          ).performExecuteActionOnDocument(user?.fu_id, "individual_pan");
          if (status_code === 0) {
            setInitialForm((prevState) => ({
              ...prevState,
              name: data.name,
              pan: data.number,
              dob: convertDateFormat(data.dob),
              base64Image: value,
            }));
            reload();
          }
        });
      } catch (err) {
        await showErrorDialog(err.message);
      }
    }
  };

  const allFieldsPrefilled =
    initialForm.name &&
    initialForm.email &&
    initialForm.pan &&
    initialForm.mobile &&
    initialForm.dob;

  return (
    <APColumn
      style={{ background: APPalette.white, paddingTop: "0.5rem" }}
      mainAxisSize="max"
      crossAxisAlignment="stretch"
    >
      <APExpanded>
        <APForm control={personalLoanApplicationFormControl}>
          <APColumn
            mainAxisSize="min"
            crossAxisAlignment="stretch"
            style={{ backgroundColor: APPalette["grey-50"], padding: 0 }}
          >
            <APColumn>
              <APCard style={{ borderRadius: "8px", width: "95%" }}>
                <PersonalLoanApplicationForm
                  key={key}
                  kycForm={initialForm}
                  setKycForm={setInitialForm}
                  handleImageUpload={handleImageUpload}
                  handlerResendOtpRequest={generateBureauPullOtpReq}
                  timeLeft={timeLeft}
                />
              </APCard>
              {errorMessage && <APTag variant="warning">{errorMessage}</APTag>}
              <APButton
                size="large"
                style={{ width: "85%", marginTop: "1vh" }}
                onClick={async () => {
                  if (await personalLoanApplicationFormControl.validate()) {
                    if (allFieldsPrefilled) {
                      goToNextStep();
                    } else {
                      handlerNextAction();
                    }
                  }
                }}
              >
                {allFieldsPrefilled
                  ? "Next"
                  : !Boolean(initialForm.otpRequested)
                    ? "Get OTP"
                    : "Next"}
              </APButton>
            </APColumn>
          </APColumn>
        </APForm>
      </APExpanded>
    </APColumn>
  );
};

export default PersonalInformation;
