import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAPForm } from "codemod_components/forms/useAPForm";
import { getIt } from "codemod_components/dialogs/DialogHelper";
import FinacuityProfileApiServices from "../../requests/UserRequests";
import { Player } from "@lottiefiles/react-lottie-player";
import { APForm } from "codemod_components/forms/APForm";
import { APPalette } from "codemod_components/utils/APPalette";
import { APIcon } from "codemod_components/icons/APIcon";
import { APFormFieldText } from "codemod_components/forms/APFormFieldText";
import { REGEX } from "codemod_components/utils/REGEX";
import { showErrorDialog } from "codemod_components/dialogs/showErrorDialog";
import { showLoadingDialog } from "codemod_components/dialogs/showLoadingDialog";
import otpAnimation from "../../animations/lottie/otp_animation.json";
import {
  APIntrinsicHeight,
  APPadding,
  APSizedBox,
} from "codemod_components/layout/Common";
import { APColumn, APRow } from "codemod_components/layout/APFlex";
import { APScrollView } from "codemod_components/layout/APScrollView";
import { APText } from "codemod_components/elements/APText";
import { APButton } from "codemod_components/elements/APButton";
import { APTag } from "codemod_components/elements/APTag";
import { dispatchRegisterCurrentUser } from "../../store/slices/authenticationSlice";
import { useDispatch } from "react-redux";
import { checkSessionTokenExists } from "./auth-utils";

const LoginScreen = () => {
  const navigate = useNavigate();
  const loginControlForm = useAPForm();
  const queryParams = new URLSearchParams(location.search);
  const continueUrl = queryParams.get("continue");
  const dispatch = useDispatch();
  const [userMaskedData, setUserMaskedData] = useState<{
    maskedEmail: string | null;
    maskedPhoneNumber: string | null;
  } | null>({ maskedEmail: null, maskedPhoneNumber: null });
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const pushUserBackToSource = async (data: any) => {
    dispatch(
      dispatchRegisterCurrentUser({
        user: data?.user,
        token: data?.token,
        refresh: data?.refresh,
      }),
    );
    if (continueUrl) {
      navigate(decodeURIComponent(continueUrl));
    } else navigate(-1);
  };

  useEffect(() => {
    checkSessionTokenExists(
      "sta",
      "str",
      FinacuityProfileApiServices,
      pushUserBackToSource,
    );
  }, []);

  const generateOtpHelper = async () => {
    try {
      await showLoadingDialog(async () => {
        const { data, error, status_code } = await getIt(
          FinacuityProfileApiServices,
        ).getLoginOtp(userMaskedData?.maskedPhoneNumber);
        if (status_code === 0) {
          navigate(
            {
              pathname: "/auth/verify",
              search: queryParams.toString(),
            },
            {
              state: { maskedPhoneNumber: userMaskedData?.maskedPhoneNumber },
            },
          );
        } else {
          await showErrorDialog(error?.message);
        }
      });
    } catch (error: any) {
      await showErrorDialog(error.message);
    }
  };

  return (
    <APIntrinsicHeight crossAxisAlignment="stretch" mainAxisAlignment="start">
      <APScrollView style={{ maxWidth: "600px" }}>
        <APColumn
          mainAxisAlignment="start"
          crossAxisAlignment="stretch"
          mainAxisSize="max"
        >
          <APPadding>
            <APColumn>
              <APForm control={loginControlForm} style={{ width: "95%" }}>
                <APColumn>
                  <APSizedBox height="16px" />
                  <APColumn>
                    <APRow style={{ justifyContent: "center" }}>
                      <APIcon icon="finacuityLogo" size={50} />
                      <APText variant="h0">MONEYEASE</APText>
                    </APRow>
                    <APText
                      color={APPalette["grey-700"]}
                      variant="h4"
                      style={{ textAlign: "center", marginTop: "0.2rem" }}
                    >
                      Log in to manage your finances, designed for your
                      financial success.
                    </APText>
                    <APSizedBox height="26px" />
                    <Player
                      src={otpAnimation}
                      autoplay
                      loop
                      style={{ height: "30%", width: "60%" }}
                    />
                    <APSizedBox height="26px" />
                  </APColumn>
                  <APFormFieldText
                    size="medium"
                    style={{ width: "100%" }}
                    label="Mobile"
                    keyboardType="tel"
                    initialValue=""
                    onChanged={(value) =>
                      setUserMaskedData((prev) => ({
                        ...prev,
                        maskedPhoneNumber: value,
                      }))
                    }
                    validator={(value) => {
                      if (!value || REGEX.PHONE.test(value) == false) {
                        return "Please enter a valid Mobile number";
                      }
                      return null;
                    }}
                  />
                  {errorMessage && (
                    <APTag variant="warning">{errorMessage}</APTag>
                  )}
                </APColumn>
                <APButton
                  size="large"
                  style={{ width: "100%", marginTop: "1vh" }}
                  onClick={async () => {
                    setErrorMessage(undefined);
                    if (await loginControlForm.validate()) {
                      try {
                        await generateOtpHelper();
                      } catch (err: any) {
                        setErrorMessage(err.message);
                      }
                    }
                  }}
                >
                  Send me a OTP
                </APButton>
                <APColumn style={{ margin: "0.5rem" }}>
                  <APText variant="paragraph-small">
                    By proceeding, you agree to the{" "}
                    <span>
                      <a
                        href="https://www.moneyease.in/terms-of-use/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Terms & Conditions
                      </a>
                    </span>{" "}
                    and{" "}
                    <span>
                      <a
                        href="https://www.moneyease.in/privacy-policy-2"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Privacy Policy
                      </a>
                    </span>{" "}
                    of <strong>MoneyEase</strong>
                  </APText>
                </APColumn>
              </APForm>
            </APColumn>
          </APPadding>
        </APColumn>
      </APScrollView>
    </APIntrinsicHeight>
  );
};

export default LoginScreen;
