import React from "react";
import { APColumn } from "codemod_components/layout/APFlex";
import { APText } from "codemod_components/elements/APText";

export function IntroScreen(session_meta) {
  return (
    <APColumn crossAxisAlignment="start" gap="12px">
      <APText variant="h3">
        Hello <strong>{session_meta?.user?.name}</strong>,
      </APText>
      <APText variant="subtext">
        Please verify the contact details before proceeding:
        <br />
        Email: <strong>{session_meta?.user?.email}</strong>
        <br />
        Mobile: <strong>{session_meta?.user?.mobile}</strong>
        <br />
        <br />
        (If the details are incorrect, please contact our support team)
      </APText>

      <APColumn crossAxisAlignment="start">
        <APText>Documents to be kept ready for KYC</APText>
        {
          <ul>
            {session_meta?.steps?.map((doc, index) => (
              <APText key={index}>
                <strong>
                  {index + 1}. {doc.step_title}, {doc.minor_header}
                </strong>
              </APText>
            ))}
          </ul>
        }
      </APColumn>
    </APColumn>
  );
}

export function addParamsToCurrentUrl(newParams: { [key: string]: string }) {
  const currentUrl = new URL(window.location.href);
  const searchParams = new URLSearchParams(currentUrl.search);

  Object.entries(newParams).forEach(([key, value]) => {
    searchParams.set(key, value);
  });

  currentUrl.search = searchParams.toString();

  return currentUrl.toString();
}

export function launchUrl(url: string) {
  window.location.href = url;
}

export let OccupationTable = [
  { value: "01", label: "Private Sector Service" },
  { value: "02", label: "Public Sector" },
  { value: "03", label: "Business" },
  { value: "04", label: "Professional" },
  { value: "05", label: "Agriculturist" },
  { value: "06", label: "Retired" },
  { value: "07", label: "Housewife" },
  { value: "08", label: "Student" },
  { value: "09", label: "Forex Dealer" },
  { value: "10", label: "Government Service" },
  { value: "99", label: "Others" },
];

export let RelationshipTable = [
  { value: "brother", label: "brother" },
  { value: "sister", label: "sister" },
  { value: "father", label: "father" },
  { value: "mother", label: "mother" },
  { value: "son", label: "son" },
  { value: "daughter", label: "daughter" },
  { value: "husband", label: "husband" },
  { value: "wife", label: "wife" },
  { value: "other", label: "other" },
];

export let SalutationTable = [
  { value: "mr", label: "mr" },
  { value: "mrs", label: "mrs" },
  { value: "ms", label: "ms" },
];

export let TypeOfBusinessTable = [
  { value: "01", label: "Private Limited Company" },
  { value: "02", label: "Public Limited Company" },
  { value: "03", label: "Partnerships" },
  { value: "04", label: "LLP Registration" },
  { value: "05", label: "One Person Company" },
  { value: "06", label: "Sole Proprietorship" },
];

export let TypeOfRegistrationTable = [
  { value: "01", label: "Regular" },
  { value: "02", label: "Composition" },
  { value: "03", label: "Casual" },
  { value: "04", label: "Non-Resident" },
  { value: "05", label: "ISD" },
  { value: "06", label: "TDS" },
  { value: "07", label: "TCS" },
];
