import Button from "@mui/material/Button";

import React, { useContext, useState } from "react";
import { APFormContext } from "codemod_components/forms/APForm";
import {
  ButtonType,
  MUIButtonVariant,
} from "codemod_components/elements/APButton";
import { APFormControl } from "./useAPForm";
import { APRow } from "codemod_components/layout/APFlex";
import { CustomLoading } from "codemod_components/elements/APAsyncButton";

interface IFormSubmitProps {
  onClick?: () => Promise<any>;
  children: any;
  nonSubmit?: boolean;
  type?: ButtonType;
  size?: "small" | "medium" | "large";
  style?: React.CSSProperties;
  /**
   * @deprecated use attribute 'type' instead
   */
  variant?: "contained" | "outlined" | "text";
  /**
   * @deprecated use attribute 'type' instead
   */
  color?: "default" | "primary" | "secondary" | "inherit";
}

export function APFormSubmit({
  onClick,
  children,
  color,
  variant,
  nonSubmit,
  size = "large",
  style,
  type,
}: IFormSubmitProps) {
  switch (variant) {
    case "contained":
      type = "primary";
      break;
    case "outlined":
      type = "secondary";
      break;
    case "text":
      type = "secondary";
      break;
  }

  let muiVariant: MUIButtonVariant = "contained";
  let muiColor: "inherit" | "primary" | "secondary" | "default" = "primary";
  switch (type) {
    case "secondary":
      muiVariant = "outlined";
      muiColor = "primary";
      break;
    case "primary":
      muiVariant = "text";
      muiColor = "secondary";
  }

  const [disabled, setDisabled] = useState(false);

  async function onclick() {
    if (!disabled) {
      setDisabled(true);
      if (onClick) await onClick();
      setDisabled(false);
    }
  }

  var control2: APFormControl | undefined = useContext(APFormContext);

  if (nonSubmit) control2 = undefined;

  if (control2 !== undefined) {
    control2.submit = onclick;
  }

  var disabled2 = disabled;
  if (!onClick) {
    disabled2 = true;
  }

  return (
    <Button
      disableElevation
      size={size}
      style={{ paddingLeft: 8, paddingRight: 8, marginTop: 16, ...style }}
      disabled={disabled2}
      variant={muiVariant}
      color={muiColor}
      onClick={control2 ? undefined : onclick}
      type={nonSubmit ? undefined : "submit"}
    >
      <>
        <APRow
          style={{
            position: "relative",
            visibility: disabled ? "hidden" : undefined,
          }}
          mainAxisAlignment="center"
          gap="8px"
        >
          {children}
        </APRow>
        {disabled && <CustomLoading />}
      </>
    </Button>
  );
}
