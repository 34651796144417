import { APFormFieldText } from "../../../../codemod_components/forms/APFormFieldText";
import { REGEX } from "../../../../codemod_components/utils/REGEX";
import { APForm } from "../../../../codemod_components/forms/APForm";
import { useAPForm } from "../../../../codemod_components/forms/useAPForm";
import {
  APExpanded,
  APSizedBox,
} from "../../../../codemod_components/layout/Common";
import { APFormSubmit } from "../../../../codemod_components/forms/APFormSubmit";
import { APColumn, APRow } from "../../../../codemod_components/layout/APFlex";
import React, { useState } from "react";
import { APTag } from "../../../../codemod_components/elements/APTag";
import { APText } from "../../../../codemod_components/elements/APText";
import { showLoadingDialog } from "../../../../codemod_components/dialogs/showLoadingDialog";
import { getIt } from "../../../../codemod_components/dialogs/DialogHelper";
import { showErrorDialog } from "../../../../codemod_components/dialogs/showErrorDialog";
import FinacuityLoanApplicationApiServices from "../../../../requests/LoanapplyRequests";
import { APFormFieldDropdown } from "../../../../codemod_components/forms/APFormFieldDropdown";

const DirectorDetailForm = ({
  kybForm,
  setKybForm,
  goToNextStep,
  goToPrevStep,
}) => {
  const businessLoanDirectorFormControl = useAPForm();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [directorInfo, setDirectorInfo] = useState({
    name: kybForm?.kybDirector?.name || "",
    pan: kybForm?.kybDirector?.pan || "",
    mobile: kybForm?.kybDirector?.mobile || "",
    email: kybForm?.kybDirector?.email || "",
  });

  const handlerPullBureauInfoForBusinessLoan = async () => {
    try {
      const { data, error, status_code } = await showLoadingDialog(
        async () =>
          await getIt(
            FinacuityLoanApplicationApiServices,
          ).getDirectorDetailedBureauReport(
            kybForm.application.application_id,
            directorInfo.pan,
            directorInfo.mobile,
            directorInfo.email,
          ),
      );
      if (status_code === 0) {
        setKybForm((prev: any) => ({
          ...prev,
          ...{ kybDirector: directorInfo },
        }));
        goToNextStep();
      } else {
        await showErrorDialog(error?.message);
      }
    } catch (error: any) {
      await showErrorDialog(error.message);
    }
  };
  return (
    <>
      <APForm
        control={businessLoanDirectorFormControl}
        style={{ width: "100%" }}
      >
        <APFormFieldDropdown
          style={{ width: "100%" }}
          label="Select the Director with Highest Share"
          items={
            (kybForm?.gst_details?.taxpayerDetails?.mbr &&
              kybForm?.gst_details?.taxpayerDetails?.mbr?.map((j) => ({
                value: j,
                label: j,
              }))) ||
            []
          }
          initialValue={directorInfo?.name || ""}
          onChanged={(v) => setDirectorInfo((p) => ({ ...p, ...{ name: v } }))}
          validator={(value) => {
            if (!value) {
              return "Please select a director with highest share";
            }
            return null;
          }}
        />
        <APFormFieldText
          style={{ width: "100%" }}
          label="Enter Director Mobile"
          initialValue={directorInfo.mobile || ""}
          onChanged={(v) =>
            setDirectorInfo((p) => ({ ...p, ...{ mobile: v } }))
          }
          validator={(value) => {
            if (!value || !REGEX.PHONE.test(value)) {
              return "Please enter a valid 10-digit mobile number";
            }
            return null;
          }}
        />
        <APFormFieldText
          style={{ width: "100%" }}
          label="Enter Director PAN Number"
          initialValue={directorInfo.pan || ""}
          onChanged={(v) => setDirectorInfo((p) => ({ ...p, ...{ pan: v } }))}
          textAppearance="uppercase"
          validator={(value) => {
            if (!value || !REGEX.PAN.test(value)) {
              return "Please enter a valid PAN number";
            }
            return null;
          }}
        />
        <APFormFieldText
          style={{ width: "100%" }}
          label="Enter Director Email Address"
          initialValue={directorInfo.email || ""}
          onChanged={(v) => setDirectorInfo((p) => ({ ...p, ...{ email: v } }))}
          textAppearance="lowercase"
          validator={(value) => {
            if (!value || !REGEX.EMAIL.test(value)) {
              return "Please enter a valid Email Address";
            }
            return null;
          }}
        />
        {errorMessage && <APTag variant="warning">{errorMessage}</APTag>}
        <APColumn>
          <APFormSubmit
            style={{ width: "100%" }}
            size="large"
            onClick={async () => {
              if (await businessLoanDirectorFormControl.validate()) {
                try {
                  await handlerPullBureauInfoForBusinessLoan();
                } catch (err: any) {
                  setErrorMessage(err.message);
                }
              }
            }}
          >
            Next
          </APFormSubmit>
        </APColumn>
        <APSizedBox height="12px" />
        <APRow>
          <APText variant="button-small">
            By Clicking Next, you authorize MoneyEase to query Credit Bureau, to
            process your business loan application.
          </APText>
        </APRow>
      </APForm>
    </>
  );
};

export default DirectorDetailForm;
