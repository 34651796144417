import { singleton } from "tsyringe";

@singleton()
export class DialogHolder {
  cmps: Array<{ id: string; element: JSX.Element }> = [];

  listener?: (args: Array<{ id: string; element: JSX.Element }>) => void;

  setListener(
    listener: (args: Array<{ id: string; element: JSX.Element }>) => void,
  ) {
    this.listener = listener;
  }

  removeListener() {
    delete this.listener;
  }

  updateListener() {
    if (this.listener != null) {
      this.listener(this.cmps);
    }
  }

  push(cmp: JSX.Element) {
    this.cmps.push({ id: Math.random().toString(36), element: cmp });
    this.updateListener();
  }

  pop(cmp: JSX.Element) {
    // if (this.cmps.includes(cmp)) {
    var pos = this.cmps.findIndex((item) => item.element === cmp);
    if (pos >= 0) {
      this.cmps.splice(pos, 1);
      this.updateListener();
    }
    // }
  }
}
