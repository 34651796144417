import { APText } from "codemod_components/elements/APText";
import { APRow } from "codemod_components/layout/APFlex";
import { Deferred } from "codemod_components/utils/Deferred";
import { APDialogCard } from "codemod_components/elements/APDialogCard";
import { APButton } from "codemod_components/elements/APButton";
import ReportIcon from "@mui/icons-material/Report";

export function ConfirmationDialog(props: {
  deferred: Deferred<boolean>;
  title: string;
  body: string;
  yesAction?: string;
  noAction?: string;
}) {
  return (
    <APDialogCard
      style={{ minWidth: "100%", maxWidth: "500px" }}
      title={props.title}
      leading={<ReportIcon />}
      actions={
        <APRow mainAxisAlignment="end" gap="8px">
          <APButton
            size="small"
            type="secondary"
            onClick={() => props.deferred.resolve(false)}
          >
            {props.noAction ?? "Cancel"}
          </APButton>
          <APButton size="small" onClick={() => props.deferred.resolve(true)}>
            {props.yesAction ?? "Yes"}{" "}
          </APButton>
        </APRow>
      }
    >
      <APText variant="paragraph-small"> {props.body} </APText>
    </APDialogCard>
  );
}
