import { APGrid } from "codemod_components/layout/APGrid";
import { showLoadingDialog } from "codemod_components/dialogs/showLoadingDialog";
import { getIt } from "codemod_components/dialogs/DialogHelper";
import { showErrorDialog } from "codemod_components/dialogs/showErrorDialog";
import React, { useEffect, useState } from "react";
import { APFormFieldText } from "codemod_components/forms/APFormFieldText";
import { REGEX } from "codemod_components/utils/REGEX";
import { useDispatch, useSelector } from "react-redux";
import { updateGstForm } from "../../../store/slices/onboardingSlice";
import { useForceRender } from "codemod_components/hooks/useForceUpdate";
import axios from "axios";
import { convertBytesToBase64PDF } from "../../../utils/string-utils";
import { APForm } from "codemod_components/forms/APForm";
import { useAPForm } from "codemod_components/forms/useAPForm";
import { APFormSubmit } from "codemod_components/forms/APFormSubmit";
import { APExpanded } from "codemod_components/layout/Common";
import { APRow } from "codemod_components/layout/APFlex";
import { APTag } from "../../../codemod_components/elements/APTag";
import { APFormFieldPDF } from "codemod_components/forms/APFormFieldPdf";
import OnboardingApiServices from "../../../requests/OnboardingRequests";
import { APFormFieldDropdown } from "codemod_components/forms/APFormFieldDropdown";
import { TypeOfBusinessTable, TypeOfRegistrationTable } from "../IntroScreen";
import { useLocation, useNavigate } from "react-router-dom";

const RenderOcrInfoStatus = ({ kycForm, onChange }) => {
  if (!kycForm) return null;
  return (
    <>
      <APFormFieldText
        label="Registration Number"
        initialValue={kycForm.registration_no?.value}
        onChanged={(value) => onChange("registration_no", value)}
        validator={(value) => {
          if (!value || REGEX.GST.test(value) == false) {
            return "Please enter a valid Register number";
          }
          return null;
        }}
      />
      <APFormFieldText
        label="Legal Name"
        initialValue={kycForm.legal_name?.value}
        onChanged={(value) => onChange("legal_name", value)}
        validator={(value) => {
          if (!value || value.length < 3) {
            return "Please enter a valid Legal Name";
          }
          return null;
        }}
      />
      <APFormFieldText
        label="Trade Name"
        initialValue={kycForm.trade_name?.value}
        onChanged={(value) => onChange("trade_name", value)}
        validator={(value) => {
          if (!value || value.length < 3) {
            return "Please enter a valid name";
          }
          return null;
        }}
      />
      <APFormFieldDropdown
        label="Type of Business"
        items={TypeOfBusinessTable}
        initialValue={kycForm.type_of_business?.value}
        onChanged={(value) => {
          if (value) {
            onChange(
              "occupationCode",
              TypeOfBusinessTable.find((a) => a.value === value)?.label!,
            );
          }
        }}
        validator={(value) => {
          if (!value) {
            return "Please select business type";
          }
          return null;
        }}
      />
      <APFormFieldText
        label="Date of Issue"
        initialValue={kycForm.date_of_issue?.value}
        onChanged={(value) => onChange("date_of_issue", value)}
        validator={(value) => {
          if (!value || value.length < 10) {
            return "Please enter a valid date";
          }
          return null;
        }}
        type="date"
      />
      <APFormFieldText
        label="GSTIN"
        initialValue={kycForm.gstin?.value}
        onChanged={(value) => onChange("gstin", value)}
        validator={(value) => {
          if (!value || REGEX.GST.test(value) == false) {
            return "Please enter a valid GSTIN";
          }
          return null;
        }}
      />
      <APFormFieldText
        label="Place of Business"
        initialValue={kycForm.principal_place_of_business?.value}
        onChanged={(value) => onChange("principal_place_of_business", value)}
        validator={(value) => {
          if (!value || value.length < 3) {
            return "Please enter a valid Address";
          }
          return null;
        }}
      />
      <APFormFieldDropdown
        label="Type of Registration"
        items={TypeOfRegistrationTable}
        initialValue={kycForm.type_of_registration?.value}
        onChanged={(value) => {
          if (value) {
            onChange(
              "occupationCode",
              TypeOfRegistrationTable.find((a) => a.value === value)?.label!,
            );
          }
        }}
        validator={(value) => {
          if (!value) {
            return "Please select type of registration";
          }
          return null;
        }}
      />
      {kycForm.directors &&
        kycForm?.directors?.map((director, index) => (
          <React.Fragment key={index}>
            <APFormFieldText
              label={`Director ${index + 1} Name`}
              initialValue={director.name?.value}
              onChanged={(value) => onChange(`directors[${index}].name`, value)}
            />
            <APFormFieldText
              label={`Director ${index + 1} Designation`}
              initialValue={director.designation?.value}
              onChanged={(value) =>
                onChange(`directors[${index}].designation`, value)
              }
            />
            <APFormFieldText
              label={`Director ${index + 1} State`}
              initialValue={director.state?.value}
              onChanged={(value) =>
                onChange(`directors[${index}].state`, value)
              }
            />
          </React.Fragment>
        ))}
    </>
  );
};

const GstForm = ({ goToNextStep, goToPrevStep, isLastStep }) => {
  const navigate = useNavigate();
  const gstFormControl = useAPForm();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const { gst, session_id, token, session_meta } = useSelector(
    (state: any) => state.onboardingSlice,
  );

  const { key, reload } = useForceRender();

  const preFillInfo = async () => {
    await showLoadingDialog(async () => {
      let { data, status_code } = await getIt(
        OnboardingApiServices,
      ).getSessionDetail(session_id, token);
      const gstPrefilledObject = data?.session?.gst;
      if (gstPrefilledObject?.doc_identifier) {
        let { data, status_code } = await showLoadingDialog(
          async () =>
            await getIt(OnboardingApiServices).downloadDocPreSignedUrl(
              session_id,
              "company_gst",
              token,
            ),
        );
        if (status_code === 0 && Array.isArray(data) && data.length > 0) {
          const response = await axios.get(data[0].url, {
            responseType: "arraybuffer",
          });
          if (response.status === 200) {
            dispatch(
              updateGstForm({
                ...gstPrefilledObject,
                base64Image: convertBytesToBase64PDF(response.data),
              }),
            );
            reload();
          }
        }
      }
    });
  };

  useEffect(() => {
    preFillInfo();
  }, []);

  const handleImageUpload = async (value) => {
    if (value && value !== gst?.base64Image) {
      try {
        await showLoadingDialog(async () => {
          const resp = await getIt(OnboardingApiServices).generatePreSignedUrl(
            session_id,
            "company_gst",
            token,
          );
          await getIt(OnboardingApiServices).uploadDocumentToPreSignedUrl(
            resp.data.url,
            value,
          );
          const ocrResponseStatus = await getIt(
            OnboardingApiServices,
          ).performExecuteActionOnDocument(session_id, "company_gst", token);
          dispatch(
            updateGstForm({
              base64Image: value,
              doc_identifier: resp.data.doc_identifier,
              ocr_extract: ocrResponseStatus?.data,
            }),
          );
          reload();
        });
      } catch (err) {
        await showErrorDialog(err.message);
      }
    }
  };

  const handlerSubmitInfo = async () => {
    const ocrResponseStatus = await getIt(
      OnboardingApiServices,
    ).updateSessionInfo(
      session_id,
      {
        gst: {
          ocr_extract: { ...gst.ocr_extract },
          doc_identifier: gst.doc_identifier,
        },
      },
      token,
    );
    if (isLastStep) {
      navigate(`/onboarding/${session_id}/success`);
    } else {
      goToNextStep();
    }
  };

  return (
    <>
      <APForm control={gstFormControl} style={{ width: "100%" }}>
        <APGrid count={1} columnGap={"16px"} key={key}>
          <APFormFieldPDF
            label="GST"
            src={gst?.base64Image}
            onChange={handleImageUpload}
            validator={(file) => {
              if (!file) {
                return "Please upload a valid image";
              }
              return null;
            }}
          />
          <RenderOcrInfoStatus
            kycForm={gst?.ocr_extract || {}}
            onChange={(label: any, value: any) =>
              dispatch(
                updateGstForm({
                  ocr_extract: { ...gst.ocr_extract, ...{ [label]: value } },
                }),
              )
            }
          />
        </APGrid>
      </APForm>
      {errorMessage && <APTag variant="warning">{errorMessage}</APTag>}
      <APRow style={{ padding: "0px 16px 16px 16px" }}>
        <APExpanded>
          <APFormSubmit
            size="large"
            onClick={async () => {
              if (await gstFormControl.validate()) {
                try {
                  await handlerSubmitInfo();
                } catch (err: any) {
                  setErrorMessage(err.message);
                }
              }
            }}
          >
            Next
          </APFormSubmit>
        </APExpanded>
      </APRow>
    </>
  );
};

export default GstForm;
