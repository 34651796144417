import React from "react";
import { Box, Button, styled, useTheme } from "@mui/material";

interface AnimatedButtonProps {
  $progress: number;
}

const AnimatedButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "$progress",
})<AnimatedButtonProps>(({ theme, $progress }) => ({
  position: "relative",
  overflow: "hidden",
  borderRadius: "4px",
  background: theme.palette.primary.main,
  color: "white",
  "&:disabled": {
    background: theme.palette.grey[500],
    color: theme.palette.text.disabled,
  },
  "&::after": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: `${$progress}%`,
    background: "rgba(0, 0, 255, 0.5)",
    transition: "width 1s linear",
  },
  "& .MuiButton-label": {
    position: "relative",
    zIndex: 2,
    color: "white",
  },
}));

interface CountdownButtonProps {
  timeLeft: number;
  onClick: () => void;
  duration: number;
}

const CountdownButton: React.FC<CountdownButtonProps> = ({
  timeLeft,
  onClick,
  duration,
}) => {
  const progress =
    timeLeft > 0 ? ((duration - timeLeft / 1000) / duration) * 100 : 100;

  return (
    <Box
      style={{ textAlign: "center" }}
      mt={2}
      justifyContent={"space-between"}
      display={"flex"}
      alignItems={"center"}
    >
      <AnimatedButton
        variant="contained"
        disabled={timeLeft > 0}
        onClick={onClick}
        $progress={progress}
      >
        Resend
      </AnimatedButton>
      <Box ml={2} style={{ color: "black" }}>
        {timeLeft > 0 ? `${Math.ceil(timeLeft / 1000)}s` : ""}
      </Box>
    </Box>
  );
};

export default CountdownButton;
