import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const mutualFundSlice = createSlice({
  name: "mutualFund",
  initialState: {
    token:
      "eyJhbGciOiJSUzUxMiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxOTA4MjUiLCJleHAiOjE3MjEzNzkxMDYsImlzcyI6Imxtcy5jb2RlbW9kdWxvLmluIiwiYXVkIjoibG1zLmNvZGVtb2R1bG8uaW4ifQ.Zmc4ut-HNJGZ3inbQm3qjppJFUMVML-7FnllRrMrmn1mobJrsOjQ5zfn_zW8j_U3v4ipJr92RWAHgF8GOBLL1eHkmVDoB-wtYbDtMZCasakFXoGBBn62qD9P9KRdDyo1lsrhgVNhNY_cWq05i1hpaMfRhhdiYuihIFj5866Ix4rsawbhJWnSD7jU_k1XlFoV9QHt7QYEKc9xaQDAY-oKWjMjT-foRlDEKfFYJhwDoo4mwNv7feZHh2E0haIVtMobp4qQfTuNZ8SS3Z2O48Ls_WZuho5esHe3tNyBIbYaD8J4k-Hfv2DulagBFM3e_r0_bNpY3OwU6R18Q9M4PDjibUg05X35pU8nPAZY1LMHga8Ag_jH31qxYJ1lPY-ZAyC7B9PTO67aATTBwM7c-GSbi7_i0ewT6zQ3HWuMWPdmmhGsXWXWL5ZO0IF8oOBCs6dUyE6dRRl_S6cNiy6oiDPwC35Wm5bQAV7itJY63abdrguidv1_F29DhtRx2JRn9ukfN5owCjU9J6Gyz0Hv9XVOforrh3UpB0QGtMZnOOwl6afuL9ZAaiWIziGhD46QyWYZFHeSLFIkOh-mOxkgul2WAhmQO9p-R3YEyyjIuBsoMPHEe-FhXaPte0-unF-1-xoZ95ajRG1rcn7tS9hF4ofy_kdMizHNsNd73eRYhdSLkTk",
  },
  reducers: {},
});

export const {} = mutualFundSlice.actions;

export default mutualFundSlice.reducer;
