import React, { useState, useEffect } from "react";
import { Sheet } from "react-modal-sheet";
import { Typography, Divider } from "@mui/material";

interface AttentionModalProps {
  children: React.ReactNode;
  countdown: number;
  setCountdown: React.Dispatch<React.SetStateAction<number>>;
  title: string;
  onClose: () => void;
}

const AttentionModal: React.FC<AttentionModalProps> = ({
  children,
  countdown,
  setCountdown,
  title,
  onClose,
}) => {
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    if (countdown === 0) {
      handleClose();
      return;
    }
    const timer = setInterval(() => {
      setCountdown((prev) => prev - 1);
    }, 1000);
    return () => clearInterval(timer);
  }, [countdown, setCountdown]);

  const handleClose = () => {
    if (onClose) onClose();
  };

  if (!isOpen) return null;

  return (
    <Sheet
      isOpen={isOpen}
      onClose={handleClose}
      detent="content-height"
      disableDrag
    >
      <Sheet.Container>
        <Sheet.Header>
          <Typography textAlign={"center"} variant="h6" mt={4}>
            {title}
          </Typography>
          <Divider />
        </Sheet.Header>
        <Sheet.Content
          style={{ height: "50vh", marginTop: "1.5rem", marginLeft: "1rem" }}
        >
          {children}
        </Sheet.Content>
        <Sheet.Backdrop />
      </Sheet.Container>
      <Sheet.Backdrop />
    </Sheet>
  );
};

export default AttentionModal;
