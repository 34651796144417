import { useState } from 'react';

type AsyncFunction = (...args: any[]) => Promise<any>;

export const useLoadingWrapper = (
    asyncFunc: AsyncFunction
): [AsyncFunction, boolean] => {
    const [loading, setLoading] = useState<boolean>(false);

    const wrappedFunction: AsyncFunction = async (...args: any[]) => {
        setLoading(true);
        try {
            const result = await asyncFunc(...args);
            return result;
        } finally {
            setLoading(false);
        }
    };

    return [wrappedFunction, loading];
};