import React from "react";
import {Box, Typography, Button, Divider, Card, CardContent} from "@mui/material";
import {QRCodeSVG} from 'qrcode.react';
import {APColumn, APRow} from "../../codemod_components/layout/APFlex";
import {APPalette} from "../../codemod_components/utils/APPalette";
import {APInkWell} from "../../codemod_components/elements/APInkWell";
import {APIcon} from "../../codemod_components/icons/APIcon";
import {APCenter, APExpanded} from "../../codemod_components/layout/Common";
import {APText} from "../../codemod_components/elements/APText";
import {MediaCardDefault} from "../../assembled-components/MediaCard";
import {APScrollView} from "../../codemod_components/layout/APScrollView";
import {APCard} from "../../codemod_components/elements/APCard";
import APIconButton from "../../codemod_components/icons/APIconButton";

const PaymentScreen = () => {
    return (
        <APColumn>
            <APColumn
                style={{background: APPalette.white, paddingTop: "0.5rem"}}
                mainAxisSize="max"
                crossAxisAlignment="stretch"
            >
                <APColumn
                    style={{padding: "8px 16px"}}
                    gap="8px"
                    crossAxisAlignment="stretch"
                >
                    <APRow
                        mainAxisSize="max"
                        crossAxisAlignment="start"
                        mainAxisAlignment="center"
                    >
                        <APInkWell onClick={() => navigate("/")}>
                            <APIcon icon="backButton" size={20}/>
                        </APInkWell>
                        <APExpanded>
                            <APColumn>
                                <APText center variant="h3">
                                    Title
                                </APText>
                            </APColumn>
                        </APExpanded>
                    </APRow>
                </APColumn>
                <APColumn
                    mainAxisSize="min"
                    crossAxisAlignment="stretch"
                    style={{
                        backgroundColor: APPalette["grey-50"],
                    }}
                >
                    <APScrollView>
                        <APExpanded>
                            <APCard>
                                <APColumn  mainAxisSize="min" crossAxisAlignment="center">
                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                        <Typography variant="h6" component="div">
                                            MSG91
                                        </Typography>
                                        <Button variant="outlined" size="small">Razorpay Trusted Business</Button>
                                    </Box>

                                    {/* Offers */}
                                    <Box mt={2}>
                                        <Typography variant="body2" color="textSecondary">
                                            Upto ₹40 cashback on Amazon Pay Wallet
                                        </Typography>
                                    </Box>

                                    <Divider sx={{ my: 2 }} />

                                    {/* UPI QR */}
                                    <Box textAlign="center">
                                        <QRCodeSVG value="" size={150} />
                                        <Typography variant="caption" color="textSecondary" display="block" mt={1}>
                                            Scan the QR using any UPI app on your phone.
                                        </Typography>
                                        <Typography variant="caption" color="textSecondary">
                                            QR Code is valid for <span style={{ color: "red" }}>8:51</span> minutes
                                        </Typography>
                                    </Box>

                                    <Divider sx={{ my: 2 }} />

                                    {/* Payment Options */}
                                    <Typography variant="body1" gutterBottom>
                                        Netbanking, UPI & More
                                    </Typography>
                                    <Button fullWidth variant="outlined">Netbanking</Button>
                                    <Button fullWidth variant="outlined" sx={{ mt: 1 }}>
                                        UPI / QR
                                    </Button>

                                    {/* Footer */}
                                    <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
                                        <Typography variant="h6">₹ 1,180</Typography>
                                        <Button variant="contained" color="primary">Pay Now</Button>
                                    </Box>
                                </APColumn>
                            </APCard>
                        </APExpanded>
                    </APScrollView>
                </APColumn>
            </APColumn>
        </APColumn>
    );
};

export default PaymentScreen;
