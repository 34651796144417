import { APColumn, APRow } from "codemod_components/layout/APFlex";
import { APPalette } from "codemod_components/utils/APPalette";
import { APInkWell } from "codemod_components/elements/APInkWell";
import { APExpanded, APSizedBox } from "codemod_components/layout/Common";
import { APText } from "codemod_components/elements/APText";
import { APScrollView } from "codemod_components/layout/APScrollView";
import { MediaCardDefault } from "../../../assembled-components/MediaCard";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@reduxjs/toolkit/query";
import { APButton } from "codemod_components/elements/APButton";
import { APFormFieldText } from "codemod_components/forms/APFormFieldText";
import { REGEX } from "codemod_components/utils/REGEX";
import { showLoadingDialog } from "codemod_components/dialogs/showLoadingDialog";
import { getIt } from "codemod_components/dialogs/DialogHelper";
import FinacuityLoanApplicationApiServices from "../../../requests/LoanapplyRequests";
import { APIcon } from "../../../codemod_components/icons/APIcon";
import { APJourneyApp } from "../../../codemod_components/stepper_app/APJourneyApp";
import DirectorDetailForm from "./forms/directorDetailForm";
import GstDetailExtractForm from "./forms/gstDetailExtractForm";
import BankStatementForm from "./forms/bankStatementForm";
import { showErrorDialog } from "../../../codemod_components/dialogs/showErrorDialog";
import LoanApplicationWrapperContainer from "../LoanApplicationWrapperContainer";

const BusinessLoanApplicationForm = ({ kybForm, setKybForm }) => {
  const triggerGstPull = async (gstin: string) => {
    if (REGEX.GST.test(gstin)) {
      const { data, error, status_code } = await showLoadingDialog(
        async () =>
          await getIt(
            FinacuityLoanApplicationApiServices,
          ).getBasicGstDetailExtract(gstin),
        "Fetching GST!",
      );
      if (status_code === 0) {
        setKybForm((prevState) => ({
          ...prevState,
          ...{ application: data?.application, gst_details: data?.gst },
        }));
      } else {
        await showErrorDialog(error?.message);
      }
    }
  };
  const handleCompletion = () => {};

  const getSteps = useMemo(() => {
    return [
      {
        title: "Bank Statement",
        description: "Please upload the bank statement for Last 6 Months.",
        form: BankStatementForm,
        formProps: { kybForm, setKybForm },
      },
      {
        title: "Enter Director's Details",
        description: "Depending on the share pattern, Enter the Info",
        form: DirectorDetailForm,
        formProps: { kybForm, setKybForm },
      },
      {
        title: "Enter GST Details",
        description: "Please Enter the UserName of the GST portal",
        form: GstDetailExtractForm,
        formProps: { kybForm, setKybForm },
      },
    ];
  }, [kybForm]);

  return (
    <>
      <APExpanded>
        <APColumn>
          {kybForm?.gst_details?.gstnDetailed?.legalNameOfBusiness ? (
            <APRow
              style={{ height: "40px" }}
              crossAxisAlignment="center"
              mainAxisAlignment="center"
            >
              <APText color={APPalette["grey-700"]} variant={"h3"} maxLines={1}>
                {kybForm?.gst_details?.gstnDetailed?.legalNameOfBusiness}
              </APText>
            </APRow>
          ) : (
            <>
              <APText variant="h2">
                <strong>Documents Required</strong>
              </APText>
              <br />
              <APColumn crossAxisAlignment="start" gap="12px">
                <APText variant="paragraph-medium">
                  <br />
                  <strong>Bank Statement</strong> : Bank Statement for last 6
                  months
                  <br />
                  <strong>Directors Detail</strong>: Director Mobile number and
                  Pan Number
                  <br />
                </APText>
              </APColumn>
              <APSizedBox height="12px" />
              <APFormFieldText
                disabled={Boolean(
                  kybForm?.gst_details?.gstnDetailed?.legalNameOfBusiness,
                )}
                style={{ width: "100%" }}
                textAppearance={"uppercase"}
                label="Enter your GST Number"
                initialValue={kybForm.gstin}
                onChanged={async (value) => {
                  setKybForm((prev) => ({ ...prev, ...{ gstin: value } }));
                }}
                validator={(value) => {
                  if (!value || REGEX.GST.test(value) == false) {
                    return "Please enter a valid GST Number";
                  }
                  return null;
                }}
              />
              <APSizedBox height="12px" />
              <APColumn>
                <APRow>
                  <APText variant="button-small">
                    By Clicking Next, you authorize MoneyEase to pull GST
                    Fillings and sales figures for last 1 year, to process your
                    business loan application.
                  </APText>
                </APRow>
              </APColumn>
            </>
          )}
          <APSizedBox height="16px" />

          {kybForm?.gst_details?.gstnDetailed?.legalNameOfBusiness ? (
            <APJourneyApp
              style={{ width: "100%", height: "100%" }}
              mode="linear"
              title={""}
              description={""}
              getSteps={getSteps}
              handleCompletion={handleCompletion}
            />
          ) : (
            <APButton
              disabled={Boolean(REGEX.GST.test(kybForm?.gstin || "") == false)}
              style={{ width: "100%" }}
              onClick={() => triggerGstPull(kybForm?.gstin)}
            >
              Next
            </APButton>
          )}
        </APColumn>
      </APExpanded>
    </>
  );
};

const ApplyBusinessLoan = () => {
  // @ts-ignore
  const { user, applications } = useSelector(
    (state: RootState<any, any, any>) => state.authenticationSlice,
  );
  let navigate = useNavigate();
  let [errorMessage, setErrorMessage] = useState<string | undefined>();
  let [kybForm, setKybForm] = useState({
    consent: false,
    gstin: "",
    application: null,
    gst_details: null,
  });

  return (
    <>
      <LoanApplicationWrapperContainer
        headerTitle={"Business Loan"}
        productCode={"loan:business"}
      >
        <APColumn
          mainAxisSize="min"
          crossAxisAlignment="stretch"
          style={{
            padding: "16px",
            backgroundColor: APPalette["grey-50"],
          }}
        >
          <BusinessLoanApplicationForm
            kybForm={kybForm}
            setKybForm={setKybForm}
          />
        </APColumn>
      </LoanApplicationWrapperContainer>
    </>
  );
};

export default ApplyBusinessLoan;
