import React from "react";
import { APFormControl } from "codemod_components/forms/useAPForm";

export const APFormContext = React.createContext<APFormControl | undefined>(
  undefined,
);

export function APForm(props: {
  control: APFormControl;
  children: any;
  style?: React.CSSProperties;
}) {
  return (
    <APFormContext.Provider value={props.control}>
      <form
        onSubmit={(event) => {
          props.control.submit();
          event?.preventDefault();
        }}
        style={props.style}
      >
        {props.children}
      </form>
    </APFormContext.Provider>
  );
}
