import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import partnerSlice from "./slices/productSlice";
import authenticationSlice from "./slices/authenticationSlice";
import onboardingSlice from "./slices/onboardingSlice";
import mutualFundSlice from "./slices/mutualFundSlice";
import personalSlice from "./slices/personalSlice";

const rootReducer = combineReducers({
  authenticationSlice: authenticationSlice,
  partnerSlice: partnerSlice,
  onboardingSlice: onboardingSlice,
  mutualFundSlice: mutualFundSlice,
  personalSlice: personalSlice,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: true,
});

export function ReduxStoreProvider({ children }) {
  return <Provider store={store}>{children}</Provider>;
}
