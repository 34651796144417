import GppGoodIcon from "@mui/icons-material/GppGood";
import { Stack, Typography } from "@mui/material";
import React from "react";
import { APStack } from "codemod_components/layout/APStack";

const OnboardingFooter = ({ partnerLogo }) => {
  return (
    <APStack
      style={{
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
        display: "flex",
        margin: "0.5rem",
      }}
    >
      <GppGoodIcon
        color="success"
        style={{ width: "2.2rem", height: "2.2rem" }}
      />
      <Typography
        marginTop={"0.2rem"}
        marginRight={"1.1rem"}
        marginLeft={"-0.7rem"}
        bgcolor={"green"}
        borderRadius={"0rem 0.5rem 0.5rem 0rem"}
        color={"white"}
        height={"1.2rem"}
        width={"5rem"}
        textAlign={"center"}
        variant="caption"
      >
        Secured By
      </Typography>
      <img
        src={partnerLogo}
        alt="Logo"
        style={{ width: "10rem", height: "2rem" }}
      />
    </APStack>
  );
};

export default OnboardingFooter;
