import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import React from "react";
import { showDialog } from "codemod_components/dialogs/DialogHelper";
import { sleep } from "codemod_components/hooks/FilePicker/utils";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import WarningOutlinedIcon from "@mui/icons-material/WarningOutlined";

type Severity = "positive" | "negative" | "info" | "warning";

export async function showSnackbar(content: string, severity?: Severity) {
  let newSeverity: "success" | "info" | "warning" | "error";
  switch (severity) {
    case "positive":
      newSeverity = "success";
      break;
    case "negative":
      newSeverity = "error";
      break;
    case "info":
      newSeverity = "info";
      break;
    case "warning":
      newSeverity = "warning";
      break;
    default:
      newSeverity = "success";
      break;
  }

  var close = showDialog(
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={true}
      autoHideDuration={3000}
    >
      <Alert
        severity={newSeverity}
        onClose={() => close()}
        iconMapping={{
          error: <InternalSVGImage name="error-snackbar" />,
          success: <InternalSVGImage name="check-snackbar" />,
          info: <InternalSVGImage name="info-snackbar" />,
          warning: <InternalSVGImage name="warning-snackbar" />,
        }}
      >
        {content}
      </Alert>
    </Snackbar>,
  );
  await sleep(3000);
  close();
}

export function InternalSVGImage({
  name,
  size = 20,
  style,
}: {
  name: string;
  size?: number;
  style?: React.CSSProperties;
}) {
  switch (name) {
    case "check-snackbar":
      return (
        <CheckCircleOutlineIcon
          color="success"
          style={{ fontSize: size, ...style }}
        />
      );
    case "error-snackbar":
      return (
        <ErrorOutlineIcon color="error" style={{ fontSize: size, ...style }} />
      );
    case "info-snackbar":
      return (
        <InfoOutlinedIcon color="info" style={{ fontSize: size, ...style }} />
      );
    case "warning-snackbar":
      return (
        <WarningOutlinedIcon
          color="warning"
          style={{ fontSize: size, ...style }}
        />
      );
    default:
      return null;
  }
}
