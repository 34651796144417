import { AxiosInstance } from "axios";
import { singleton } from "tsyringe";
import authenticatedHttp from "./baseHttp";
import { BASE_CONFIG } from "../config";

@singleton()
export default class FinacuityLoanApplicationApiServices {
  instance: AxiosInstance = authenticatedHttp;
  baseUrl: string = BASE_CONFIG.BASE_URL;

  async getLamfLoanApplicationOtp(payload: object) {
    let res = await this.instance.post(
      `${this.baseUrl}/vapi/v1/apply/lamf/otp`,
      payload,
    );
    return res.data;
  }
  async generatePreSignedUrl(session_id, doc_type) {
    let res = await this.instance.request({
      method: "POST",
      url: `${this.baseUrl}/vapi/v1/ocr/upload/${session_id}/${doc_type}`,
    });
    return res.data;
  }
  async performExecuteActionOnDocument(session_id, doc_type) {
    let res = await this.instance.request({
      method: "POST",
      url: `${this.baseUrl}/vapi/v1/ocr/v2/execute/${session_id}/${doc_type}`,
    });
    return res.data;
  }
  async submitLamfLoanApplicationOtp(payload: object) {
    let res = await this.instance.post(
      `${this.baseUrl}/vapi/v1/apply/lamf/submit/otp`,
      payload,
    );
    return res.data;
  }
  async getLamfLoanApplicationEligibility(applicationId: string) {
    let res = await this.instance.post(
      `${this.baseUrl}/vapi/v1/apply/lamf/eligibility/${applicationId}`,
    );
    return res.data;
  }
  async getLamfLoanApplicationRedirectUri(applicationId: string) {
    let res = await this.instance.post(
      `${this.baseUrl}/vapi/v1/apply/lamf/apply/${applicationId}`,
    );
    return res.data;
  }
  async getLoanApplications() {
    let res = await this.instance.get(
      `${this.baseUrl}/vapi/v1/apply/applications`,
    );
    return res.data;
  }
  async getBasicGstDetailExtract(gstin: string) {
    let res = await this.instance.post(
      `${this.baseUrl}/vapi/v1/apply/business/application/${gstin}`,
    );
    return res.data;
  }
  async checkAndTriggerGstDetailedReport(
    gstin: string,
    username: string,
    password: string,
  ) {
    let res = await this.instance.post(
      `${this.baseUrl}/vapi/v1/apply/business/gst/password`,
      {
        gstin,
        gst_user_name: username,
        password,
      },
    );
    return res.data;
  }
  async getEligibility() {
    let res = await this.instance.post(
      `${this.baseUrl}/vapi/v1/apply/business/eligibility`,
      {},
    );
    return res.data;
  }
  async getDirectorDetailedBureauReport(
    application_id: string,
    pan: string,
    mobile: string,
    email: string,
  ) {
    let res = await this.instance.post(
      `${this.baseUrl}/vapi/v1/apply/business/experian/${application_id}/director`,
      {
        pan,
        mobile,
        email,
      },
    );
    return res.data;
  }
  async generateGstPortalAccessOtp(
    application_id: string,
    gstin: string,
    portal_user_name: string,
  ) {
    let res = await this.instance.post(
      `${this.baseUrl}/vapi/v1/apply/business/gst/${application_id}/otp/generate`,
      {
        gstin,
        gst_user_name: portal_user_name,
      },
    );
    return res.data;
  }

  async getPersoanlLoanApplicationOtp(payload: object) {
    let res = await this.instance.post(
      `${this.baseUrl}/vapi/v1/apply/personal/otp`,
      payload,
    );
    return res.data;
  }

  async submitPersonalLoanApplicationOtp(payload: object) {
    let res = await this.instance.post(
      `${this.baseUrl}/vapi/v1/apply/personal/submit-otp`,
      payload,
    );
    return res.data;
  }

  async cinLookUp(value) {
    let res = await this.instance.post(
      `${this.baseUrl}/vapi/v1/apply/cin-look-up/${value}`,
    );
    return res.data;
  }

  async getUploadStatement(endpoint, payload: object) {
    let res = await this.instance.post(
      `${this.baseUrl}/vapi/v1/apply/${endpoint}`,
      payload,
    );
    return res.data;
  }
}
