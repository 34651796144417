import Clear from "@mui/icons-material/Clear";
import CloseRounded from "@mui/icons-material/CloseRounded";
import { useEffect, useState } from "react";

import { useWindowDimensions } from "codemod_components/hooks/useWindowDimension";

import ImageCropDialog from "codemod_components/helpers/ImageCropDialog";
import {
  APCenter,
  APExpanded,
  APSizedBox,
} from "codemod_components/layout/Common";
import { APAlign, APStack } from "codemod_components/layout/APStack";
import { APColumn, APRow } from "codemod_components/layout/APFlex";
import { APInkWell } from "codemod_components/elements/APInkWell";
import { APPalette } from "codemod_components/utils/APPalette";
import { useFilePicker } from "codemod_components/hooks/FilePicker/useFilePicker";
import { launchDialog } from "codemod_components/dialogs/DialogHelper";
import { handleErrorMessage } from "codemod_components/utils/handleErrorMessage";
import { APImage } from "codemod_components/elements/APImage";
import { APText } from "codemod_components/elements/APText";
import { APButton } from "codemod_components/elements/APButton";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

export default function ShowImage({
  src,
  alt,
  handleRemove,
  upload,
  error,
  label,
  padding,
  margin,
  aspectRatio,
  variant,
  accept,
  ref,
  disabled = false,
}: {
  src?: string;
  alt?: string;
  handleRemove: () => void;
  upload: (src: string, file: File) => void;
  error: string | null;
  label?: string;
  padding?: string;
  margin?: string;
  aspectRatio?: number;
  variant: "default" | "avatar";
  accept?: string;
  ref?: any;
  disabled?: boolean;
}) {
  const [hover, setHover] = useState(false);
  const windowDimensions = useWindowDimensions();

  if (!src) {
    return (
      <ImageContainer error={error} padding={padding} margin={margin} ref={ref}>
        <UploadImageWithLabel
          label={label}
          hover={setHover}
          onChange={upload}
          aspectRatio={aspectRatio}
          variant={variant}
          accept={accept}
          disabled={disabled}
        />
      </ImageContainer>
    );
  }

  if (windowDimensions.width && windowDimensions.width <= 600) {
    return (
      <ImageContainer error={error} padding={padding} margin={margin} ref={ref}>
        <APExpanded>
          <APStack style={{ height: "100%", width: "100%" }}>
            <APAlign align="fill">
              <APRow mainAxisAlignment="center">
                <img
                  src={src}
                  alt={alt}
                  style={{ maxHeight: "140px", maxWidth: "100%" }}
                />
              </APRow>
            </APAlign>
            <APAlign align="topRight">
              <APCenter
                style={{
                  backgroundColor: "pink",
                  border: "2px solid grey",
                  top: "-15px",
                  right: "-15px",
                  borderRadius: "100%",
                  height: "28px",
                  width: "28px",
                  cursor: disabled ? "not-allowed" : "pointer",
                }}
              >
                <CloseRounded
                  htmlColor="grey"
                  fontSize="inherit"
                  onClick={disabled ? undefined : handleRemove}
                  style={{ padding: "0px" }}
                />
              </APCenter>
            </APAlign>
          </APStack>
        </APExpanded>
      </ImageContainer>
    );
  }

  return (
    <div
      onMouseEnter={() => !disabled && setHover(true)}
      onMouseLeave={() => !disabled && setHover(false)}
      ref={ref}
    >
      {hover ? (
        // <ImageContainer error={error} padding={padding} margin={margin}>
        //     <APExpanded>
        //         <APStack style={{ height: "100%", width: "100%" }}>
        //             <APAlign align="fill">
        //                 <APRow mainAxisAlignment="center">
        //                     <img src={src} alt={alt} style={{ maxHeight: "140px", maxWidth: "100%" }} />
        //                 </APRow>
        //             </APAlign>
        //             <APAlign align="center">
        //                 <APRow crossAxisAlignment="center" mainAxisSize="min" style={{ padding: "6px 10px", borderRadius: "20px", background: "white", fontWeight: 600 }} onClick={handleRemove}>
        //                     <Clear />
        //                     <APSizedBox width="12px" />
        //                     <div>{`Remove ${label}`}</div>
        //                     <APSizedBox width="8px" />
        //                 </APRow>
        //             </APAlign>
        //         </APStack>
        //     </APExpanded>
        // </ImageContainer>
        <ImageContainer error={error} padding={padding} margin={margin}>
          {variant === "avatar" ? (
            <AvatarWrapper src={src} alt={alt} size="140px" visibility={0.5} />
          ) : (
            <img
              src={src}
              alt={alt}
              style={{ maxHeight: "140px", maxWidth: "100%", opacity: "0.5" }}
            />
          )}
          <div style={{ position: "relative" }}>
            <APCenter>
              <APAlign align="center">
                <APInkWell onClick={disabled ? undefined : handleRemove}>
                  <APRow
                    crossAxisAlignment="center"
                    mainAxisSize="min"
                    style={{
                      padding: "6px 10px",
                      borderRadius: "20px",
                      background: "white",
                      fontWeight: 600,
                      marginTop: "-70%",
                      cursor: disabled ? "not-allowed" : "pointer",
                    }}
                  >
                    <Clear />
                    <APSizedBox width="12px" />
                    <div>Remove</div>
                    <APSizedBox width="8px" />
                  </APRow>
                </APInkWell>
              </APAlign>
            </APCenter>
          </div>
        </ImageContainer>
      ) : (
        <ImageContainer error={error} padding={padding} margin={margin}>
          {variant === "avatar" ? (
            <AvatarWrapper src={src} alt={alt} size="140px" />
          ) : (
            <img
              src={src}
              alt={alt}
              style={{ maxHeight: "140px", maxWidth: "100%" }}
            />
          )}
        </ImageContainer>
      )}
    </div>
  );
}

export function ImageContainer({
  children,
  error,
  padding = "12px",
  margin = "8px 0 0",
  ref,
}: {
  children: any;
  error: string | null;
  padding?: string;
  margin?: string;
  ref?: any;
}) {
  return (
    <APColumn style={{ padding }}>
      <APExpanded>
        <APColumn
          mainAxisSize="max"
          mainAxisAlignment="center"
          crossAxisAlignment="center"
          style={{
            border: `1px dashed ${error !== null ? "#ff1744" : "#888"}`,
            height: "148px",
            // background: "#eee",
            minHeight: "148px",
            maxHeight: "148px",
            width: "100%",
            margin,
            borderRadius: "8px",
            background: APPalette["grey-50"],
          }}
        >
          {children}
        </APColumn>
      </APExpanded>
      <div
        ref={ref}
        style={{
          color: "#ff1744",
          margin: "3px 14px 0",
          fontSize: "0.75rem",
          lineHeight: 1.66,
          letterSpacing: "0.0333em",
        }}
      >
        {error !== null && error}
      </div>
    </APColumn>
  );
}

export function UploadImageWithLabel({
  onChange,
  hover,
  label,
  aspectRatio,
  variant,
  accept = "image/*",
  disabled = false,
}: {
  onChange: (base64: string, file: File) => void;
  hover: (isHover: boolean) => void;
  label?: string;
  aspectRatio?: number;
  variant?: string;
  accept?: string;
  disabled?: boolean;
}) {
  var { files, onClick, HiddenFileInput, reset } = useFilePicker();
  var file: File | null = null;
  if (files) file = files[0];

  useEffect(() => {
    if (file) {
      getImageUrl(file);
    }
  }, [file]);

  async function getImageUrl(selectedFile: File) {
    try {
      var base64 = (await convertBase64(selectedFile)) as string;
      var croppedCanvas = await launchDialog<HTMLCanvasElement>(
        (deferred) => (
          <ImageCropDialog
            deferred={deferred}
            base64={base64}
            aspectRatio={aspectRatio}
            variant={variant}
          />
        ),
        { disableClose: true, fullScreen: true },
      );

      const croppedBase64 = croppedCanvas.toDataURL(selectedFile.type);
      const croppedBlob: Blob = await new Promise((resolve) => {
        croppedCanvas.toBlob(
          (blob) => {
            resolve(blob as Blob);
          },
          selectedFile.type,
          0.5,
        );
      });
      const croppedFile = new File([croppedBlob], selectedFile.name, {
        type: selectedFile.type,
      });

      onChange(croppedBase64, croppedFile);
      hover(false);
      reset();
    } catch (error) {
      handleErrorMessage(error);
    }
  }

  return (
    <APInkWell onClick={disabled ? undefined : onClick}>
      <APColumn>
        <CloudUploadIcon />
        <APSizedBox height="16px" />
        <APText variant="paragraph-small" center color={APPalette["grey-700"]}>
          {`Upload ${label}`}
        </APText>
        <HiddenFileInput accept={accept} disabled={disabled} />
      </APColumn>
    </APInkWell>
  );
}

const getBase64 = (file: Blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export async function convertBase64(file: Blob) {
  return await getBase64(file);
}

export function UploadImageButton({
  onChange,
  aspectRatio,
  variant,
  accept = "images/*",
  label,
  disabled = false,
}: {
  onChange: (src?: string, file?: File) => void;
  aspectRatio?: number;
  variant: "default" | "avatar";
  accept?: string;
  label?: string;
  disabled?: boolean;
}) {
  var { files, onClick, HiddenFileInput, reset } = useFilePicker();
  var file: File | null = null;
  if (files) file = files[0];

  useEffect(() => {
    if (file) {
      getImageUrl(file);
    }
  }, [file]);

  async function getImageUrl(selectedFile: File) {
    try {
      var base64 = (await convertBase64(selectedFile)) as string;
      var croppedCanvas = await launchDialog<HTMLCanvasElement>(
        (deferred) => (
          <ImageCropDialog
            deferred={deferred}
            base64={base64}
            aspectRatio={aspectRatio}
            variant={variant}
          />
        ),
        { disableClose: true, fullScreen: true },
      );

      const croppedBase64 = croppedCanvas.toDataURL(selectedFile.type);
      const croppedBlob: Blob = await new Promise((resolve) => {
        croppedCanvas.toBlob(
          (blob) => {
            resolve(blob as Blob);
          },
          selectedFile.type,
          0.5,
        );
      });
      const croppedFile = new File([croppedBlob], selectedFile.name, {
        type: selectedFile.type,
      });

      onChange(croppedBase64, croppedFile);
      reset();
    } catch (error) {
      handleErrorMessage(error);
    }
  }

  return (
    <APButton onClick={onClick}>
      {label}
      <HiddenFileInput accept={accept} disabled={disabled} />
    </APButton>
  );
}

function AvatarWrapper({
  src,
  alt,
  size = "140px",
  visibility = 1,
}: {
  src: string;
  alt?: string;
  size?: string;
  visibility?: number;
}) {
  return (
    <APColumn
      mainAxisAlignment="center"
      crossAxisAlignment="center"
      style={{
        height: size,
        width: size,
        borderRadius: "50%",
        overflow: "hidden",
      }}
    >
      <img
        src={src}
        alt={alt}
        style={{
          maxHeight: "140px",
          maxWidth: "100%",
          opacity: visibility,
        }}
      />
    </APColumn>
  );
}
