import { ConfirmationDialog } from "codemod_components/dialogs/ConfirmationDialog";
import { launchDialog } from "codemod_components/dialogs/DialogHelper";

export async function showConfirmationDialog(
  title: string,
  body: string,
  yesAction?: string,
  noAction?: string,
): Promise<boolean> {
  try {
    var result = await launchDialog<boolean>((deferred) => (
      <ConfirmationDialog
        deferred={deferred}
        title={title}
        body={body}
        yesAction={yesAction}
        noAction={noAction}
      />
    ));
    return result;
  } catch (error) {
    return false;
  }
}
