import { useState } from "react";
import { customAlphabet } from "nanoid";

const nanoid = customAlphabet("1234567890abcdef", 10);

export function useForceUpdate() {
  const [value, setValue] = useState(false); // integer state
  return () => setValue((value) => !value); // update the state to force render
}

export function useForceRender() {
  const [value, setValue] = useState(() => nanoid());
  return { key: value, reload: () => setValue(nanoid()) };
}
