import Clear from "@mui/icons-material/Clear";
import CloseRounded from "@mui/icons-material/CloseRounded";
import { useEffect, useState } from "react";
import { useWindowDimensions } from "codemod_components/hooks/useWindowDimension";
import {
  APCenter,
  APExpanded,
  APSizedBox,
} from "codemod_components/layout/Common";
import { APAlign, APStack } from "codemod_components/layout/APStack";
import { APColumn, APRow } from "codemod_components/layout/APFlex";
import { APInkWell } from "codemod_components/elements/APInkWell";
import { APPalette } from "codemod_components/utils/APPalette";
import { handleErrorMessage } from "codemod_components/utils/handleErrorMessage";
import { APText } from "codemod_components/elements/APText";
import { APButton } from "codemod_components/elements/APButton";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useFilePickerPDF } from "codemod_components/hooks/FilePicker/useFilePickerPDF";

export default function ShowPdf({
  src,
  alt,
  handleRemove,
  upload,
  error,
  label,
  padding,
  margin,
  ref,
  variant,
  accept,
}: {
  src?: string;
  alt?: string;
  handleRemove: () => void;
  upload: (src: string, file: File) => void;
  error: string | null;
  label?: string;
  padding?: string;
  margin?: string;
  ref?: any;
  variant: "default" | "avatar";
  accept?: string;
}) {
  const [hover, setHover] = useState(false);
  const windowDimensions = useWindowDimensions();

  if (!src) {
    return (
      <PdfContainer error={error} padding={padding} margin={margin} ref={ref}>
        <UploadPdfWithLabel
          label={label}
          hover={setHover}
          onChange={upload}
          accept={accept}
          variant={variant}
        />
      </PdfContainer>
    );
  }

  if (windowDimensions.width && windowDimensions.width <= 600) {
    return (
      <PdfContainer error={error} padding={padding} margin={margin} ref={ref}>
        <APExpanded>
          <APStack style={{ height: "100%", width: "100%" }}>
            <APAlign align="fill">
              <APRow mainAxisAlignment="center">
                <embed
                  src={src}
                  type="application/pdf"
                  style={{ maxHeight: "140px", maxWidth: "100%" }}
                />
              </APRow>
            </APAlign>
            <APAlign align="topRight">
              <APCenter
                style={{
                  backgroundColor: "pink",
                  border: "2px solid grey",
                  top: "-15px",
                  right: "-15px",
                  borderRadius: "100%",
                  height: "28px",
                  width: "28px",
                }}
              >
                <CloseRounded
                  htmlColor="grey"
                  fontSize="inherit"
                  onClick={handleRemove}
                  style={{ padding: "0px" }}
                />
              </APCenter>
            </APAlign>
          </APStack>
        </APExpanded>
      </PdfContainer>
    );
  }

  return (
    <div
      onMouseEnter={(e) => setHover(true)}
      onMouseLeave={(e) => setHover(false)}
      ref={ref}
    >
      {hover ? (
        <PdfContainer error={error} padding={padding} margin={margin}>
          <embed
            src={src}
            type="application/pdf"
            style={{ maxHeight: "140px", maxWidth: "100%", opacity: "0.5" }}
          />
          <div style={{ position: "relative" }}>
            <APCenter>
              <APAlign align="center">
                <APInkWell onClick={handleRemove}>
                  <APRow
                    crossAxisAlignment="center"
                    mainAxisSize="min"
                    style={{
                      padding: "6px 10px",
                      borderRadius: "20px",
                      background: "white",
                      fontWeight: 600,
                      marginTop: "-70%",
                    }}
                  >
                    <Clear />
                    <APSizedBox width="12px" />
                    <div>Remove</div>
                    <APSizedBox width="8px" />
                  </APRow>
                </APInkWell>
              </APAlign>
            </APCenter>
          </div>
        </PdfContainer>
      ) : (
        <PdfContainer error={error} padding={padding} margin={margin}>
          <embed
            src={src}
            type="application/pdf"
            style={{ maxHeight: "140px", maxWidth: "100%" }}
          />
        </PdfContainer>
      )}
    </div>
  );
}

export function PdfContainer({
  children,
  error,
  padding = "12px",
  margin = "8px 0 0",
  ref,
}: {
  children: any;
  error: string | null;
  padding?: string;
  margin?: string;
  ref?: any;
}) {
  return (
    <APColumn style={{ padding }}>
      <APExpanded>
        <APColumn
          mainAxisSize="max"
          mainAxisAlignment="center"
          crossAxisAlignment="center"
          style={{
            border: `1px dashed ${error !== null ? "#ff1744" : "#888"}`,
            height: "148px",
            minHeight: "148px",
            maxHeight: "148px",
            width: "100%",
            margin,
            borderRadius: "8px",
            background: APPalette["grey-50"],
          }}
        >
          {children}
        </APColumn>
      </APExpanded>
      <div
        ref={ref}
        style={{
          color: "#ff1744",
          margin: "3px 14px 0",
          fontSize: "0.75rem",
          lineHeight: 1.66,
          letterSpacing: "0.0333em",
        }}
      >
        {error !== null && error}
      </div>
    </APColumn>
  );
}

export function UploadPdfWithLabel({
  onChange,
  hover,
  label,
  variant,
  accept = "application/pdf",
}: {
  onChange: (base64: string, file: File) => void;
  hover: (isHover: boolean) => void;
  label?: string;
  variant?: string;
  accept?: string;
}) {
  const { files, onClick, HiddenFileInput, reset } = useFilePickerPDF({
    maxFileSize: 10,
  });
  let file: File | null = null;
  if (files) file = files[0];

  useEffect(() => {
    if (file) {
      getPdfUrl(file);
    }
  }, [file]);

  async function getPdfUrl(selectedFile: File) {
    try {
      const base64 = (await convertBase64(selectedFile)) as string;
      onChange(base64, selectedFile);
      hover(false);
      reset();
    } catch (error) {
      handleErrorMessage(error);
    }
  }

  return (
    <APInkWell onClick={onClick}>
      <APColumn>
        <CloudUploadIcon />
        <APSizedBox height="16px" />
        <APText variant="paragraph-small" center color={APPalette["grey-700"]}>
          {`Upload ${label}`}
        </APText>
        <HiddenFileInput accept={accept || "application/pdf"} />
      </APColumn>
    </APInkWell>
  );
}

const getBase64 = (file: Blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export async function convertBase64(file: Blob) {
  try {
    return await getBase64(file);
  } catch (error) {
    console.error("Error converting to Base64:", error);
    throw error;
  }
}

export function UploadPdfButton({
  onChange,
  accept = "application/pdf",
  label,
}: {
  onChange: (src?: string, file?: File) => void;
  accept?: string;
  label?: string;
}) {
  const { files, onClick, HiddenFileInput, reset } = useFilePickerPDF({
    maxFileSize: 10,
  });
  let file: File | null = null;
  if (files) file = files[0];

  useEffect(() => {
    if (file) {
      getPdfUrl(file);
    }
  }, [file]);

  async function getPdfUrl(selectedFile: File) {
    try {
      const base64 = (await convertBase64(selectedFile)) as string;
      onChange(base64, selectedFile);
      reset();
    } catch (error) {
      handleErrorMessage(error);
    }
  }

  return (
    <APButton onClick={onClick}>
      {label}
      <HiddenFileInput accept={accept} />
    </APButton>
  );
}
