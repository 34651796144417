import { useContext, useEffect, useRef, useState } from "react";
import { APFormContext } from "./APForm";
import { APFormControl, APFormFieldItem } from "./useAPForm";
import { showConfirmationDialog } from "codemod_components/dialogs/showConfirmationDialog";
import ShowPDFFile from "codemod_components/helpers/ShowPdf";

export interface APFormFieldPDFProps {
  control?: APFormControl;
  validator?: (src?: string) => string | null | Promise<string | null>;
  onChange?: (src?: string, file?: File) => void;
  src?: string;
  label?: string;
  alt?: string;
  padding?: string;
  margin?: string;
  variant?: "default" | "avatar";
  accept?: string;
}

export function APFormFieldPDF({
  src,
  control,
  validator,
  onChange,
  label,
  alt,
  padding,
  margin,
  variant = "default",
  accept,
}: APFormFieldPDFProps) {
  let inputRef = useRef<any>(null);

  const [base64String, setBase64String] = useState<string | undefined>(src);
  const [error, setError] = useState<string | null>(null);
  const [selectedFile, setSelectedFile] = useState<File>();

  var control2: APFormControl | undefined = useContext(APFormContext);

  if (control !== undefined) {
    control2 = control;
  }

  async function checkValues(): Promise<boolean> {
    var errorMessage = null;
    if (validator) errorMessage = await validator(base64String);
    setError(errorMessage);
    if (errorMessage) {
      inputRef.current?.scrollIntoView?.({
        behavior: "smooth",
        block: "center",
      });
    }
    return !errorMessage;
  }

  var fieldItem: APFormFieldItem = {
    validate: checkValues,
    reset: () => {
      setBase64String("");
      setSelectedFile(undefined);
    },
  };

  useEffect(() => {
    if (control2 !== undefined) control2.fields.add(fieldItem);
    return () => {
      if (control2 !== undefined) control2.fields.delete(fieldItem);
    };
  }, []);

  useEffect(() => {
    (async () => {
      if (validator && error !== null) setError(await validator(base64String));
      if (onChange) onChange(base64String, selectedFile!);
    })();
  }, [base64String]);

  return (
    <ShowPDFFile
      handleRemove={async () => {
        try {
          if (
            await showConfirmationDialog(
              `Remove ${label}`,
              `Are you sure you want to remove ${label}?`,
            )
          ) {
            setBase64String("");
            setSelectedFile(undefined);
          }
        } catch (error) {}
      }}
      ref={inputRef}
      upload={(base64, file) => {
        setBase64String(base64);
        setSelectedFile(file);
      }}
      src={base64String}
      alt={alt}
      error={error}
      label={label}
      padding={padding}
      margin={margin}
      variant={variant}
      accept={accept}
    />
  );
}
