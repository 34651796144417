import { showErrorDialog } from "codemod_components/dialogs/showErrorDialog";

export function handleErrorMessage(error: unknown) {
  let errorMessage = "Failed to do something exceptional";
  if (error instanceof Error) {
    errorMessage = error.message;
  }

  showErrorDialog(errorMessage);
}
