import React from "react";

export interface APImageProps {
  /**
   * accepts image url or base64 string of the image
   */
  src: string;
  /**
   * max height of the image
   */
  height?: string | number;
  /**
   * max width of the image
   */
  width?: string | number;
  /**
   * string that appear if the image is not loaded, SEO
   */
  alt?: string;
  style?: React.CSSProperties;
  /**
   * fits the image in the provided space by the parent component
   */
  fit?: boolean;
  onClick?: () => void;
  /**
   * @default default
   */
  variant?: "default" | "avatar";

  allowCors?: boolean;

  /**
   * @default false
   * If true, hides the broken image
   */
  hideBrokenImage?: boolean;

  imageFit?: "contain" | "cover" | "fill" | "none" | "scale-down";
}

export function APImage({
  src,
  height,
  width,
  alt,
  style = {},
  fit,
  onClick,
  variant = "default",
  allowCors = false,
  hideBrokenImage = false,
  imageFit,
}: APImageProps) {
  if (onClick) {
    style.cursor = "pointer";
  }

  if (variant === "avatar") {
    style.borderRadius = "50%";
    style.width = `min(${width}, ${height})`;
    style.height = `min(${width}, ${height})`;
  }

  if (fit)
    return (
      <div
        onError={({ currentTarget }) => {
          if (hideBrokenImage) {
            currentTarget.style.display = "none";
          }
        }}
        style={{
          backgroundImage: `url(${src})`,
          backgroundColor: "#eee",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          height,
          width,
          ...style,
        }}
        onClick={onClick}
      />
    );

  return (
    <img
      onError={({ currentTarget }) => {
        if (hideBrokenImage) {
          currentTarget.style.display = "none";
        }
      }}
      crossOrigin={allowCors ? "anonymous" : undefined}
      src={src}
      height={height}
      width={width}
      alt={alt}
      style={{ objectFit: imageFit, ...style }}
      onClick={onClick}
    />
  );
}

APImage.whyDidYouRender = {
  logOnDifferentValues: true,
  customName: "Menu",
};
