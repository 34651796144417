import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { APGrid } from "codemod_components/layout/APGrid";
import { APButton } from "codemod_components/elements/APButton";
import OnboardingApiServices from "../../../requests/OnboardingRequests";
import { showLoadingDialog } from "codemod_components/dialogs/showLoadingDialog";
import { getIt } from "codemod_components/dialogs/DialogHelper";
import { showErrorDialog } from "codemod_components/dialogs/showErrorDialog";
import { updatePhotoForm } from "../../../store/slices/onboardingSlice";
import Webcam from "react-webcam";
import cloneDeep from "lodash/cloneDeep";
import { useNavigate } from "react-router-dom";
import { APSizedBox } from "../../../codemod_components/layout/Common";

const PhotoForm = ({ goToNextStep, goToPrevStep, isLastStep }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { photo, session_id, token } = useSelector(
    (state: any) => state.onboardingSlice,
  );

  const [photoSrc, setPhotoSrc] = useState<string | null>(
    photo?.base64Image || "",
  );
  const [cameraError, setCameraError] = useState<string | null>("");
  // @ts-ignore
  const webcamRef = useRef<Webcam>({});

  const checkCameraAccess = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
      });
      stream.getTracks().forEach((track) => track.stop());
      setCameraError(null);
    } catch (err) {
      setCameraError(
          "Unable to access camera. Please check your camera permissions and try again.",
      );
    }
  };

  useEffect(() => {
    checkCameraAccess();
  }, []);

  const capture = async () => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      try {
        const resp = await showLoadingDialog(() =>
          getIt(OnboardingApiServices).generatePreSignedUrl(
            session_id,
            "individual_photo",
            token,
          ),
        );
        await showLoadingDialog(() =>
          getIt(OnboardingApiServices).uploadDocumentToPreSignedUrl(
            resp.data.url,
            imageSrc,
          ),
        );
        await getIt(
          OnboardingApiServices,
        ).updateSessionInfo(
          session_id,
          {
            photo: {
              doc_identifier: resp.data.doc_identifier,
            },
          },
          token,
        );
        dispatch(
          updatePhotoForm(
            cloneDeep({
              ...photo,
              doc_identifier: resp.data.doc_identifier,
              base64Image: imageSrc,
            }),
          ),
        );
        if (isLastStep) {
          navigate(`/onboarding/${session_id}/success`);
        } else {
          goToNextStep();
        }
      } catch (err: any) {
        await showErrorDialog(err.message);
      }
    }
  };

  const handleRemovePhoto = () => {
    setPhotoSrc(null);
  };

  return (
    <APGrid count={1} columnGap={"16px"}>
      {cameraError && (
          <>
            <div style={{color: "red", marginBottom: "10px"}}>{cameraError}</div>
            <APButton
                style={{marginTop: "2rem"}}
                size="large"
                onClick={checkCameraAccess}
                type="primary"
            >
              Get Camera Access
            </APButton>
          </>
      )}
      <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        width="100%"
        height="auto"
      />
      <APSizedBox height="16px" />
      <APButton
        style={{ marginTop: "2rem" }}
        size="large"
        onClick={capture}
        type="primary"
        disabled={!!cameraError}
      >
        Capture Photo
      </APButton>

      {photoSrc && (
        <div>
          <img
            src={photoSrc}
            alt="Captured"
            style={{ maxWidth: "100%", maxHeight: "300px" }}
          />
          <APButton size="small" onClick={handleRemovePhoto}>
            Remove Photo
          </APButton>
        </div>
      )}
    </APGrid>
  );
};

export default PhotoForm;
