import React, { useState, useCallback } from "react";
import { APFormFieldText } from "codemod_components/forms/APFormFieldText";
import { APButton } from "codemod_components/elements/APButton";
import { APCard } from "codemod_components/elements/APCard";
import { APScrollView } from "codemod_components/layout/APScrollView";
import { getIt } from "codemod_components/dialogs/DialogHelper";
import debounce from "lodash/debounce";
import { Typography } from "@mui/material";
import { APColumn } from "codemod_components/layout/APFlex";
import { REGEX } from "codemod_components/utils/REGEX";
import { APPalette } from "codemod_components/utils/APPalette";
import { APExpanded } from "codemod_components/layout/Common";
import { APForm } from "codemod_components/forms/APForm";
import { useAPForm } from "codemod_components/forms/useAPForm";
import FinacuityLoanApplicationApiServices from "requests/LoanapplyRequests";
import { APFormFieldDropdown } from "codemod_components/forms/APFormFieldDropdown";

const EmploymentDetails = () => {
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [education, setEducation] = useState("");
  const [designation, setDesignation] = useState("");
  const [salary, setSalary] = useState("");
  const [companyOptions, setCompanyOptions] = useState([]);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const emplyomentFormControl = useAPForm();

  const fetchCompanies = useCallback(
    debounce(async (searchTerm) => {
      if (searchTerm && String(searchTerm).length > 3) {
        try {
          const { data } = await getIt(
            FinacuityLoanApplicationApiServices,
          ).cinLookUp(searchTerm);
          setCompanyOptions(data);
          setIsDropdownOpen(true);
        } catch (error) {
          console.error(error);
        }
      } else {
        setCompanyOptions([]);
        setIsDropdownOpen(false);
      }
    }, 1000),
    [],
  );

  const handleCompanyNameChange = (value) => {
    setCompanyName(value);
    fetchCompanies(value);
  };

  const handleCompanySelect = (company) => {
    setCompanyName(company.companyName);
    setCompanyOptions([]);
    setIsDropdownOpen(false);
  };

  const handleEmailVerify = async () => {
    console.log("verified");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  return (
    <APColumn
      style={{ background: APPalette.white, paddingTop: "0.5rem" }}
      mainAxisSize="max"
      crossAxisAlignment="stretch"
    >
      <APForm control={emplyomentFormControl}>
        <APColumn
          mainAxisSize="min"
          crossAxisAlignment="stretch"
          style={{
            backgroundColor: APPalette["grey-50"],
            padding: 0,
            position: "relative",
          }}
        >
          <APExpanded>
            <APScrollView>
              <APCard style={{ borderRadius: "8px", width: "100%" }}>
                <APColumn
                  mainAxisSize="min"
                  crossAxisAlignment="stretch"
                  style={{ padding: "1rem", position: "relative" }}
                >
                  <div style={{ position: "relative" }}>
                    <APFormFieldText
                      label="Company Name"
                      value={companyName}
                      onChanged={handleCompanyNameChange}
                      onClick={() => setIsDropdownOpen(true)}
                      onBlur={() =>
                        setTimeout(() => setIsDropdownOpen(false), 200)
                      }
                      validator={(value) =>
                        !value ? "Please enter a valid Name" : null
                      }
                      fullWidth
                      style={{ width: "100%" }}
                    />
                    {isDropdownOpen && companyOptions.length > 0 && (
                      <APColumn
                        style={{
                          position: "absolute",
                          top: "100%",
                          left: 0,
                          zIndex: 2,
                          width: "100%",
                          backgroundColor: "white",
                          border: "1px solid #ccc",
                          maxHeight: "200px",
                          overflowY: "auto",
                          padding: "0.5rem",
                        }}
                      >
                        {companyOptions.map((option) => (
                          <APCard
                            key={option.companyID}
                            style={{
                              marginBottom: "0.5rem",
                              padding: "0.5rem",
                              cursor: "pointer",
                              width: "100%",
                            }}
                            onClick={() => handleCompanySelect(option)}
                          >
                            <Typography variant="caption">
                              {option.companyName}
                            </Typography>
                          </APCard>
                        ))}
                      </APColumn>
                    )}
                  </div>
                  <APFormFieldText
                    label="Official Email Address"
                    value={email}
                    onChanged={setEmail}
                    validator={(value) => {
                      if (!value || !REGEX.EMAIL.test(value)) {
                        return "Please enter a valid Email";
                      }
                      return null;
                    }}
                    style={{ width: "100%" }}
                  />
                  <APButton
                    onClick={handleEmailVerify}
                    size="small"
                    type="primary"
                    style={{ width: "50%" }}
                  >
                    Verify Email
                  </APButton>
                  {isEmailSent && (
                    <Typography color="success.main">
                      Verification email sent!
                    </Typography>
                  )}
                  <APFormFieldDropdown
                    label="Education"
                    initialValue={education}
                    onChanged={setEducation}
                    items={[
                      {
                        value: "Class 7 or below",
                        label: "Class 7 or below",
                      },
                      {
                        value: "Class 8 to Class 11",
                        label: "Class 8 to Class 11",
                      },
                      {
                        value: "Class 12 or Diploma",
                        label: "Class 12 or Diploma",
                      },
                      {
                        value: "Graduate or Post Graduate",
                        label: "Graduate or Post Graduate",
                      },
                    ]}
                    validator={(value) =>
                      !value ? "Please select atleast one option" : null
                    }
                  />
                  <APFormFieldText
                    label="Designation"
                    value={designation}
                    onChanged={setDesignation}
                    validator={(value) =>
                      !value ? "Please enter a valid Designation" : null
                    }
                  />
                  <APFormFieldText
                    label="Salary (in rupees per month)"
                    value={salary}
                    onChanged={(value) => {
                      const parsedValue = parseFloat(value);
                      if (parsedValue <= 200000 || value === "") {
                        setSalary(value);
                      }
                    }}
                    validator={(value) =>
                      !value ? "Please enter a Salary" : null
                    }
                    type="number"
                    inputProps={{ maxLength: 200000 }}
                    fullWidth
                  />
                  <APButton
                    variant="contained"
                    color="primary"
                    onClick={async () => {
                      if (await emplyomentFormControl.validate()) {
                        handleSubmit;
                      }
                    }}
                    style={{ marginTop: 20 }}
                  >
                    Submit
                  </APButton>
                </APColumn>
              </APCard>
            </APScrollView>
          </APExpanded>
        </APColumn>
      </APForm>
    </APColumn>
  );
};

export default EmploymentDetails;
