import {Route, Routes} from "react-router-dom";
import PaymentScreen from "./PayIndex";


const PayRouter = () => {
    return (
        <>
            <Routes>
                <Route path="/:session_id" element={<PaymentScreen />} />
            </Routes>
        </>
    )

}

export default PayRouter;