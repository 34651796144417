import React from "react";
import { APCard } from "codemod_components/elements/APCard";
import { APColumn, APRow } from "codemod_components/layout/APFlex";
import { APScrollView } from "codemod_components/layout/APScrollView";
import { APExpanded } from "codemod_components/layout/Common";
import { APPalette } from "codemod_components/utils/APPalette";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";
import data from "./sample.json";
import { formatCurrency } from "utils/string-utils";

const styles = {
  container: {
    background: APPalette.white,
    padding: "1rem",
    maxWidth: "100%",
    margin: "auto",
    boxSizing: "border-box",
  },
  card: {
    padding: "1rem",
    margin: "auto",
    width: "100%",
  },
  header: {
    textAlign: "center",
    marginBottom: "1rem",
  },
  headerText: {
    color: "red",
    fontWeight: "bold",
  },
  sectionHeader: {
    color: "red",
    fontWeight: "bold",
  },
  footer: {
    backgroundColor: "#3d3a3a",
    color: "white",
    padding: "1rem",
    marginTop: "2rem",
  },
  tableContainer: {
    width: "100%",
    overflow: "auto",
  },
  table: {
    width: "100%",
  },
  tableHeader: {
    backgroundColor: "gray",
    color: "white",
    fontSize: "0.875rem",
  },
  tableCell: {
    textAlign: "center",
    padding: "8px",
    fontSize: "0.75rem",
    wordBreak: "break-word",
  },
  tableHeaderCell: {
    fontWeight: "bold",
  },
};

const DataTable = ({ headers, rows, isFooter = true, cellStyles = {} }) => {
  return (
    <TableContainer component={Paper} style={styles.tableContainer}>
      <Table style={styles.table}>
        <TableHead>
          <TableRow>
            {headers.map((header, index) => (
              <TableCell key={index} style={styles.tableHeader}>
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index}>
              {row.map((cell, idx) => (
                <TableCell
                  key={idx}
                  style={{
                    ...styles.tableCell,
                    backgroundColor:
                      isFooter && index === rows.length - 1
                        ? "lightGrey"
                        : "inherit",
                  }}
                >
                  {cell}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const FreeCreditReportModule = () => {
  const report = data?.report || {};
  const disposableData = report.disposable || [];
  const paySlips = report.payslips_earnings?.pay_slips || [];
  const liabilityTotals = report.bureau?.open_accounts_table || [];

  let totalSanctioned = 0;
  let totalCurrentBalance = 0;
  let totalPaidPrinciple = 0;
  let totalEmiAmount = 0;

  liabilityTotals.forEach((item) => {
    totalSanctioned += item.group_total?.sanctioned || 0;
    totalCurrentBalance += item.group_total?.currentBalance || 0;
    totalPaidPrinciple += item.group_total?.paidPrinciple || 0;
    totalEmiAmount += item.group_total?.emiAmount || 0;
  });
  return (
    <APColumn
      style={styles.container}
      mainAxisSize="max"
      crossAxisAlignment="stretch"
    >
      <APExpanded>
        <APScrollView>
          <APCard style={styles.card}>
            <APColumn>
              <APColumn style={styles.header}>
                <Typography variant="h6" style={styles.headerText}>
                  CREDIT ANALYSIS REPORT
                </Typography>
                <hr
                  style={{
                    border: "none",
                    borderTop: "1px solid rgba(0, 0, 0, 0.253)",
                    width: "80%",
                    margin: "0 auto 1rem",
                  }}
                />
              </APColumn>
              <APRow style={{ justifyContent: "center" }}>
                <Typography variant="h5">Credit Score</Typography>
              </APRow>
              <APRow style={{ justifyContent: "center", marginBottom: "1rem" }}>
                <Typography variant="h4" color={"green"}>
                  {report.score || "N/A"}
                </Typography>
              </APRow>

              {/* Current Liabilities Table */}
              <APColumn style={{ marginBottom: "1rem" }}>
                <Typography variant="h6" style={styles.sectionHeader}>
                  Your Current Liabilities*
                </Typography>
                <APCard style={styles.card}>
                  <DataTable
                    headers={[
                      "PRODUCTS",
                      "SANCTIONED",
                      "CURRENT BALANCE",
                      "PAID PRINCIPAL",
                      "EMI",
                    ]}
                    rows={[
                      ...liabilityTotals.map((item) => [
                        item.type,
                        formatCurrency(item.group_total?.sanctioned || 0),
                        formatCurrency(item.group_total?.currentBalance || 0),
                        formatCurrency(item.group_total?.paidPrinciple || 0),
                        formatCurrency(item.group_total?.emiAmount || 0),
                      ]),
                      [
                        "Total",
                        formatCurrency(totalSanctioned),
                        formatCurrency(totalCurrentBalance),
                        formatCurrency(totalPaidPrinciple),
                        formatCurrency(totalEmiAmount),
                      ],
                    ]}
                    cellStyles={{
                      0: { 0: "red" },
                    }}
                    isFooter={true}
                  />
                </APCard>
                <APColumn style={{ marginTop: "1rem" }}>
                  <Typography>
                    1. As reported in your latest Credit Bureau Report...
                  </Typography>
                  <Typography>
                    2. Check if your active and closed loans are correctly
                    reported...
                  </Typography>
                  <Typography>
                    3. Any fresh loan eligibility is decided by banks...
                  </Typography>
                </APColumn>
              </APColumn>

              {/* Disposable Income Table */}
              <APColumn style={{ marginBottom: "1rem" }}>
                <Typography variant="h6" style={styles.sectionHeader}>
                  Your Disposable Income
                </Typography>
                <APCard style={styles.card}>
                  <DataTable
                    headers={["TYPE", "PER MONTH"]}
                    rows={disposableData.map((item) => [
                      item.type?.toUpperCase() || "N/A",
                      formatCurrency(item.per_month || 0),
                    ])}
                  />
                </APCard>
                <APColumn>
                  <APRow>
                    <Typography style={{ color: "red" }}>* FOIR</Typography>
                  </APRow>
                  <Typography>
                    Fixed Obligation to Income Ratio (FOIR) is calculated by
                    banks and lending companies to assess your loan eligibility
                    based on your current monthly disposable income.
                  </Typography>
                </APColumn>
              </APColumn>

              {/* Recommendations Table */}
              <APColumn style={{ marginBottom: "1rem" }}>
                <Typography variant="h6" style={styles.sectionHeader}>
                  Codemodulo Recommendations
                </Typography>
                <Typography>
                  You are eligible for the following Personal Loans*
                </Typography>
                <APCard style={styles.card}>
                  <DataTable
                    headers={["Loan Provider", "Loan Amount", "Interest Rate"]}
                    rows={[
                      ["Loan Provider 1", "Loan Amount 1", "Interest Rate 1"],
                      ["Loan Provider 2", "Loan Amount 2", "Interest Rate 2"],
                    ]}
                  />
                </APCard>
                <APRow style={{ marginTop: "1rem" }}>
                  <Typography>
                    Note: Please refer to individual loan providers for the most
                    accurate details...
                  </Typography>
                </APRow>
              </APColumn>

              {/* Detailed Credit Analysis Report Table */}
              <APColumn style={{ marginBottom: "1rem" }}>
                <Typography variant="h6" style={styles.sectionHeader}>
                  Detailed Credit Analysis Report
                </Typography>
                <APCard style={styles.card}>
                  <DataTable
                    headers={[
                      "LIABILITIES",
                      "SANCTIONED",
                      "OUTSTANDING",
                      "PAID PRINCIPAL",
                      "EMI",
                      "DELINQUENCY*",
                    ]}
                    rows={paySlips.map((paySlip) => [
                      paySlip.company || "N/A",
                      formatCurrency(paySlip.earnings?.basic_salary || 0),
                      formatCurrency(
                        paySlip.earnings?.house_rent_allowance || 0
                      ),
                      formatCurrency(
                        paySlip.earnings?.conveyance_allowance || 0
                      ),
                      formatCurrency(paySlip.total_earnings || 0),
                      formatCurrency(paySlip.net_pay || 0),
                    ])}
                  />
                </APCard>
                <Typography style={{ marginTop: "1rem" }}>
                  *An account is said to be in delinquency when the EMI or
                  payment due for it is past due date.
                </Typography>
              </APColumn>
            </APColumn>
          </APCard>
        </APScrollView>
      </APExpanded>
    </APColumn>
  );
};

export default FreeCreditReportModule;